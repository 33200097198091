
const initialState = {
    path: '/',
    toggle: false,
    ADMIN_PROFILE: {},
    OF_FILTERS: {},
    KYC_DATA: {},
    KYC_GENERAL_FILTERS: {},
    GENERAL_FILTERS: {},
    TOOL_TIP_DATA: {}
};

export default function adminReducer(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_NAVIGATION":
            return {
                ...state,
                path: action.payload
            };
        case "TOGGLE_SIDEBAR":
            return {
                ...state,
                toggle: action.payload
            };

        case "GET_ADMIN_PROFILE":
            return {
                ...state,
                ADMIN_PROFILE: action.payload
            };
        case 'SELECTED_OF_FILTERS':
            return {
                ...state,
                OF_FILTERS: action.payload
            }
        case 'GET_KYC_DATA':
            return {
                ...state,
                KYC_DATA: action.payload
            }
        case 'GET_KYC_FILTERS':
            return {
                ...state,
                KYC_GENERAL_FILTERS: action.payload
            }
        case 'APP_GENERAL_DATA' :
            return {
                ...state,
                GENERAL_FILTERS: action.payload
            }
        case 'TOOLTIP_DATA' :
            return {
                ...state,
                TOOL_TIP_DATA: action.payload
            }
        default:
            return state
    }
}