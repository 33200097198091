
/*
 *   File : compare-search.js
 *   Author URI : https://evoqins.com
 *   Description : MF search modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState, useRef } from "react";

import Icon from "../Icon/icon";

import style from '../../Styles/Components/compare-search.module.scss';

import APIService from "../../Services/api-service";


const CompareSearchModal = (props) => {
    const [search, setSearch] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchSuggestion, setSearchSuggestion] = useState([]);

    useEffect(() => {
        const my_modal = document.getElementById("compare-search");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    useEffect(() => {
        if (search.length !== 0) {
            _getSearchSuggestion(search);
        }
    }, [search]);

    // handler to store search value
    const _handleSearch = (e) => {
        if (e.target.value.length !== 0) {
            setSearch(e.target.value);
        }
        else {
            setSearch("");
            setSearchSuggestion([]);
            setShowSuggestions(false);
        }
    }

    const _showSuggestion = () => {
        _getSearchSuggestion(search);
    }

    function _handleSelect(item) {
        props.handleFundSelect(item);
        let button = document.getElementById('modal-close');
        button.click();
    }

    // Get search data
    async function _getSearchSuggestion(value) {
        if (value == "") {
            setSearchSuggestion([]);
            setShowSuggestions(false);
            return null;
        }
        let url = 'mf-data/search';
        let payload = JSON.stringify({
            query: value
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSearchSuggestion(response.data);
                setShowSuggestions(true);
            } else {
                setSearchSuggestion([]);
                setShowSuggestions(false);
            }
        })
    }

    return (
        <div id="compare-search"
            // data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="compare-search"
            aria-hidden="true"
            className={`modal fade ${style.e_compare_modal}`}>
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content p-4 ${style.e_content} `}>
                    <div className="d-flex justify-content-end">
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className={` cursor-pointer e-modal-close-btn`} />
                        <button data-bs-dismiss="modal" id="modal-close" className="d-none"></button>
                    </div>
                    <div className="col-12 px-2">
                        <h6 className="e-poppins-semi-bold e-font-18 color-almost-black">Add funds to compare</h6>
                    </div>
                    <div className="col-12 mt-2 px-2">
                        <div className="position-relative h-100">
                            <div className="position-relative">
                                <input type="text"
                                    value={search}
                                    placeholder="Search fund name"
                                    className='e-investment-search z-index-10 position-relative padding-12px-all border-radius-8px border-all border-may-green bg-white w-100 color-black e-poppins-regular e-font-16 line-height-24px'
                                    // onKeyDown={handleKeyPress}
                                    autoComplete="off"
                                    aria-autocomplete="none"
                                    spellCheck="false"
                                    autoCorrect="off"
                                    onChange={(e) => _handleSearch(e)} />
                                <img src={require('../../Assets/Images/Dashboard/search.png')}
                                    alt="Search"
                                    width={24}
                                    height={24}
                                    draggable={false}
                                    className='position-absolute z-index-12 e-investment-search-icon' />
                            </div>
                            {
                                showSuggestions === false ?
                                    <div className="h-100 w-100 d-flex justify-content-center align-items-center mt-5">
                                        <img src={require('../../Assets/Images/search-placeholder.png')}
                                            width={195} 
                                            draggable={false}/>
                                    </div>
                                    :
                                    <div className={`position-absolute padding-24px-all  w-100 left-0 bg-white border-radius-8px border-all border-anti-flash-white`}>
                                        <div className='position-relative '>
                                            <div className="row">
                                                <div className="col-12">
                                                    {
                                                        searchSuggestion.length !== 0 &&
                                                        <div className={`d-flex flex-column gap-8px h-250px overflowY-auto ${style.e_search_result}`}>
                                                            {
                                                                searchSuggestion.map((item, key) =>
                                                                    <div className='d-flex align-items-center py-2 padding-14px-lr gap-14px cursor-pointer e-top-searches' key={key}
                                                                        onClick={() => _handleSelect(item)}>
                                                                        <img src={require('../../Assets/Images/search-line.svg').default}
                                                                            alt="Top searches"
                                                                            draggable={false}
                                                                            className="d-sm-block d-none" />
                                                                        <p className='color-black e-poppins-regular e-font-14 mb-0'>{item.name}</p>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompareSearchModal