/*
 *   File : portfolio-summary.js
 *   Author URI : https://evoqins.com
 *   Description : UI card to show the investment summary of personal accounts and family accounts. 
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { Fragment, useRef, useState, useEffect } from "react";
import { Modal } from "bootstrap";

/* import components */
import { Toggle } from "../FormElements"
import Icon from "../Icon/icon"
import { GradientButton } from "../Cta"
import { ManageExternalFundConfig } from "../Popups";
import { ImportExternalFundModal } from "../Modal";

/* import hooks */
import { useClickOutside } from "../../Helper/helper-function";

/* import styles */
import Color from "../../Styles/color.module.scss"

const PORTFOLIO_SUMMARY_DATA = {
    members: 7,
    invested_amount: 5000000.00,
    current_amount: 5000000.00,
    returns: 5000000.00,
    return_percent: 35,
    xirr: 18.24
}

const PortfolioSummaryCard = (props) => {

    const optionsRef = useRef();
    const [showAllReturns, setShowAllReturns] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    //eslint-disable-next-line
    const [importFunds, setImportFunds] = useState(false);
    //eslint-disable-next-line
    const [deleteFunds, setDeleteFunds] = useState(false);

    const [initiateExternalFunds, setInitiateExternalFunds] = useState(false);
    const [isFundImported, setIsFundImported] = useState(props.isFundImported);
    const [lastFundImported, setLastFundImported] = useState(props.lastCASImport);
    const [isUpdateAvailable, setIsUpdateAvailable] = useState(props.isUpdateAvailable)

    useEffect(() => {
        if (initiateExternalFunds === true) {
            const modal = new Modal(document.getElementById("initiate-external-funds-modal"), {});
            modal.show();
        }
    }, [initiateExternalFunds])

    useClickOutside(optionsRef, () => {
        setShowOptions(false);
    });


    const _showOptions = () => {
        setShowOptions(!showOptions);
    }


    const _handleImportExternalFunds = () => {
        setInitiateExternalFunds(true);
    }

    const _handleDeleteFunds = () => {
        setDeleteFunds(true);
    }

    const _handleUpdateExternalFunds = () => {
        setImportFunds(true);
    }

    const _handleInitiateClose = () => {
        setInitiateExternalFunds(false);
    }

    const _updateUIAfterImport = () => {
        var today = new Date();
        var day = today.getDate().toString().padStart(2, '0');
        var month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        var year = today.getFullYear();
        var fund_imported_date = `${day}-${month}-${year}`;
        setIsFundImported(true);
        setLastFundImported(fund_imported_date);
        setIsUpdateAvailable(false);
        props.callBackExternalFunds();
    }


    return (
        <Fragment>
            {showAllReturns === true ?
                <div className="border-radius-24px bg-honey-dew padding-56px-bottom px-4 pt-4 position-relative overflow-hidden">
                    <img draggable={false} src={require("../../Assets/Images/Portfolio/portfolio-banner.svg").default}
                        alt="Circle"

                        className="position-absolute top-0 left-0  e-bg-image" />
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <p className="color-black e-poppins-semi-bold e-font-16 mb-0">Self investment summary</p>
                        <div className="d-flex align-items-center gap-8px position-relative"
                            ref={optionsRef}>
                            <img src={require("../../Assets/Images/Profile/bank-menu-dots.png")}
                                alt="Drop down"
                                width={24}
                                height={24}
                                className="cursor-pointer"
                                onClick={_showOptions} />
                            {
                                showOptions === true &&
                                <ManageExternalFundConfig importExternalFunds={_handleImportExternalFunds}
                                    updateExternalFunds={_handleUpdateExternalFunds}
                                    deleteExternalFunds={_handleDeleteFunds}
                                    isCASImported={isFundImported}
                                    lastCASImport={lastFundImported} />
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="border-radius-16px bg-white position-relative p-3 z-index-1">
                                <p className="color-black e-poppins-regular e-font-14 mb-3">Invested amount</p>
                                <Icon icon="invested-icon"
                                    size={24}
                                    className="position-absolute e-icon" />
                                <p className="color-black e-poppins-semi-bold e-font-18 line-height-20px mb-0"><span className="e-inter-semi-bold">₹</span>{props.data.self_details.invested_amount.toLocaleString('en-IN')}</p>

                            </div>
                        </div>
                        <div className="col-6">
                            <div className="border-radius-16px bg-white position-relative p-3 z-index-1">
                                <p className="color-black e-poppins-regular e-font-14 mb-3">Current value</p>
                                <Icon icon="invested-icon"
                                    size={24}
                                    className="position-absolute e-icon" />
                                <p className="color-black e-poppins-semi-bold e-font-18 line-height-20px mb-0"><span className="e-inter-semi-bold">₹</span>{props.data.self_details.current_value.toLocaleString('en-IN')}</p>

                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="border-radius-16px bg-white p-3 position-relative z-index-1 ">
                                <div className="row">
                                    <div className="col-6 border-right-1px">
                                        <div className="position-relative">
                                            <p className="color-black e-poppins-regular e-font-14 mb-3">Returns </p>
                                            <img draggable={false} src={require("../../Assets/Images/Portfolio/total-returns.svg").default}
                                                alt="Total returns"
                                                className="position-absolute e-returns"
                                                width={24}
                                                height={24} />
                                            <div className="d-flex">
                                                <p className="color-black e-poppins-semi-bold e-font-18 line-height-20px mb-0 me-1">
                                                    <span className="e-inter-semi-bold">₹</span>
                                                    {props.data.self_details.returns.toLocaleString('en-IN')}
                                                </p>
                                                <div className="d-flex align-items-center">
                                                    <Icon icon="green-up-arrow"
                                                        width={15.5}
                                                        height={7.5}
                                                        color={props.data.self_details.returns_percentage < 0 && Color.red}
                                                        className={props.data.self_details.returns_percentage < 0 && "e-transform-returns"} />
                                                    <p className={`${props.data.self_details.returns_percentage < 0 ? "color-red" : "color-sea-green"} e-poppins-regular e-font-14 mb-0`}>{props.data.self_details.returns_percentage}%</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-6 ps-4">
                                        <div className="position-relative">
                                            <p className="color-black e-poppins-regular e-font-14 mb-3">XIRR </p>
                                            <img draggable={false} src={require("../../Assets/Images/Portfolio/xirr.svg").default}
                                                alt="XIRR"
                                                width={24}
                                                height={24}
                                                className="position-absolute e-returns " />
                                            <p className={`${props.data.self_details.xirr < 0 ? "color-red" : "color-sea-green"} e-poppins-semi-bold e-font-18 line-height-20px mb-0`}>{props.data.self_details.xirr}%</p>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> :
                <div className="border-radius-24px bg-pale-blueish px-4 pt-4 padding-14px-bottom position-relative">
                    <img draggable={false} src={require("../../Assets/Images/Portfolio/portfolio-blue-banner.svg").default}
                        alt="Circle"

                        className="position-absolute top-0 left-0  e-bg-image" />
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="color-black e-poppins-semi-bold e-font-16 mb-0">Family investment summary</p>
                        <div className="d-flex align-items-center gap-8px position-relative" ref={optionsRef}>
                            <span className="color-black e-poppins-regular e-font-12">
                                Self details
                            </span>
                            <Toggle check={showAllReturns}
                                leftColor={Color.primary}
                                barColor={Color.pearl_aqua}
                                onChange={(e) => setShowAllReturns(e)} />
                            <span className="color-black e-poppins-regular e-font-12">
                                Family details
                            </span>
                            <img draggable={false} src={require("../../Assets/Images/Portfolio/bank-menu-dots.png")}
                                alt="Drop down"
                                width={24}
                                height={24}
                                className="cursor-pointer"
                                onClick={_showOptions} />
                            {
                                showOptions === true &&
                                <ManageExternalFundConfig importExternalFunds={_handleImportExternalFunds}
                                    deleteExternalFunds={_handleDeleteFunds} />

                            }
                        </div>
                    </div>
                    <div className="border-radius-16px bg-white position-relative z-index-1 p-3">
                        <div className="d-flex align-items-center gap-8px">
                            <img draggable={false} src={require("../../Assets/Images/Portfolio/family-icon.png")}
                                alt="Family"
                                width={24}
                                height={24} />
                            <p className="color-black e-poppins-semi-bold e-font-16 mb-0">Family investment</p>
                        </div>
                        <div className="row my-3">
                            <div className="col-3">
                                <div className="p-2 border-right-1px">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Members</p>
                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0">{PORTFOLIO_SUMMARY_DATA.members}</p>
                                </div>
                            </div>
                            <div className="col-3 ps-0">
                                <div className="p-2 border-right-1px">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Total invested</p>
                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{PORTFOLIO_SUMMARY_DATA.invested_amount.toLocaleString("en-IN")}</p>
                                </div>
                            </div>
                            <div className="col-3 ps-0">
                                <div className="p-2 border-right-1px">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Current value</p>
                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{PORTFOLIO_SUMMARY_DATA.current_amount.toLocaleString("en-IN")}</p>
                                </div>
                            </div>
                            <div className="col-3 ps-0">
                                <div className="p-2">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Returns</p>
                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{PORTFOLIO_SUMMARY_DATA.returns.toLocaleString("en-IN")}</p>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="p-2 border-right-1px">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">Returns %</p>
                                    <p className={`${PORTFOLIO_SUMMARY_DATA.return_percent < 0 ? "color-red" : "color-black"} e-poppins-medium e-font-14 line-height-16px mb-0`}>{PORTFOLIO_SUMMARY_DATA.return_percent}</p>
                                </div>
                            </div>
                            <div className="col-3 ps-0">
                                <div className="p-2 ">
                                    <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px margin-10px-bottom">XIRR</p>
                                    <p className={`${PORTFOLIO_SUMMARY_DATA.xirr < 0 ? "color-red" : "color-black"} e-poppins-medium e-font-14 line-height-16px mb-0`}>{PORTFOLIO_SUMMARY_DATA.xirr}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <GradientButton label="View family details"
                            className="px-3 py-2" />
                    </div>
                </div>
            }

            {
                initiateExternalFunds === true ?
                    <ImportExternalFundModal data={props.customer_info}
                        close={_handleInitiateClose}
                        fundImported={_updateUIAfterImport} />
                    :
                    null
            }

        </Fragment>
    )
}

PortfolioSummaryCard.defaultProps = {
    data: {
        self_details:
        {
            invested_amount: 0,
            current_value: 0,
            returns: 0,
            returns_percentage: 0,
            xirr: 0
        }
    }
}

export default PortfolioSummaryCard