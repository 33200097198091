
/*
 *   File : investment-style.js
 *   Author : https://evoqins.com
 *   Description : Popup for investment style filter
 *   Integrations : null
 *   Version : 1.0.0
*/

import { useEffect, useState } from "react";
import { RadioGroup } from "../FormElements";
import { SecondaryButton, GradientButton } from "../Cta";

const InvestmentStylePopup = (props) => {
    const [investmentStyle, setInvestmentStyle] = useState(props.selectedData.id);
    const [investmentStyles, setInvestmentStyles] = useState([]);


    useEffect(() => {
        let styles = []
        props.investmentStyles.map((item) => {
            return (
                styles.push({
                    label: item.name,
                    id: item.id,
                    ...item
                })
            )
        })
        setInvestmentStyles(styles);
    }, [props.investmentStyles]);

    function _handleRadioChange(e) {
        setInvestmentStyle(e);
    };

    function _setFilter() {
        const selected_obj = investmentStyles.find(item => item.id === investmentStyle);
        props.filterChange(selected_obj);
        props.closePopUp();
    };

    return (
        <>
            <div className={`bg-white border-radius-8px py-3 e-investment-style-popup position-absolute top-66px z-index-12 ${props.className}`}>
                <h6 className="color-black e-poppins-semi-bold e-font-16 px-3">Choose investment style</h6>
                <div className="mt-sm-4 mt-2 border-bottom pb-3">
                    <div className="px-3">
                        <RadioGroup data={investmentStyles}
                            type={1}
                            id={investmentStyle}
                            radioHandler={(e) => _handleRadioChange(e)} />
                    </div>
                </div>
                <div className="px-sm-3 py-sm-3 px-2 py-0">
                    <SecondaryButton label="Cancel"
                        className="me-3 "
                        onPress={() => props.closePopUp()} />
                    <GradientButton label="Save"
                        className="padding-14px-tb padding-32px-lr mt-sm-0 mt-3"
                        onPress={_setFilter} />
                </div>
            </div>
            <div className="position-fixed w-100 z-index-10 h-100 left-0 top-0 cursor-default"
                onClick={() => props.closePopUp()}></div>
        </>
    )
};

export default InvestmentStylePopup;