/*
 *   File : slider.js
 *   Author : https://evoqins.com
 *   Description : Slider 
 *   Integrations : NA
 *   Version : 1.0.0
*/

/* import packages */
import ReactSlider from "react-slider";

import styled from "styled-components";
import Color from "../../Styles/color.module.scss"

const THUMB_ICON = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle opacity="1" cx="12" cy="12" r="12" fill="#ADCCE1" />
        <circle cx="12" cy="12" r="10" fill="white" />
        <circle cx="12" cy="12" r="4" fill="#1177BD" />
    </svg>
)

const StyledSlider = styled(ReactSlider)`
        width: 100%;
        height: 6px;`;

const StyledThumb = styled.div`
        outline:none;
        cursor: grab;
        top: ${window.innerWidth > 576 ? '-10px' : '-10px'};`

const StyledTrack = styled.div`
background: ${props => props.index === 1 ? Color.platinum : Color.primary};
border-radius: 16px;
height:6px;`;

export default function GoalSlider(props) {

    const Thumb = (props) => (
        <StyledThumb {...props}>
            <THUMB_ICON />
        </StyledThumb>
    );

    const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

    const _handleChange = (e) => {
        props._handleChange(e)
    }

    return (
        <>
            <StyledSlider min={props.min}
                id={props.id}
                max={props.max}
                value={props.value}
                renderTrack={Track}
                renderThumb={Thumb}
                step={props.step ? props.step : 1}
                onChange={(e) => _handleChange(e)} />
        </>
    )
}
