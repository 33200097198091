/*
 *   File : radio-group.js
 *   Author URI : www.evoqins.com
 *   Description : Radio group component
 *   Integrations : NA
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react"

/* import components */
import Icon from "../Icon/icon";

const RadioGroup = (props) => {

    const [selectedValue, setSelectedValue] = useState(props.id);

    useEffect(() => {
        setSelectedValue(props.id)
    }, [props.id])

    const _handleClick = (item) => {
        setSelectedValue(item.id);
        props.radioHandler(item.id);
    }

    return (

        <div className={`${props.className} d-flex flex-wrap gap-8px`}>
            {
                props.data.map((item, key) => {
                    if (props.type === 1) {
                        let is_selected = selectedValue === item.id;
                        return (
                            <div key={key}
                                className={`${props.wrapperClass} cursor-pointer w-100 mt-2 me-3`}
                                onClick={() => _handleClick(item)}>
                                <Icon className="me-2"
                                    icon={is_selected ? 'radio-selected' : 'radio-unselected'}
                                    size={22} />
                                <span className={is_selected ? "color-primary-color e-poppins-medium e-font-16" : "color-outer-space e-poppins-medium e-font-16"}>
                                    {
                                        props.containesRupee === true ?
                                            item.label.split('').map((char, index) => (
                                                <span key={index} className={char === '₹' ? 'e-inter-regular' : 'e-poppins-regular'}>
                                                    {char}
                                                </span>
                                            )) :
                                            item.label
                                    }
                                </span>
                            </div>
                        )
                    }
                    else if (props.type === 3) {
                        return (
                            <div key={key}
                                className={`d-flex gap-8px align-items-center cursor-pointer 
                                ${item.id === selectedValue ?
                                        `border-radius-8px border-all padding-12px-all border-primary-color ${props.radioItemClassName}`
                                        :
                                        `border-radius-8px border-all padding-12px-all border-bright-gray ${props.radioItemClassName}`
                                    }`}

                                onClick={() => _handleClick(item)}>
                                <Icon icon={
                                    selectedValue === item.id ?
                                        'radio-selected' :
                                        'radio-unselected'
                                }
                                    size={props.size ? props.size : 24} />
                                <img draggable={false} src={item.image}
                                    alt={item.label}
                                    width={24}
                                    height={24} />
                                <span className={
                                    selectedValue === item.id ?
                                        `color-black e-poppins-regular ${props.fontSize ? props.fontSize : "e-font-14"}` :
                                        `color-charleston-green e-poppins-regular ${props.fontSize ? props.fontSize : "e-font-14"}`}>
                                    {item.label}
                                </span>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div key={key}
                                className={`d-flex gap-8px align-items-center cursor-pointer ${props.disabled ? 'opacity-5 pointer-events-none' : ''} 
                                    ${item.id === selectedValue ?
                                        `border-radius-8px border-all padding-12px-all border-primary-color ${props.radioItemClassName}`
                                        :
                                        `border-radius-8px border-all padding-12px-all border-bright-gray ${props.radioItemClassName} `
                                    }`}

                                onClick={() => _handleClick(item)}>
                                <Icon icon={
                                    selectedValue === item.id ?
                                        'radio-selected' :
                                        'radio-unselected'
                                }
                                    size={props.size ? props.size : 24} />
                                <span className={
                                    selectedValue === item.id ?
                                        `color-black e-poppins-semi-bold ${props.fontSize ? props.fontSize : "e-font-14"}` :
                                        `color-charleston-green e-poppins-regular ${props.fontSize ? props.fontSize : "e-font-14"}`}>
                                    {item.label}
                                </span>
                            </div>
                        )
                    }
                })
            }
        </div>

    )
}

RadioGroup.defaultProps = {
    radioItemClassName: "",
    type: null,
    radioHandler: () => { }
}

export default RadioGroup;