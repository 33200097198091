
/*
 *   File : fund-suggestion.js
 *   Author URI : www.evoqins.com
 *   Description : Fund suggestion data table
 *   Integrations : null
 *   Version : v1.0
 */

import { DataTableContainer } from "../FormElements";
import { useNavigate } from "react-router-dom";

import Colors from '../../Styles/color.module.scss';

// custom table style
const TABLE_STYLE = {
    table: {
        style: {
            backgroundColor: 'transparent',
        },
    },
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'flex-end',
            },
        },
    },
    headRow: {
        style: {
            backgroundColor: 'transparent',
            border: 'none',
            color: Colors.jet_black,
            fontFamily: 'Poppins-Medium',
            fontSize: '1rem',
            padding: '0',
            paddingRight: "34px"
        }
    },
    tableBody: {
        style: {
            marginTop: '0',
        }
    },
    rows: {
        style: {
            backgroundColor: Colors.baby_blue,
            borderBottom: 'none !important',
            border: 'none',
            marginTop: '0',
            marginBottom: '10px',
            padding: '14px 34px 14px 16px'
        },
    },
    cells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'flex-end',
            },
        }
    }
};

const FundSuggestionTable = (props) => {

    const navigator = useNavigate();
    // Colum configuration
    const TABLE_CONFIG = [
        {
            name: 'Fund name',
            selector: row => row['fund_name'],
            sortable: false,
            left: true,
            width: `${window.innerWidth > 575 ? '60%' : '525px'}`,
            cell: (row) => (
                <div className="text-start d-flex gap-12px align-items-center"
                    onClick={() => _handleRedirection(row)}>
                    <span className="color-black e-poppins-regular e-font-14 text-nowrap">{row['sl_no']}.</span>
                    <p className="color-primary-color e-poppins-medium e-font-16 line-height-160 mb-0">
                        {row.fund_name}
                    </p>
                </div>
            )
        },
        {
            name: 'Allocation',
            selector: row => row['percentage'],
            sortable: false,
            left: true,
            // width: '20%',
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-18px mb-0 text-end white-space-nowrap"
                    onClick={() => _handleRedirection(row)}>
                    {row['percentage']}
                </p>
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            // width: "20%",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-18px mb-0 ps-5 white-space-nowrap"
                    onClick={() => _handleRedirection(row)}>
                    <span className="e-inter-medium me-1">₹</span>{row['amount'].toLocaleString('en-IN')}
                </p>
        },
    ];

    function _handleRedirection(row) {
        navigator("/explore/fund", {
            state: {
                fund_id: row.mf_id
            }
        });
    }

    return (
        <div className="e-fund-suggestion px-0">
            <DataTableContainer columns={TABLE_CONFIG}
                data={props.data}
                pagination={false}
                customStyles={TABLE_STYLE}
                rowClick={_handleRedirection} />
        </div>
    )
}

export default FundSuggestionTable;
