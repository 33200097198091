
/*
 *   File : external-fund-config
 *   Author : https://evoqins.com
 *   Description : Popup component to re-import or un-link external fund tracking from the MT account.
 *   Integrations : null
 *   Version : 1.0.0
*/
/* import packages */
import { useState } from "react"

/* import components */
import Icon from "../Icon/icon";

/* import styles */
import style from "../../Styles/Components/external-fund-config.module.scss"


const ManageExternalFundConfig = (props) => {

    const [showRedDeleteIcon, setShowRedDeleteIcon] = useState(false);

    const _handleMouseEnter = () => {
        setShowRedDeleteIcon(true);
    }

    const _handleDeleteEnter = () => {
        setShowRedDeleteIcon(false);
    }

    const _handleImportExternalFunds = () => {
        props.importExternalFunds();
    }

    const _handleDeleteFunds = () => {
        props.deleteExternalFunds();
    }

    

    return (
        <div className={`position-absolute w-max-content border-radius-12px border-width-2px border-solid border-bright-gray bg-white z-index-2 right-0 ${style.e_manage_external_fund}`}>
            <div className="d-flex gap-8px align-items-center py-2 padding-10px-lr cursor-pointer"
                onClick={_handleImportExternalFunds}>
                <Icon icon="refresh-external-case"
                    size={24} />
                <p className="color-black e-poppins-regular e-font-14 mb-0">Refresh external CAS</p>
            </div>
            {/* <div className="d-flex gap-8px align-items-center py-2 padding-10px-lr cursor-pointer"
                onClick={_handleDeleteFunds}>
                <Icon icon={showRedDeleteIcon === true ? "delete-red" : "delete"}
                    size={24}
                    onMouseEnter={_handleMouseEnter}
                    onMouseLeave={_handleDeleteEnter} />
                <p className="color-black e-poppins-regular e-font-14 mb-0">Delete external funds</p>
            </div> */}
        </div>
    )
}

export default ManageExternalFundConfig