/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : Invest in goals
 *   Integrations : null
 *   Version : v1.1
 */

import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { toast } from 'react-toastify';

import { NavigationHeader } from "../../Components/Header";
import { CustomLoader } from "../../Components/Other";
import Icon from "../../Components/Icon/icon";
import { GoalCard, PendingGoalCard } from "../../Components/Cards";
import { RemoveGoalModal } from "../../Components/Modal";

import APIService from "../../Services/api-service";

const PAGE_NAVIGATION = [
    "Dashboard",
    "Invest in Goals"
]

const InvestInGoals = React.memo(() => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [viewMore, setViewMore] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState("");
    const [pageLoader, setPageLoader] = useState(true);
    const [goalList, setGoalList] = useState({});
    const [customerId, setCustomerId] = useState(null);
    const [customerName, setCustomerName] = useState('');
    // scroll to top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(()=>{
        console.log('hdiwew', customerId);
    },[customerId]);

    useEffect(()=>{
        const params = new URLSearchParams(window.location.search);
        const customer_id = params.get('customer_id');
        const name = params.get('name');
        setCustomerName(name);
        _getGoalList(customer_id);
        setCustomerId(customer_id);
    },[]);

    // listener for opening delete modal
    useEffect(() => {
        if (showDeleteModal.length !== 0) {
            _openDeleteModal();
        }
    }, [showDeleteModal]);

    const _handleNavigateToDashboard = () => {
        navigate("/");
    }

    const _handleViewMore = () => {
        setViewMore(!viewMore);
    }

    const _handleContinue = (id) => {
        let page = ""
        switch (id) {
            case 1:
                page = "retirement"
                break;
            case 2:
                page = "house"
                break;
            case 3:
                page = "education"
                break;
            case 4:
                page = "car"
                break;
            case 5:
                page = "vacation"
                break;
            case 6:
                page = "custom-goal"
                break;

            default:
                break;
        }
        navigate(`${page}`, {
            state: {
                id: id,
                customer_id: customerId,
                customer_name: customerName
            }
        });
    }

    const _handleDelete = (value) => {
        setShowDeleteModal(value);
    }

    //to open delete modal
    const _openDeleteModal = () => {
        const modal = new Modal(document.getElementById("remove-goal"), {});
        modal.show();
    };


    // API - will return details of any goal with default data.
    const _getGoalDetail = (id) => {
        let page = ""
        switch (id) {
            case 1:
                page = "retirement"
                break;
            case 2:
                page = "house"
                break;
            case 3:
                page = "education"
                break;
            case 4:
                page = "car"
                break;
            case 5:
                page = "vacation"
                break;
            case 6:
                page = "custom-goal"
                break;

            default:
                break;
        }
        navigate(`${page}`, {
            state: {
                id: id,
                customer_id: customerId,
                customer_name: customerName
            }
        });
    }

    // API - Goal listing
    function _getGoalList(customer_id) {
        let url = 'goal/list';
        let payload = JSON.stringify({
            customer_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setGoalList(response.data);
            } else {
                toast.dismiss();
                setGoalList([]);
                toast.error(response.message, {
                    type: "error"
                });
            }
            setPageLoader(false);
        })
    };

    // API - Delete goal
    const _confirmDelete = () => {
        setPageLoader(true);
        console.log("SHow delete", showDeleteModal)
        let url = 'goal/delete';
        let payload = JSON.stringify({
            goal_id: showDeleteModal,
            customer_id: customerId
        })
        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                toast.success("Deleted Successfully", {
                    type: "success",
                });
                _getGoalList(customerId);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                setPageLoader(false);
            }
        })
    }

    // (USER_DATA.is_kyc_done === false && false) ? (
    //     <div className="col-8 mb-4 mt-4">
    //         <CreateAccountBanner/>
    //     </div>


    return (
        <Fragment>
            <NavigationHeader
                type={1}
                data={PAGE_NAVIGATION}
                title="Invest in Goals" />
            <div className="e-invest-goals ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top">

                {
                    pageLoader === true ?
                        <div className="row">
                            <div className="col-12 h-80vh">
                                <CustomLoader />
                            </div>
                        </div>
                        :
                        <>
                            {/* Banner */}
                            <div className=" d-sm-block e-goals-banner pt-4  padding-40px-left  padding-32px-right border-radius-20px bg-minty margin-24px-top margin-40px-bottom position-relative">
                                <div className="row">
                                    <div className="col-8 mt-5">
                                        <h1 className="color-black e-inter-semi-bold e-font-32 margin-12px-bottom position-relative z-index-1">Are you planning to improve your wealth.</h1>
                                        <h6 className="color-outer-space e-inter-regular e-font-18 mb-0 position-relative line-height-160 z-index-1">The secret to long-term wealth is, starting early. Invest your money for smooth sailing through your life goals</h6>
                                    </div>
                                    <div className="col-4 text-end">
                                        <img src={require('../../Assets/Images/Invest-in-goals/banner.svg').default}
                                            alt="Banner"
                                            draggable={false} />
                                    </div>
                                </div>
                            </div>

                            {/* Goals */}
                            {
                                goalList.pending_goals.length !== 0 &&
                                <Fragment>
                                    <input type="checkbox" id="goal-check" hidden />
                                    <div className="e-goals-animation">
                                        <div className="d-flex flex-column gap-sm-24px-16px " >
                                            {
                                                goalList.pending_goals.slice(0, viewMore ? goalList.pending_goals.length : 2).map((item, key) =>
                                                    <PendingGoalCard data={item}
                                                        index={item.goal_type_id}
                                                        goalId={item.goal_id}
                                                        key={key}
                                                        continue={_handleContinue}
                                                        delete={_handleDelete} />
                                                )
                                            }
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {
                                goalList && goalList.pending_goals.length > 2 ?
                                    <label className="d-flex justify-content-center align-items-center w-100 mt-4"
                                        htmlFor="goal-check">
                                        <p className="color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1 cursor-pointer"
                                            onClick={_handleViewMore}>
                                            {viewMore === false && goalList.pending_goals.length > 2 && "View all pending goals"}
                                            {viewMore === true && goalList.pending_goals.length > 2 && "Hide all pending goals"}
                                        </p>
                                        {
                                            goalList.pending_goals.length > 2 &&
                                            <Icon icon="right-arrow"
                                                size={24}
                                                data-view-more={viewMore}
                                                className="e-view-pending cursor-pointer"
                                                onClick={_handleViewMore} />
                                        }
                                    </label>
                                    :
                                    null
                            }


                            {/* Income */}
                            {
                                goalList && goalList.goals.length && (
                                    <>
                                        <div className="margin-48px-top">
                                            <p className="color-black e-poppins-medium e-font-24 line-height-32px mb-sm-2 mb-0">Invest for you Life Goals</p>
                                        </div>
                                        <div className="row gx-2 gx-sm-4">
                                            {
                                                goalList.goals.map((item, key) =>
                                                    <div className="col-lg-4 col-md-4 col-6  pt-sm-4 pt-2" key={key}>
                                                        <GoalCard data={item}
                                                            type={2}
                                                            handleClick={_getGoalDetail} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </>
                }
            </div>
            {/* <Footer /> */}

            {
                showDeleteModal.length !== 0 ?
                    <RemoveGoalModal confirmDelete={_confirmDelete}
                        close={() => setShowDeleteModal("")} />
                    :
                    null
            }
        </Fragment>
    )
})

export default InvestInGoals