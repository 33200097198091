
/*
 *   File : retirement.js
 *   Author URI : https://evoqins.com
 *   Description : Page for retirement
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { NavigationHeader } from "../../Components/Header";
import GoalSlider from '../../Components/Other/goal-slider';
import { CustomAmountInput } from '../../Components/FormElements';
import { GradientButton } from '../../Components/Cta';
import GoalSummary from '../../Components/Goal/goal-summary';
import { CustomLoader, RepresentationLabel } from '../../Components/Other';
import { GoalBanner } from '../../Components/Banner';

import { _getGoalDetail } from '../../Helper/api';
import APIService from '../../Services/api-service';

const PAGE_NAVIGATION = [
    'Home',
    'Invest in Goals',
    'Kids education'
];

const STEP_PROGRESS = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" stroke="#1177BD" stroke-width="2" />
        <circle cx="16" cy="16" r="5" fill="#1177BD" />
    </svg>
);

const STEP_UPCOMING = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" stroke="#D1D5DB" stroke-width="2" />
    </svg>
);

const STEP_COMPLETED = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" fill="#209E49" stroke="#209E49" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4933 10.935C22.8053 11.2074 22.8374 11.6812 22.565 11.9933L14.7079 20.9933C14.5654 21.1564 14.3594 21.25 14.1429 21.25C13.9263 21.25 13.7203 21.1564 13.5779 20.9933L10.435 17.3933C10.1626 17.0812 10.1947 16.6074 10.5068 16.335C10.8188 16.0626 11.2926 16.0947 11.565 16.4068L14.1429 19.3596L21.435 11.0068C21.7074 10.6947 22.1812 10.6626 22.4933 10.935Z" fill="white" />
    </svg>
);

const GOAL_CREATION_STEPS = [
    {
        title: "Enter your details",
        step1: STEP_PROGRESS,
        step2: STEP_COMPLETED,
        step3: STEP_COMPLETED
    },
    {
        title: "Corpus & fund suggestion",
        step1: STEP_UPCOMING,
        step2: STEP_PROGRESS,
        step3: STEP_COMPLETED
    },
    {
        title: "Invest",
        step1: STEP_UPCOMING,
        step2: STEP_UPCOMING,
        step3: STEP_PROGRESS
    }
];

// Step progress component
function StepProgress(props) {
    // const icons = props.currentStep === 1 ? PROGRESS_ICONS_STEP_1 : PROGRESS_ICONS_STEP_2;
    return (

        // {/* Progress bar and indicator */}
        <Fragment>
            <div className='d-sm-flex flex-sm-column position-relative overflow-sm-hidden'>
                <div className='d-flex justify-content-between gap-100px mt-sm-4 mt-2 e-goal-progress'>
                    <div className='e-horizontal-line'></div>
                    {
                        GOAL_CREATION_STEPS.map((item, key) => {
                            return (
                                <div className='d-flex flex-column align-items-center' key={key}>
                                    <p className='bg-mint-green mb-2 z-index-1'> {props.currentStep === 1 ? item.step1 : props.currentStep === 2 ? item.step2 : item.step3}
                                    </p>
                                    <span className={`color-black e-poppins-medium e-font-14 text-center`} key={key}>{item.title}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    )
};

// Form elements value
function Value(props) {
    return (
        <p className='color-black e-poppins-regular e-font-16 text-capitalized mb-0'>
            <span className='e-poppins-medium me-1'>{props.value}</span>
            {props.postfix}
        </p>
    )
};

// UI element for slider min and max limit value
function SliderLimit(props) {
    return (
        <span className='color-old-silver e-poppins-regular e-font-12 mt-2'>{props.value} {props.postfix}</span>
    )
};



export default function Education(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const [currentGoalStep, setCurrentGoalStep] = useState(1);
    const [targetAmount, setTargetAmount] = useState("");
    const [inflationRatio, setInflationRatio] = useState(null);
    const [availableCorpus, setAvailableCorpus] = useState(null);
    const [currentMonthError, setCurrentMonthError] = useState(null);
    const [goalData, setGoalData] = useState({});
    const [tenure, setTenure] = useState(null);
    const [description, setDescription] = useState("");
    const [loader, setLoader] = useState(true);
    const [detailData, setDetailData] = useState({});
    const [currentAge, setCurrentAge] = useState(null);
    const [targetAge, setTargetAge] = useState(null);
    const [goalId, setGoalId] = useState(null);
    const [fromFundDetail, setFromFundDetail] = useState(false);
    const [ageError, setAgeError] = useState(false);
    const [tillAgeError, setTillAgeError] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [customerId, setCustomerId] = useState(null);
    const [customerName, setCustomerName] = useState('');

    useEffect(() => {
        setCustomerId(location.state.customer_id);
        setCustomerName(location.state.customer_name);
    }, []);

    useEffect(() => {
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    }, []);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentGoalStep]);

    useEffect(() => {
        if (location.hash == '') {
            setCurrentGoalStep(1);
        } else {
            setLoader(true);
        }
    }, [location]);

    useEffect(() => {
        if (fromFundDetail === true) {
            _calculateFutureValue();
        }
    }, [fromFundDetail]);

    useEffect(() => {
        setTenure(targetAge - currentAge)
    }, [targetAge, currentAge]);

    useEffect(() => {
        if (ageError === true) {
            console.log('change');
            toast.dismiss();
            toast.error('Current age must be less than target age', {
                type: "error"
            });
        }
    }, [ageError]);

    useEffect(() => {
        if (tillAgeError === true) {
            toast.dismiss();
            toast.error('Target age must be greater than current age', {
                type: "error"
            });
        }
    }, [tillAgeError])

    useEffect(() => {
        if (location.state !== null && customerId !== null) {
            _getGoalDetail(location.state.id, customerId)
                .then((response) => {
                    setGoalId(response.goal_id);
                    setLoader(false);
                    setDetailData(response);
                    setAvailableCorpus(response.available_corpus !== "-" ? response.available_corpus : 0);
                    setDescription(response.description);
                    if (response.is_set === true) {
                        setTargetAmount(response.additional_data.target ? response.additional_data.target : 0);
                        setInflationRatio(response.additional_data.inflation_rate ? response.additional_data.inflation_rate : response.meta_data.inflation_rate.default);
                        setCurrentAge(response.additional_data.current_age ? response.additional_data.current_age : response.meta_data.current_age.default);
                        setTargetAge(response.additional_data.target_age ? response.additional_data.target_age : response.meta_data.target_age.default);
                        setTenure(response.additional_data.target_age - response.additional_data.current_age);
                        if (location.hash && Object.keys(goalData).length === 0) {
                            setFromFundDetail(true);
                            setLoader(true);
                        } else {
                            setLoader(false);
                        }
                        // setCurrentAge(response.)
                    } else {
                        setInflationRatio(response.meta_data.inflation_rate.default);
                        setCurrentAge(response.meta_data.current_age.default);
                        setTargetAge(response.meta_data.target_age.default);
                        setLoader(false);
                    }
                })
                .catch((response) => {
                    toast.dismiss();
                    toast.error(response, {
                        type: "error",
                    });
                });
        }
        //eslint-disable-next-line
    }, [location, currentGoalStep, customerId])


    // calculate corpus form validation 
    function _validateInput() {
        let valid = true;
        if (targetAmount.length === 0) {
            setCurrentMonthError('Enter amount for your education goal');
            valid = false;
        }

        if (valid === true) {
            _calculateFutureValue();
        }
    }

    // API - calculate corpus
    const _calculateFutureValue = () => {
        let url = 'goal/set';
        let payload = JSON.stringify({
            "goal_type_id": detailData.goal_id,
            "tenure": tenure,
            "target": targetAmount,
            "lumpsum": 0,
            "sip": 0,
            "step_sip": 0,
            "inflation_rate": inflationRatio,
            'customer_id': location.state.customer_id,
            "additional_data": {
                "current_age": currentAge,
                "target_age": targetAge,
                "target": targetAmount,
                'tenure': tenure,
                "inflation_rate": inflationRatio,
                "lumpsum": detailData.is_set ? detailData.additional_data.lumpsum : 0,
                "sip": detailData.is_set ? detailData.additional_data.sip : 0,
                "step_sip": detailData.is_set ? detailData.additional_data.step_sip : detailData.meta_data.step_sip.default,
                "sip_tenure": detailData.is_set ? detailData.additional_data.sip_tenure : detailData.meta_data.sip_tenure.default,
                "investment_style": detailData.is_set ? detailData.additional_data.investment_style : {},
            }
        })
        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                setCurrentGoalStep(2);
                if (location.hash === '') {
                    navigate("#summary", 
                        { state: { 
                            id: location.state.id, 
                            customer_id: location.state.customer_id, 
                            customer_name: location.state.customer_name } 
                        }
                    );
                }
                setGoalData(response.data);
                setLoader(false);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })

    }

    return (
        <>
            {
                loader ?
                    <CustomLoader />
                    :
                    <>
                        <NavigationHeader
                            type={1}
                            data={PAGE_NAVIGATION}
                            title="Invest in Goals - Kids education" />
                        <div className="e-retirement ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top">

                            {/* Banner  */}
                            <GoalBanner main_title={detailData.meta_data.title_1}
                                sub_title={detailData.meta_data.title_2}
                                banner_image={detailData.meta_data.banner_image}
                                description={detailData.meta_data.long_description} />

                            {/* Form */}
                            <div className='border-radius-16px border-bright-gray border-all bg-mint-green margin-40px-top padding-42px-tb  e-steps margin-72px-bottom-sm-16'>
                                <div className='row gx-3'>
                                    <div className='col-12 text-center'>
                                        <h4 className='color-black e-poppins-medium e-font-24 line-height-28px'>
                                            You are few steps away from creating Kids Education goal
                                        </h4>
                                    </div>

                                    {/* Progress */}
                                    <div className='col-12 d-flex justify-content-center'>
                                        <StepProgress currentStep={currentGoalStep} />
                                    </div>

                                    {/* step 1 */}
                                    {
                                        currentGoalStep === 1 ? (
                                            <>
                                                {/* Monthly income */}
                                                <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-3'>
                                                    <div className={`${screenWidth > 576 ? 'border-radius-16px border-all border-bright-gray p-4' : 'mt-2'}  min-height-144px-sm-8rem`}>
                                                        <CustomAmountInput value={targetAmount}
                                                            label="Required education amount "
                                                            inputClass="bg-transparent padding-28px-left"
                                                            labelClass="bg-mint-green"
                                                            showRupeePrefix={true}
                                                            postfix="*"
                                                            error={currentMonthError}
                                                            handleChange={(e) => {
                                                                setCurrentMonthError("");
                                                                setTargetAmount(e);
                                                            }} />
                                                    </div>
                                                </div>

                                                {/* Current age */}
                                                <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-3'>
                                                    <div className={`${screenWidth > 576 ? 'p-4' : 'py-2 px-3'} border-radius-16px border-all border-bright-gray min-height-144px-sm-8rem`}>
                                                        <div className='d-flex justify-content-between mb-3'>
                                                            <RepresentationLabel label="Kid current age"
                                                                type={1}
                                                                mandatory={true} />
                                                            <RepresentationLabel type={2}
                                                                value={currentAge}
                                                                postfix={currentAge == 1 ? "Year" : "Years"} />
                                                        </div>
                                                        <GoalSlider min={detailData.meta_data.current_age.min}
                                                            max={detailData.meta_data.current_age.max}
                                                            step={detailData.meta_data.current_age.step}
                                                            value={currentAge}
                                                            _handleChange={(e) => {
                                                                if (e >= targetAge) {
                                                                    if (ageError === false) {
                                                                        setAgeError(true);
                                                                    }
                                                                } else {
                                                                    setCurrentAge(e);
                                                                    setAgeError(false);
                                                                }
                                                            }} />
                                                        <div className='d-flex justify-content-between'>
                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.current_age.min}
                                                                postfix={detailData.meta_data.current_age.min == 1 ? "Year" : "Years"}
                                                                whiteSpace={false} />

                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.current_age.max}
                                                                postfix={detailData.meta_data.current_age.max == 1 ? "Year" : "Years"}
                                                                whiteSpace={false} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* target age */}
                                                <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-3'>
                                                    <div className={`${screenWidth > 576 ? 'p-4' : 'py-2 px-3'} border-radius-16px border-all border-bright-gray min-height-144px-sm-8rem`}>
                                                        <div className='d-flex justify-content-between mb-3'>
                                                            <RepresentationLabel label="By the age of"
                                                                type={1}
                                                                mandatory={true} />
                                                            <RepresentationLabel type={2}
                                                                value={targetAge}
                                                                postfix={targetAge == 1 ? "Year" : "Years"} />
                                                        </div>
                                                        <GoalSlider min={detailData.meta_data.target_age.min}
                                                            max={detailData.meta_data.target_age.max}
                                                            step={detailData.meta_data.target_age.step}
                                                            value={targetAge}
                                                            _handleChange={(e) => {
                                                                if (e <= currentAge) {
                                                                    setTillAgeError(true);
                                                                } else {
                                                                    setTargetAge(e)
                                                                    setTillAgeError(false);
                                                                }
                                                            }} />
                                                        <div className='d-flex justify-content-between'>
                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.target_age.min}
                                                                postfix={detailData.meta_data.target_age.min == 1 ? "Year" : "Years"}
                                                                whiteSpace={false} />

                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.target_age.max}
                                                                postfix={detailData.meta_data.target_age.max == 1 ? "Year" : "Years"}
                                                                whiteSpace={false} />
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* Inflation ratio & life expectancy */}
                                                <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-3'>
                                                    <div className={`${screenWidth > 576 ? 'p-4' : 'py-2 px-3'} border-radius-16px border-all border-bright-gray min-height-144px-sm-8rem`}>
                                                        <div className='row gx-5 position-relative'>
                                                            <div className='col-lg-12 col-md-12 col-12 '>
                                                                <div className='d-flex justify-content-between mb-3'>
                                                                    <RepresentationLabel label="Inflation ratio"
                                                                        type={1}
                                                                        mandatory={false} />
                                                                    <RepresentationLabel type={2}
                                                                        value={inflationRatio}
                                                                        postfix={"%"} />
                                                                </div>
                                                                <GoalSlider min={detailData.meta_data.inflation_rate.min}
                                                                    max={detailData.meta_data.inflation_rate.max}
                                                                    step={detailData.meta_data.inflation_rate.step}
                                                                    value={inflationRatio}
                                                                    _handleChange={(e) => setInflationRatio(e)} />
                                                                <div className='d-flex justify-content-between'>
                                                                    <RepresentationLabel type={3}
                                                                        value={detailData.meta_data.inflation_rate.min}
                                                                        postfix={"%"}
                                                                        whiteSpace={false} />
                                                                    <RepresentationLabel type={3}
                                                                        value={detailData.meta_data.inflation_rate.max}
                                                                        postfix={"%"}
                                                                        whiteSpace={false} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* Button */}
                                                <div className='col-12 text-center margin-40px-top'>
                                                    <GradientButton label="View Investment suggestion"
                                                        className="padding-12px-tb padding-24px-lr"
                                                        loading={buttonLoader}
                                                        onPress={_validateInput} />
                                                </div>
                                            </>
                                        ) : (
                                            <GoalSummary goalId={goalId}
                                                customerId={customerId}
                                                customerName={customerName}
                                                title={`By ${tenure === 1 ? `${tenure} year` : `${tenure} years`} , you must require the following sum.`}
                                                apiData={goalData}
                                                inflationRatio={inflationRatio}
                                                availableCorpus={availableCorpus}
                                                step_sip={detailData.is_set === true ? detailData.additional_data.step_sip : detailData.meta_data.step_sip.default}
                                                sip_tenure={detailData.is_set === true ? detailData.additional_data.sip_tenure : detailData.meta_data.sip_tenure.default}
                                                lumpsum={detailData.is_set === true ? detailData.additional_data.lumpsum : 0}
                                                sip={detailData.is_set === true ? detailData.additional_data.sip : 0}
                                                target={detailData.meta_data.default_target}
                                                onBackButtonPress={() => setCurrentGoalStep(1)} />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}