/*
 *   File : funds.js
 *   Author URI : www.evoqins.com
 *   Description : To show the available funds in Master Trust platform.
 *   Integrations : NA
 *   Version : v1.0
 *   Created : 14-09-2023
 */

import { useEffect, useState, memo } from "react";
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

import { DataTableContainer } from "../FormElements";
import { Icon } from "../Icon";
import { GradientButton } from "../Cta";
// import { CustomToolTip } from "../Popups";

import Colors from '../../Styles/color.module.scss';

const CUSTOM_STYLES = {
    table: {
        style: {
            padding: "12px 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "24px",
            color: Colors.outer_space
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
            cursor: 'pointer',
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}



export default memo(ExploreDataTable);
function ExploreDataTable(props) {

    const location = useLocation();

    const TOOLTIP_DATA =  useSelector(state => state.MT_ADMIN_STORE.TOOL_TIP_DATA);
    const [showArrow, setShowArrow] = useState(0);
    const [fundGoals, setFundGoals] = useState(props.data);
    const [rankInfo, setRankInfo] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const _handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', _handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', _handleResize);
        };
    }, []);

    useEffect(() => {
        setFundGoals(props.data);
    }, [props.data]);


    const FUNDS_COLUMN = [
        {
            id: 1,
            name: 'Funds',
            selector: row => row['name'],
            sortable: true,
            left: true,
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name "
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['name']}
                            width={36}
                            height={36}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-12px">
                        <span className="color-black e-poppins-medium e-font-sm-16-14 line-height-24px mb-0 bg-white">{row['name']}</span>
                        <span className="border-radius-16px border-all border-bright-gray  padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px w-fit-content">
                            {row['type']}
                        </span>
                    </div>

                </div>
        }, {
            id: 2,
            name: "CAGR (3Y)",
            width: windowWidth > 575 ? "110px" : "130px",
            selector: row => row['cagr'],
            sortable: true,
            left: true,
            style: {
                color: Colors.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: "16px",
                lineHeight: "18px",
            },
            cell: row =>
                <p className="color-dark-spring-green e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['cagr'] !== '-' ? `${row['cagr']}%` : row['cagr']}
                </p>
        },
        {
            id: 3,
            name: 'AUM (Cr)',
            selector: row => row['aum'],
            sortable: true,
            left: true,
            width: "130px",
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['aum']}
                </p>
        },
        {
            id: 4,
            name: 'Risk',
            selector: row => row['risk'],
            sortable: true,
            left: true,
            width: "100px",
            cell: row =>
                <div className="d-flex w-100 justify-content-center "
                    onClick={() => _handleCardClick(row)}>
                    <img src={row['risk_image']}
                        alt="High risk"
                        width={42}
                        height={40}
                        className="object-fit-contain" />
                </div>

        },
        {
            id: 5,
            name: <div className="postion-relative explore-table-title">
                Rank
                {
                    Object.keys(TOOLTIP_DATA).length !== 0 &&
                    <>
                        <Icon icon="info"
                            size={20}
                            className="cursor-pointer"
                            data-tooltip-id="rank-tooltip"
                            onClick={(e) => e.stopPropagation()} />

                        <Tooltip id="rank-tooltip"
                            arrowColor='transparent'
                            effect="float"
                            place="bottom"
                            className='e-tooltip-general'>
                            {parse(TOOLTIP_DATA.explore_rank_column_heading)}
                        </Tooltip>
                    </>
                }

            </div>,
            selector: row => row['rank'],
            sortable: true,
            left: true,
            width:"120px",
            style: {
                color: Colors.black,
                fontSize: "14px",
                lineHeight: " 18px"
            },
            cell: row =>
                <div className="d-flex align-items-center justify-content-center "
                    onClick={() => _handleCardClick(row)}>
                    <p className="mb-0 e-poppins-semi-bold">{row['rank']}</p>
                    <img src={require('../../Assets/Images/Dashboard/Star.png')}
                        alt="Rank"
                        width={16}
                        height={16} />

                </div>

        },
       
        {
            id: 7,
            name: '',
            sortable: false,
            left: true,
            width: "68px",
            cell: row => <div className={`e-fund-order-hover text-start   ${showArrow === row.id ? 'show ' : ''}`}
                onClick={() => _handleCardClick(row)}>
                <Icon icon="arrow-right1"
                    size={24}
                    color={Colors.black} />
            </div>

        }

    ];

    
    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const _handleCardClick = (row) => {
        props.onSelect(row);
    }

    return (

        <DataTableContainer columns={FUNDS_COLUMN}
            data={fundGoals}
            pagination={false}
            customStyles={CUSTOM_STYLES}
            onRowMouseEnter={_handleRowHover}
            onRowMouseLeave={_handleRowRemoveHover}
            defaultSortAsc={false}
            rowClick={(row) => _handleCardClick(row)}
            defaultSortFieldId={2} />
    )
}