import Cookies from "js-cookie";
import APIService from "../Services/api-service";
import Store from '../Store';

const _resendOTP = (data) => {
    return new Promise((resolve, reject) => {
        let url = 'auth/resend-otp';

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _getAdminProfileDetails = (data) => {
    return new Promise((resolve, reject) => {
        let url = 'auth/admin-profile';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _searchMF = (data) => {
    return new Promise((resolve, reject) => {
        let url = 'fund/search';


        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _addContent = (data) => {
    return new Promise((resolve, reject) => {
        let url = 'content/update-content';

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - get order details
const _getOrderDetail = (customer_id, order_id) => {
    return new Promise((resolve, reject) => {
        let url = 'order/detail';
        let payload = JSON.stringify({
            customer_id: customer_id,
            order_id: order_id
        })

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - get portfolio details
const _getPortFolioDetail = (customer_id, id, is_goal) => {
    return new Promise((resolve, reject) => {
        let url = 'user/portfolio/detail';
        let payload = JSON.stringify({
            customer_id: customer_id,
            id: id,
            is_goal: is_goal
        })

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - list admin types
function _getAdminTypes() {
    return new Promise((resolve, reject) => {
        let url = 'auth/list-admin-types';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - get permission list
function _getPermissions() {
    return new Promise((resolve, reject) => {
        let url = 'auth/config';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message)
            }
        }).catch((error) => {
            reject(error);
        })
    })
}

// API - get sip detail
const _getSIPDetail = (data) => {
    return new Promise((resolve, reject) => {
        let url = 'sip/detail';

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message)
            }
        }).catch((error) => {
            reject(error);
        })
    })
}

// API - manage kyc onboarding
const _manageKyc = (data) => {
    return new Promise((resolve, reject) => {
        let url = 'onboarding/kyc/action';

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API -get kyc 
const _getKYCRejectedReason = () => {
    return new Promise((resolve, reject) => {
        let url = 'onboarding/kyc/reason';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message)
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// logout function
function _logout() {
    let url = 'auth/logout';
    APIService(false, url).then((response) => {
        if (response.status_code === 200) {
            Cookies.remove('admin_session');
            window.location.href = '/';
        }
    });

}

// API - get zone
function _getZone() {
    return new Promise((resolve, reject) => {
        let url = 'client_mapping/zone/list';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - kyc profile
const _getKycProfile = (customer_id) => {
    let url = "onboarding/kyc/detail";
    let payload = JSON.stringify({
        customer_id
    })
    return APIService(true, url, payload).then((response) => {
        if (response.status_code === 200) {
            Store.dispatch({
                type: 'GET_KYC_DATA',
                payload: response.data
            });
            return response.data;
        } else {
            throw new Error(response.message);
        }
    });
};

const _getGoalDetail = (id, customer_id) => {
    return new Promise((resolve, reject) => {
        let url = 'goal/details';
        let payload = JSON.stringify({
            goal_id: id,
            is_invested: false,
            customer_id,
        })

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _setGoal = (customer_id, id, tenure, target, lumpsum, sip, step_sip, inflation_rate, available_corpus, name, investment_style_id, additional_data) => {
    return new Promise((resolve, reject) => {
        let url = 'goal/set';
        let payload = JSON.stringify({
            customer_id,
            goal_type_id: id,
            tenure,
            target,
            lumpsum,
            sip,
            step_sip,
            inflation_rate,
            available_corpus,
            name,
            investment_style_id,
            additional_data
        });

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export {
    _resendOTP,
    _getAdminProfileDetails,
    _searchMF,
    _addContent,
    _getOrderDetail,
    _getPortFolioDetail,
    _getAdminTypes,
    _getPermissions,
    _getSIPDetail,
    _manageKyc,
    _getKYCRejectedReason,
    _logout,
    _getZone,
    _getKycProfile,
    _getGoalDetail,
    _setGoal
}