/*
 *   File : list.js
 *   Author URI : https://evoqins.com
 *   Description : Explore page
 *   Integrations : NA
 *   Version : v1.1
 */

import { Fragment, useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import { NavigationHeader } from "../../../Components/Header";
import { CustomLoader, EmptyScreen } from "../../../Components/Other";
import { SearchInput, CustomSearchSelectBox } from "../../../Components/FormElements";
import { Icon } from "../../../Components/Icon";
import { ExploreDataTable } from "../../../Components/Table";
import { ExploreFilter } from "../../../Components/Popups";

import Colors from '../../../Styles/color.module.scss';

import APIService from "../../../Services/api-service";

const PAGE_NAVIGATION = [
    "Dashboard",
    "Explore funds"
];

const SORT_BY = [
    { value: 1, label: 'Returns - High to Low' },
    { value: 2, label: 'Rank - High to Low' },
    { value: 3, label: 'AUM - Low to High' },
    { value: 4, label: 'AUM - High to Low' },
    { value: 5, label: 'Popularity' },
];

const FUND_DATA = [
    {
        name: "Motilal Oswal Midcap Fund (G)",
        type: "Mid Cap Fund",
        image: "https://mastertrust-static-assets.s3.ap-south-1.amazonaws.com/amcs/motilal.png",
        cagr: 33.48,
        aum: 14445.55,
        risk: 1,
        rank: 5,
        risk_image: "https://mastertrust-static-assets.s3.ap-south-1.amazonaws.com/risk_images/Risk-high.png"
    }
]
export default function ExploreFunds(props) {
    const navigator = useNavigate();
    const location = useLocation();

    const filterRef = useRef();
    
    const [searchQuery, setSearchQuery] = useState("");
    const [sortBy, setSortBy] = useState({});
    const [filterCount, setFilterCount] = useState(null);
    const [showArrow, setShowArrow] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [sortByData, setSortByData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [lastItemReached, setLastItemStatus] = useState(false);
    const [fundList, setFundList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [filtersSelected, setFiltersSelected] = useState(0);
    const [filterTriggered, setFilterTriggered] = useState(false);
    const [investmentAmount, setInvestmentAmount] = useState(null);
    const [fundCategory, setFundCategory] = useState([null]);
    const [fundType, setFundType] = useState([null]);
    const [amc, setAmc] = useState([null]);
    const [theme, setTheme] = useState([null]);
    const [rating, setRating] = useState(null);
    const [filterBy, setFilterBy] = useState(null);

    const memoizedData = useMemo(() => {
        return {
            fundList
        };
    }, [fundList]);

    useEffect(() => {
        _getFilterData();
    }, []);

    useEffect(()=>{
        if (location.state !== null && Object.keys(location.state.filters).length !== 0 ) {
            const filter_data = location.state.filters;
            setInvestmentAmount(filter_data.mia);
            setFundCategory(filter_data.fund_category);
            setFundType(filter_data.fund_type);
            setAmc(filter_data.selected_amc);
            setTheme(filter_data.selected_theme);
            setRating(filter_data.rating);
            setFilterBy(filter_data.filter_by);
            _getActiveFilter(filter_data.mia, 
                filter_data.fund_category, 
                filter_data.fund_type, 
                filter_data.selected_amc, 
                filter_data.selected_theme, 
                filter_data.rating, 
                filter_data.filter_by
            );
        }
        console.log('location', location);
    },[]);

    useEffect(() => {
        if (showFilter === true) {
            // document.body.style.overflow = 'hidden';
            // document.body.style.height = '100vh';
            window.scrollTo({
                behavior: "smooth",
                top: 150
            });

        } else {
            document.body.removeAttribute("style");
        }
    }, [showFilter]);

    useEffect(() => {
        const handleScroll = (event) => {
            if (showFilter === true) {

                event.preventDefault();
                window.scrollTo({
                    behavior: "instant",
                    top: 150
                });

            }
        };

        // Add event listener when component mounts
        window.addEventListener('scroll', handleScroll);

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showFilter]);

    useEffect(() => {
        if (pageNumber > 1) {
            _getFundList(sortBy, true, searchQuery);
        }
    }, [pageNumber]);

    useEffect(()=>{
        if (filterTriggered === true) {
            _getFundList(sortBy, true, searchQuery);
        }
    },[filterTriggered]);

    function _handleSearchQuery(value) {
        setSearchQuery(value);
        if (value === "") {
            _getFundList(sortBy, false, "")
        }
    }

    // function _searchFund(query) {
    //     let url = 'mf-data/search';
    //     let payload = JSON.stringify({
    //         query
    //     });
    //     APIService(true, url, payload).then((response) => {

    //     })
    // }

    const _scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const _handleToggleFilter = () => {
        setShowFilter(!showFilter);
    }

    const _handleFilterData = (mia, fund_category, fund_type, selected_amc, selected_theme, rating, filter_by, status) => {

        if (status == true) { // customer selected "Apply Filters"
            // preloadFunds.current = false;
            setLoader(true);
            setFundList([]);
            setInvestmentAmount(mia);
            setFundCategory(fund_category);
            setFundType(fund_type);
            setAmc(selected_amc);
            setTheme(selected_theme);
            setRating(rating);
            setFilterBy(filter_by);
            setPageNumber(1);
            setLoader(true);
            setLastItemStatus(false);
            setFilterTriggered(true);
            navigator('/explore', {
                state : {
                    filters: {
                        mia,
                        fund_category,
                        fund_type,
                        selected_amc,
                        selected_theme,
                        rating,
                        filter_by
                    }
                },
                replace: true
            });
            
            _getActiveFilter(mia, fund_category, fund_type, selected_amc, selected_theme, rating, filter_by);
        }
        setShowFilter(false);
        _scrollToTop();
    }

    function _getActiveFilter(mia, fund_category, fund_type, selected_amc, selected_theme, rating, filter_by) {
        const filters = {
            mia,
            fund_category,
            fund_type,
            selected_amc,
            selected_theme,
            rating,
            filter_by
        };
        const selected_filters = Object.values(filters).filter(value => {
            if (Array.isArray(value)) {
                // Check if the array contains null values
                if (value.includes(null)) {
                    return false; // Exclude arrays with null values
                } else {
                    return value.some(item => item !== null);
                }
                // Check if the array contains non-null values

            } else {
                // Check if the value is not null
                return value !== null;
            }
        });
        setFilterCount(selected_filters.length);
    }

    function _handleTypeFilter(value) {
        setSortBy(value);
        _getFundList(value, false, searchQuery);
    }

    // handle pagination
    const _handlePageNumber = () => {
        setPageNumber(prevCount => prevCount + 1);
    }

    // handle table row click
    const _handleFundClick = (row) => {
        navigator("/explore/fund", {
            state: {
                fund_id: row.id,
            }
        });
    }

    // API - filter data
    function _getFilterData() {
        let url = 'mf-data/filter-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setFilterData(response.data);
                let reversed = response.data.sort.reverse();
                const sort_by_data = reversed.map(item => ({
                    value: item.id,
                    label: item.name
                }));
                setSortByData(sort_by_data);
                setSortBy(sort_by_data[0]);
                setFundList([]);
                if (location.state === null) {
                    _getFundList(sort_by_data[0]);
                }else{
                    setFilterTriggered(true);
                }
            }
        });
    }

    // API - fund list
    function _getFundList(sort_by, page_data_status = false, search = searchQuery) {
        let url = 'mf-data/list';
        let payload = JSON.stringify({
            amc: amc.filter(item => item && item.id !== null),
            category: fundCategory.filter(item => item && item.id !== null),
            type: fundType.filter(item => item && item.id !== null),
            theme: theme.filter(item => item && item.id !== null),
            sort_by: sort_by.value,
            page_num: pageNumber,
            query: search,
            min_investment_filter_id: investmentAmount,
            filter_by: filterBy,
            rank: rating,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (page_data_status === true) {
                    if (response.data.length !== 0) {
                        setFundList([...memoizedData.fundList, ...response.data]);
                        // const temp = [...fundList, ...response.data]
                        if (response.data.length >= 12 && response.total_no_of_records > 12) {
                            setLastItemStatus(false);
                        } else {
                            setLastItemStatus(true);
                        }

                    }
                    else {
                        setLastItemStatus(true);
                    }
                }
                // page_data_status=false means page number is 1 thus first time run so store the data
                else {
                    if (response.data.length !== 0) {
                        setFundList(response.data);
                        if (response.data.length >= 12 && response.total_no_of_records > 12) {
                            setLastItemStatus(false);
                        } else {
                            setLastItemStatus(true);
                        }

                    }
                    else {
                        setLastItemStatus(true);
                    }
                }
                setFilterTriggered(false);
            } else {
                if (page_data_status === false) {
                    setFundList([]);
                }
            }
            setLoader(false);
        })
    }
    return (
        <Fragment>
            <NavigationHeader
                type={1}
                data={PAGE_NAVIGATION}
                title="Explore funds" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top">
                <div className="row">
                    <div className="col-6">
                        <SearchInput placeholder="Search with fund name"
                            value={searchQuery}
                            className="padding-12px-tb "
                            valueChange={_handleSearchQuery}
                            handleSearch={() => _getFundList(sortBy)} />
                    </div>
                    <div className="col-6">
                        <div className="d-flex w-100 justify-content-end">
                            <CustomSearchSelectBox options={SORT_BY}
                                value={Object.keys(sortBy).length !== 0 ? sortBy : ""}
                                // label="Select user type"
                                className="w-50 me-3"
                                id="investment-type-dropdown"
                                hide_error_text={false}
                                placeholder="Sort by"
                                onSelectChange={_handleTypeFilter} />
                            <div className="position-relative" >
                                {
                                    filterCount > 0 &&
                                    <span className="color-white border-radius-100px e-poppins-bold e-font-12 line-height-12px e-filter-badge position-absolute bg-sea-green d-flex justify-content-center align-items-center">{filterCount}</span>
                                }
                                <div className="border-radius-6px p-2 border-bright-gray border-all bg-white  d-flex align-items-center z-index-10 h-100"
                                    onClick={_handleToggleFilter}>
                                    <p className="color-charleston-green e-poppins-medium e-font-14 line-height-24px mb-0 pe-1 cursor-pointer mt-0">Filter</p>
                                    <img draggable={false} src={require('../../../Assets/Images/Users/filter.png')}
                                        alt="Filter"
                                        width={24}
                                        height={24}
                                        className="cursor-pointer me-2" />
                                </div>
                                {
                                    showFilter === true &&
                                    <Fragment>
                                        <div className="position-relative" ref={filterRef}>
                                            <ExploreFilter 
                                                data={filterData}
                                                investmentAmount={investmentAmount}
                                                fundCategory={fundCategory}
                                                fundType={fundType}
                                                amc={amc}
                                                theme={theme}
                                                rating={rating}
                                                filterBy={filterBy}
                                                close={_handleToggleFilter}
                                                applyFilter={_handleFilterData} />
                                        </div>
                                        <div className="position-fixed w-100 z-index-8 h-100 left-0 top-20px cursor-default"
                                            onClick={() => setShowFilter(false)}>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {
                        loader === false &&
                        <div className="col-12 text-end mt-2 e-table-scroll-indicator d-sm-block d-none">
                            <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                <img draggable={false} src={require('../../../Assets/Images/scroll-gif.gif')} alt="scroll"
                                    width='24px'
                                    height='24px'
                                    className='e-rotate-gif' />
                                Scroll horizontally for view more  information</p>
                        </div>
                    }
                    {
                        loader === true ?
                            <CustomLoader />
                            :
                            <Fragment>

                                {
                                    fundList.length == 0 ?
                                        <>
                                            <div className="col-12 text-center margin-88px-top">
                                                <EmptyScreen image={require('../../../Assets/Images/Nodata/explore.svg')}
                                                    title="No result found for selected filter"
                                                    description=" " />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div id="table-wrap"
                                                className="e-fund-table-wrapper">
                                                {
                                                    loader === false &&
                                                    <ExploreDataTable data={memoizedData.fundList}
                                                        onSelect={_handleFundClick}
                                                        openModal={() => { }} />
                                                }

                                            </div>
                                            {/* view more link */}
                                            {
                                                lastItemReached === false &&
                                                <div className="d-flex justify-content-center align-items-center w-100 mt-3"
                                                    onClick={() => _handlePageNumber()}>
                                                    <p className="color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1 cursor-pointer">
                                                        View more
                                                    </p>
                                                    {
                                                        loader === false ?
                                                            <Icon icon="right-arrow"
                                                                size={24}
                                                                className="e-view-pending cursor-pointer" />
                                                            :
                                                            <img src={require("../../../Assets/Images/loader.gif")}
                                                                alt="data loader"
                                                                width={50} />
                                                    }
                                                </div>
                                            }
                                        </>
                                }
                            </Fragment>
                    }
                </div>
            </div>
        </Fragment>
    );
}   