
/*
 *   File : view-mf-holdings.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for list all the mf-holdings
 *   Integrations : null
 *   Version : v1.1
 */
import { useEffect } from 'react';
import Icon from '../Icon/icon';

import style from '../../Styles/Components/fund-manager.module.scss';


const FundManager = (props) => {

     // Modal close listener
     useEffect(() => {
        var my_modal = document.getElementById("fund-manager");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    return (
        <div className={`modal fade ${style.e_holdings_modal}`}
            id="fund-manager"
            tabIndex="-1"
            aria-labelledby="fund-manager"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white position-relative text-center d-flex align-items-center p-4 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="container ">
                        {/* Modal title */}
                        <div className="row">
                            <div className="col-12 text-start">
                                <h4 className="color-black e-poppins-medium e-font-18 cursor-pointer w-max-content">
                                    {props.data.name}
                                </h4>
                            </div>
                        </div>
                        {/* Modal body */}
                        <div className={`${style.e_table_wrapper} mt-3 text-start `}>
                            <p className='color-black e-poppins-regular e-font-16'>{props.data.about}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FundManager;