/*
 *   File : intiate-transaction.js
 *   Author URI : https://evoqins.com
 *   Description : Initiate transaction page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { useState, useRef, useEffect } from "react";
import { Fragment } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { useLocation } from "react-router-dom";

/* import components */
import { NavigationHeader } from "../../Components/Header";
import { CustomTab } from "../../Components/Tab";
import { CustomAmountInput, CustomSearchSelectBox, RadioGroup, SearchInput, SearchSelect } from "../../Components/FormElements";
import { GradientButton, SecondaryButton, OutlineButton } from "../../Components/Cta";
import OrderHistory from "./list";
import { CustomDateRangePicker, DatePicker } from "../../Components/FormElements";
import { KYCPopup, SIPDate } from "../../Components/Popups";
import { Icon } from "../../Components/Icon";

import { useClickOutside } from "../../Helper/helper-function";
import APIService from "../../Services/api-service";
import { OrderExecutionModal } from "../../Components/Modal";
import { CustomLoader, EmptyScreen, Amountchip } from "../../Components/Other";
import { _getDayOfMonth } from "../../Helper/helper-function";

const TAB_DATA = [
    {
        type: "Order execution",
        id: 1
    },
    {
        type: "Order history",
        id: 2
    },
];

const FIRST_INSTALLMENT = [
    { id: 1, label: 'Yes' },
    { id: 2, label: 'No' },
];

const MANDATE_TYPE = [
    { id: 1, label: 'E-mandate' },
    { id: 2, label: 'Physical mandate' }
]

const GOAL_CONFIG_TYPE = [
    { id: 1, label: 'Continue with goal investment' },
    { id: 2, label: 'Configure goal' }
]

const AMOUNT_SUGGESTIONS = [
    100000,
    200000,
    300000,
    400000,
    500000
]

const FOLIO_OPTION = { value: null, label: 'Create New Folio' };
const MANDATE_OPTION = { value: null, label: 'Create new mandate' };

const today = new Date();
const min_date = new Date(today);
min_date.setFullYear(min_date.getFullYear() + 1);

const max_date = new Date(today);
max_date.setFullYear(max_date.getFullYear() + 40);

const default_date = new Date(today);
default_date.setFullYear(default_date.getFullYear() + 5);

const start_date = new Date(today); // Initialize with the provided 'today' date
start_date.setMonth(start_date.getMonth() + 1);

const end_date = new Date(start_date);
end_date.setMonth(end_date.getMonth() + 1);

const InitiateTransaction = () => {
    const filterRef = useRef(null);
    const popupRef = useRef(null);
    const SELECTED_FILTER = useSelector(state => state.MT_ADMIN_STORE.OF_FILTERS);
    const location = useLocation();

    const userSearchRef = useRef(null);
    const fundSearchRef = useRef(null);
    const [tabIndex, setTabIndex] = useState(1);
    const [userError, setUserError] = useState('');
    const [investmentType, setInvestmentType] = useState({});
    const [investmentTypeError, setInvestmentTypeError] = useState('');
    const [amount, setAmount] = useState(0);
    const [amountError, setAmountError] = useState('');
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    // filter data
    const [investmentAmount, setInvestmentAmount] = useState([]);
    const [filterType, setFilterType] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [investedIn, setInvestedIn] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [pageLoader, setPageLoader] = useState(true);
    const [investmentTypeOptions, setInvestmentTypeOptions] = useState([]);
    const [investmentRadioOptions, setInvestmentRadioOptions] = useState([]);
    const [selectedTransactionType, setSelectedTransactionType] = useState('');
    const [transactionTypeError, setTransactionTypeError] = useState('');
    const [selectedGoalTransactionType, setSelectedGoalTransactionType] = useState(null);
    const [configData, setConfigData] = useState({});
    const [fundName, setFundName] = useState('');
    const [fundNameError, setFundNameError] = useState('');
    const [showFundDropDown, setShowFundDropDown] = useState(false);
    const [showUserDropDown, setShowUserDropDown] = useState(false);
    const [userSearchResult, setUserSearchResult] = useState([]);
    const [fundSearchResult, setFundSearchResult] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedFund, setSelectedFund] = useState('');
    const [selectedFundId, setSelectedFundId] = useState(null);
    const [mfDetail, setMfDetail] = useState(null);
    const [mfDetailBankOptions, setMfDetailBankOptions] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [bankError, setBankError] = useState('');
    const [mfDetailFolioOptions, setMfDetailFolioOptions] = useState([FOLIO_OPTION]);
    const [selectedFolio, setSelectedFolio] = useState({});
    const [folioError, setFolioError] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [firstInvestment, setFirstInvestment] = useState(1);
    const [sipDate, setSipDate] = useState("");
    const [sipDateError, setSipDateError] = useState('');
    const [mandateOptions, setMandateOptions] = useState([MANDATE_OPTION]);
    const [selectedMandate, setSelectedMandate] = useState({});
    const [mandateError, setMandateError] = useState('');
    const [cartData, setCartData] = useState([]);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [deleteIconRed, setDeleteIconRed] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState('');
    const [pageNum, setPageNum] = useState(1);
    const [orderHistory, setOrderHistory] = useState([]);
    const [orderHistoryLoader, setOrderHistoryLoader] = useState(false);
    const [goals, setGoals] = useState([]);
    const [selectedGoal, setSelectedGoal] = useState({});
    const [selectedGoalError, setSelectedGoalError] = useState('');
    const [goalLumpsum, setGoalLumpsum] = useState(0);
    const [goalLumpsumError, setGoalLumpsumError] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [pageSize, setPageSize] = useState(20);
    const [expiryDate, setExpiryDate] = useState(format(max_date, "dd-MM-yyyy"));
    const [expiryDateError, setExpiryDateError] = useState("");
    const [mandateStartDate, setMandateStartDate] = useState(format(start_date, "dd-MM-yyyy"));

    // investment type goal
    const [goalDetailBankOptions, setGoalDetailBankOptions] = useState([]);
    const [selectedGoalBank, setSelectedGoalBank] = useState({});
    const [goalBankError, setGoalBankError] = useState('');
    const [goalDetail, setGoalDetail] = useState({});
    const [investmentStyleOptions, setInvestmentStyleOptions] = useState([]);
    const [selectedInvestmentStyle, setSelectedInvestmentStyle] = useState({});
    const [investmentStyleError, setInvestmentStyleError] = useState("");
    const [sipDatesArray, setSipDatesArray] = useState([]);
    const [paginationData, setPaginationData] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [displayStartDate, setDisplayStartDate] = useState("Start date");
    const [displayEndDate, setDisplayEndDate] = useState("End date");
    const [isPendingOrder, setIsPendingOrder] = useState(true);
    const [filterCount, setFilterCount] = useState(0);

    // redeem data states
    const [goalRedeemData, setGoalRedeemData] = useState({});
    const [selectedRedeemBank, setSelectedRedeemBank] = useState({});
    const [selectedRedeemBankError, setSelectedRedeemBankError] = useState("");
    const [selectedRedeemGoal, setSelectedRedeemGoal] = useState({});
    const [selectedRedeemGoalError, setSelectedRedeemGoalError] = useState("")
    const [goalRedeemAll, setGoalRedeemAll] = useState(false);
    const [goalRedeemAmount, setGoalRedeemAmount] = useState(0);
    const [goalRedeemAmountError, setGoalRedeemAmountError] = useState("");

    const [selectedRedeemFolio, setSelectedRedeemFolio] = useState({});
    const [selectedRedeemFolioError, setSelectedRedeemFolioError] = useState('');
    const [mfRedeemFolioOptions, setmfRedeemFolioOptions] = useState([]);
    const [mfRedeemUnits, setMfRedeemUnits] = useState(0);
    const [mandateLimit, setMandateLimit] = useState("");
    const [mandateLimitError, setMandateLimitError] = useState("");
    // transaction type statement if empty
    const [transactionStatement, setTransactionStatement] = useState("");
    const [mfRedeemUnitsError, setMfRedeemUnitsError] = useState("");
    const [isDematUser, setIsDematUser] = useState(false);
    const [sipEndDate, setSipEndDate] = useState("");
    const [sipEndDateMinDate, setSipEndDateMinDate] = useState(null);

    // mt-transactions
    const [selectedMtTransactionFund, setSelectedMtTransactionFund] = useState({});
    const [mtTransactionFundError, setMtTransactionError] = useState("");
    const [selectedFundBank, setSelectedFundBank] = useState({});
    const [selectedFundBankError, setSelectedFundBankError] = useState("");
    const [selectedFundFolio, setSelectedFundFolio] = useState({});
    const [selectedFundFolioError, setSelectedFundFolioError] = useState("");
    const [transactionFundAmount, setTransactionFundAmount] = useState("");
    const [transactionFundAmountError, setTransactionFundAmountError] = useState("");
    const [switchAll, setSwitchAll] = useState(1);
    const [installment, setInstallment] = useState('');
    const [installmentError, setInstallmentError] = useState("");
    const [selectedAmcFund, setSelectedAmcFund] = useState("");
    const [selectedAmcFundError, setSelectedAmcFundError] = useState("");
    const [amcFundError, setAmcFundError] = useState("");
    const [amcSearchResult, setAmcSearchResult] = useState("");
    const [selectedAmcFundId, setSelectedAmcFundId] = useState("");
    const [showAmcFundDropdown, setShowAmcFundDropdown] = useState(false);
    const [monthString, setMonthString] = useState("");
    const [mfEnabledDate, setMfEnabledDate] = useState([]);

    const [selectedGoalConfigType, setSelectedGoalConfigType] = useState(1);

    const [mandateType, setMandateType] = useState(1);

    useEffect(() => {
        if (location.state !== null) {
            setSelectedUserId(parseInt(location.state.customer_id));
            setSelectedUser(location.state.customer_name);
            setAmount(location.state.sip_amount);
            setGoalLumpsum(location.state.lumpsum);
            if (location.state.lumpsum > 0) {
                setSelectedGoalTransactionType(1);
            } else {
                setSelectedGoalTransactionType(2);
            }
            setSelectedInvestmentStyle(location.state.investment_style);
        }
    }, [location]);

    useEffect(() => {
        if (Object.keys(SELECTED_FILTER).length !== 0) {
            setInvestmentAmount(SELECTED_FILTER.investmentAmount);
            setFilterType(SELECTED_FILTER.filterType);
            setPaymentStatus(SELECTED_FILTER.paymentStatus);
            setOrderStatus(SELECTED_FILTER.orderStatus);
            setInvestedIn(SELECTED_FILTER.investedIn);
        }
    }, [SELECTED_FILTER]);

    useEffect(() => {
        if (sipDate) {
            console.log('sipDate', sipDate);
            const current_date = new Date();
            let month = current_date.getMonth() + 1;
            current_date.setMonth(month);
            current_date.setDate(parseInt(sipDate));
            setSipEndDateMinDate(current_date);
            const sip_default_date = new Date(current_date);
            sip_default_date.setFullYear(sip_default_date.getFullYear() + 2);
            setSipEndDate(format(sip_default_date, "dd-MM-yyyy"));
        }
    }, [sipDate]);

    useEffect(() => {
        _getConfigData();
        _getOrderFilterData();

        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        let date_array = [];
        if (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) {
            date_array = sipDatesArray;
        }
        if ((selectedTransactionType === 6 || selectedTransactionType === 7) || Object.keys(goalRedeemData).length > 0) {
            date_array = goalRedeemData.allowed_dates;
        }
        if ((selectedTransactionType === 2 || selectedTransactionType === 9) && mfEnabledDate.length !== 0) {
            date_array = mfEnabledDate;
        }
        const { date, month } = _getDayOfMonth(date_array);
        // if (sipDate === '') {
        setSipDate(`${date}`);
        setMonthString(month);
        // }
    }, [selectedTransactionType, selectedGoalTransactionType, goalRedeemData, sipDatesArray, mfEnabledDate]);

    useEffect(() => {
        setFundSearchResult([]);
        setSelectedFund('');
        setSelectedFundId(null);
        setMfDetail(null);
        setCartData([]);
        setMfDetailBankOptions([]);
        if (location.state === null) {
            setSelectedGoal({});
            setSelectedInvestmentStyle({});
            setAmount(0);
            setGoalLumpsum(0);
        }

        setSelectedGoalBank({});
        setSelectedMandate({});
        setSelectedRedeemBank({});
        setSelectedRedeemGoal({});
        setGoalRedeemAll(true);
        setGoalRedeemAmount(0);
        setSelectedRedeemFolio({});
        setmfRedeemFolioOptions([]);
        setUserError('')
        setInvestmentTypeError('')
        setAmountError('')
        setTransactionTypeError('')
        setFundNameError('')
        setBankError('')
        setFolioError('')
        setSipDateError('')
        setMandateError('')
        setSelectedGoalError('')
        setGoalLumpsumError('')
        setGoalBankError('')
        setInvestmentStyleError('')
        setSelectedRedeemBankError('')
        setSelectedRedeemGoalError('')
        setGoalRedeemAmountError('');
        setMfRedeemUnitsError("");
        setSelectedRedeemFolioError('');
        setExpiryDate(format(max_date, "dd-MM-yyyy"));
        setExpiryDateError('');
        setMandateLimit('');
        setMandateLimitError('');
        setSelectedMtTransactionFund({});
        setMtTransactionError("");
        setSelectedFundBank({});
        setSelectedFundBankError("");
        setSelectedFundFolio({});
        setSelectedFundFolioError("");
        setTransactionFundAmount("");
        setTransactionFundAmountError("");
        setSwitchAll(1);
        setInstallment("");
        setInstallmentError("");
        setSelectedAmcFund("");
        setSelectedAmcFundError("");
        setAmcFundError("");
        const { date, month } = _getDayOfMonth();
        setSipDate(`${date}`);
        setMonthString(month);
    }, [selectedTransactionType, investmentType, selectedGoalTransactionType])

    useEffect(() => {
        if (selectedGoalTransactionType === 3) {
            _getGoalRedeemData();
        }
    }, [selectedGoalTransactionType])

    useEffect(() => {
        const transaction_type = [3, 5, 6, 7]; // redeem, switch, swp, stp 
        if (transaction_type.includes(selectedTransactionType)) {
            _getMFRedeemData();
        }
    }, [selectedTransactionType, selectedMtTransactionFund, selectedRedeemGoal]);

    useEffect(() => {
        if (showSuccessModal === true) {
            const modal = new Modal(document.getElementById("order-execution-sucess"));
            modal.show();
        }
    }, [showSuccessModal]);

    useEffect(() => {
        if (Object.keys(selectedGoal).length !== 0) {
            _getGoalInvestData();
        } else if (selectedFundId !== null) {
            _getMfDetail(selectedFundId);
        }
    }, [selectedGoal, firstInvestment, sipDate, selectedFundId, selectedInvestmentStyle])

    useEffect(() => {
        if (Object.keys(selectedGoalBank).length !== 0) {
            let mandates = [MANDATE_OPTION];
            selectedGoalBank.mandates.map((item) => {
                mandates.push({ value: item.id, label: item.mandate_id });
            });
            if (mandates.length > 1) {
                _selectMandate(mandates[1]);
            }
            else {
                _selectMandate(mandates[0]);
            }
            setMandateOptions(mandates);
        }
    }, [selectedGoalBank])

    useEffect(() => {
        if (selectedBank && (selectedTransactionType == 2 || selectedTransactionType == 9)) { // execute logic to find mandate only if the transaction type is SIP
            let mandates = [MANDATE_OPTION];
            selectedBank.mandate.map((item) => {
                mandates.push({ value: item.id, label: item.mandate_id });
            });
            setMandateOptions(mandates);
            // selected bank have any mandate setting create new as default
            // if any mandate then setting first item as default
            if (selectedBank.mandate.length === 0) {
                _selectMandate(mandates[0]);
            } else {
                _selectMandate(mandates[1]);
            }
        }
    }, [selectedBank])

    useEffect(() => {
        if (Object.keys(selectedInvestmentStyle).length !== 0 && Object.keys(goalDetail).length !== 0) {
            const investment_style_type = goalDetail.goal_data.investment_style.find((item) => item.id === selectedInvestmentStyle.value);
            setSipDatesArray(investment_style_type.sip_dates);
        }
    }, [selectedInvestmentStyle])

    useEffect(() => {
        if (goalRedeemAll === true) {
            if (Object.keys(selectedRedeemGoal).length !== 0) {
                setGoalRedeemAmount(selectedRedeemGoal.current_amount);
                setMfRedeemUnits(selectedRedeemGoal.total_units);
            }
            if (mfRedeemFolioOptions.length !== 0) {
                setSelectedRedeemFolio(mfRedeemFolioOptions[0]);

            }
            setSelectedRedeemFolioError("");
        }
        else {
            setSelectedRedeemFolio({});
        }
    }, [goalRedeemAll, selectedRedeemGoal, mfRedeemFolioOptions])

    useEffect(() => {
        if (selectedTransactionType === 3 && Object.keys(selectedRedeemGoal).length !== 0) {
            const formatted_folio = selectedRedeemGoal.folios.map(folio => ({
                value: folio.folio_no,
                label: `Folio no: ${folio.folio_no} - Units: ${folio.units}`,
                units: folio.units
            }));
            setmfRedeemFolioOptions(formatted_folio);
        }
    }, [selectedRedeemGoal])

    useEffect(() => {
        if (Object.keys(selectedMtTransactionFund).length !== 0) {
            const formatted_folio = selectedMtTransactionFund.folios.map(folio => ({
                value: folio.folio_no,
                label: `Folio no: ${folio.folio_no} - Units: ${folio.units}`,
                unit: folio.units,
            }));
            setmfRedeemFolioOptions(formatted_folio);
            if (formatted_folio.length) {
                setSelectedFundFolio(formatted_folio[0]);
            }
        }
    }, [selectedMtTransactionFund]);

    useEffect(() => {
        if (Object.keys(selectedMtTransactionFund).length !== 0 && switchAll === 1 && selectedTransactionType === 5) {
            setTransactionFundAmount(selectedMtTransactionFund.current_amount);
        }
    }, [selectedMtTransactionFund, switchAll, selectedFundFolio]);

    useClickOutside(filterRef, () => {
        setShowFilter(false);
    });

    useClickOutside(popupRef, () => {
        setShowDateFilter(false);
    });

    useClickOutside(userSearchRef, () => {
        setShowUserDropDown(false);
        setUserSearchResult([]);
    });

    useClickOutside(fundSearchRef, () => {
        setShowFundDropDown(false);
        setFundSearchResult([]);
    });

    const _handleApplyFilter = (filter_type, payment_status, order_status) => {
        setShowFilter(false);
        setFilterType(filter_type);
        setPaymentStatus(payment_status);
        setOrderStatus(order_status);
        console.log(filter_type)
        _orderHistory(pageNum, pageSize, searchQuery, startDate, endDate, filter_type, payment_status, order_status)
    }


    const _handleDateRangeChange = (newRange) => {
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "yyyy-MM-dd"));
        setEndDate(format(newRange.endDate, "yyyy-MM-dd"));
        setDisplayStartDate(format(newRange.startDate, "dd-MM-yyyy"));
        setDisplayEndDate(format(newRange.endDate, 'dd-MM-yyyy'));
        setSelectedRange(newRange);
        _orderHistory(1, pageSize, searchQuery, format(newRange.startDate, "yyyy-MM-dd"), format(newRange.endDate, "yyyy-MM-dd"))
    };

    const _handleShowDateFilter = () => {
        setShowDateFilter(!showDateFilter);
    }


    function _handleSIPEndDate(value) {
        setSipEndDate(value);
    }

    const _handleClearDates = () => {
        setShowDateFilter(false);
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        setStartDate("Start date");
        setEndDate("End date");
        _orderHistory(1, pageSize, searchQuery, "Start date", "End date", filterType, paymentStatus, orderStatus)
    }


    const _handleToggleFilter = () => {
        setShowFilter(!showFilter);
        window.scrollTo({
            top: 40,
            behavior: "smooth"
        })
    }

    const _handleTabIndex = (id) => {
        if (id == 2) {
            setPageNum(1);
            setOrderHistoryLoader(true);
            _orderHistory(1);
        }
        setTabIndex(id);
    }

    function _handleDateChange(value) {
        setExpiryDate(value);
        setExpiryDateError("");
    }

    function _handleStartDate(value) {
        setMandateStartDate(value);
    }

    function _handleChipSelect(index) {
        setMandateLimit(AMOUNT_SUGGESTIONS[index]);
        setMandateLimitError("");
    }


    const _selectMFTransactionType = (investment_type) => {
        setSelectedTransactionType(investment_type);
        setTransactionTypeError('');
    }

    const _selectGoalTransactionType = (investment_type) => {
        setSelectedGoalTransactionType(investment_type);
    }

    const _searchFund = (search_query) => {
        setMfDetail(null);
        setSelectedFundId(null);
        setSelectedFund(search_query);
        setFundNameError('');
        if (search_query === '') {
            setShowFundDropDown(false);
            setAmount(0);
            setSelectedFolio({});
            setSelectedBank(null);
        }

        // enable search only if the query is greater than 2
        if (search_query.length > 2) {
            _getFundName(search_query);
        } else {
            setShowFundDropDown(false);
            setFundSearchResult([]); // clear search suggestion
        }
    }

    // select folio 
    const _selectFolio = (folio_no) => {
        setSelectedFolio(folio_no);
        setFolioError('');
    }

    const _selectMFRedeemFolio = (folio_no) => {
        console.log("Folio no", folio_no);
        setSelectedRedeemFolio(folio_no);
        setSelectedRedeemFolioError('');
    }

    // select bank account 
    const _selectBank = (bank_info) => {
        setSelectedBank(bank_info);
        console.log('bank_infot', bank_info);
        setBankError('');
    }

    const _selectGoalBank = (bank_info) => {
        setSelectedGoalBank(bank_info);
        setGoalBankError('');
    }

    const _selectInvestmentStyle = (invest_style) => {
        setSelectedInvestmentStyle(invest_style);
        setInvestmentStyleError('');
    }

    const _selectGoalRedeem = (goal_redeem) => {
        setSelectedRedeemGoal(goal_redeem);
        setSelectedRedeemGoalError("");
        setSelectedRedeemFolio({});
        setSelectedRedeemFolioError("");
        setGoalRedeemAmount(0);
        setGoalRedeemAmountError("");
        setMfRedeemUnitsError("");
        setMfRedeemUnits(0);
        setGoalRedeemAll(true);
        // setSelectedRedeemBank({});
        setMfRedeemUnitsError("");
    }

    const _selectGoalRedeemBank = (bank_info) => {
        setSelectedRedeemBank(bank_info);
        setSelectedRedeemBankError("");
    }

    const _convertKeys = (array) => {
        return array.map(item => {
            const converted_keys = {
                value: item.bank_id || item.id,
                label: item.name,
                ...item
            };

            // Remove original keys
            if (converted_keys.value !== undefined) {
                delete converted_keys.bank_id;
                delete converted_keys.id;
            }

            if (converted_keys.label !== undefined) {
                delete converted_keys.name;
            }

            // Remove other original keys as needed
            return converted_keys;
        });
    };

    // select bank account 
    const _selectMandate = (mandate_id) => {
        setSelectedMandate(mandate_id);
        setMandateError('');
    }

    // select investment type : it can be "Goal" or "MF"  
    // type 1 = goal, type 2 = mf
    const _setInvestmentType = (selected_type) => {
        setInvestmentTypeError('');
        setInvestmentType(selected_type);
        setInvestmentRadioOptions(selected_type.transaction_type);
        console.log("selected_type", selected_type);
        if (selected_type.transaction_type.length !== 0) {
            if (selected_type.value == 1) { // set default investment type for Goal investment
                if (location.state === null) {
                    setSelectedGoalTransactionType(selected_type.transaction_type[0].id);
                }
            } else {
                setSelectedTransactionType(selected_type.transaction_type[0].id);
                console.log('investment type', '_setIvestmentTYpe');
            }
            setTransactionStatement("");
        }
        else {
            setTransactionStatement("No transaction type found");
        }
    }

    // select goal form admin configured
    const _selectGoal = (selected_goal) => {
        setSelectedGoal(selected_goal);
        setSelectedGoalError("")
    }

    const _handleInput = (input, type) => {
        switch (type) {
            case 1:
                setUserError('');
                setSelectedUser(input);
                if (input === '') {
                    _clearForm();
                } else {
                    _userSearch(input);
                }
                break;
            case 8:
                setSelectedMandate(input);
                setMandateError('');
            default:

        }
    }

    const _clearForm = () => {
        setSelectedUser('');
        setSelectedUserId(null);
        setInvestmentType({});
        setSelectedTransactionType('');
        setSelectedFund('');
        setSelectedFundId(null);
        setAmount(0);
        setSelectedFolio({});
        setSelectedBank('');
        setMfDetail(null);
        setShowUserDropDown(false);
        setUserSearchResult([]);
        setGoalLumpsum(0)
        setGoalDetailBankOptions(null);
        setSelectedGoalBank({});
        setGoalDetail({});
        setSelectedGoal({});
        setInvestmentStyleOptions([]);
        setSelectedInvestmentStyle({});
        setSelectedMtTransactionFund({});
        setMtTransactionError("");
        setSelectedFundBank({});
        setSelectedFundBankError("");
        setmfRedeemFolioOptions([]);
        setSelectedFundFolio({});
        setSelectedFundFolioError("");
        setTransactionFundAmount("");
        setTransactionFundAmountError("");
        setSwitchAll(1);
        setInstallment("");
        setInstallmentError("");
        setSelectedAmcFund("");
        setSelectedAmcFundError("");
        setAmcFundError("");
        setGoalRedeemData({});
    }

    // handle select user search
    function _handleSelectUserSearch(dropdown, id, label, data) {
        setShowUserDropDown(dropdown);
        setSelectedUserId(id);
        setSelectedUser(label);
        setUserError('');
        setIsDematUser(data.is_demat);
    }

    // handle fund search change 
    function _handleFundSearchSelect(dropdown, id, label, mf_data) {

        if (selectedTransactionType === 4) {
            let cart_index = cartData.findIndex((item) => item.mf_id == id);
            if (cart_index != -1) { // show error and exit from the function
                toast.dismiss();
                toast.error('Selected mutual fund already exists in Cart.', {
                    type: "error"
                });
                return;
            }
        }
        _getMfDetail(id, mf_data);
        setShowFundDropDown(dropdown);
        setSelectedFund(label);
        setFundNameError('');
        setSelectedFundId(id);
    }



    // handle first installment radio
    const _handleRadioInput = (instalment_id) => {
        setFirstInvestment(instalment_id);
    }

    const _handleMandateTypeRadio = (value) => {
        setMandateType(value);
    }

    // date selected form SIP dates. It will be always between 1-28.
    const _handleDateSelected = (date) => {
        setSipDate(date.toString());
    }

    // handle cart item amount change
    function _handleCartAmount(amount, key) {
        let cart_data = cartData;
        cart_data[key].amount = amount;
        cart_data[key].amount_error = '';
        setCartData([...cart_data]);
    }

    // validate amount on input blur
    function _handleCartAmountBlur(item, key) {
        let cart_data = cartData;
        if (cart_data[key].amount < cart_data[key].min_lumpsum_investment || cart_data[key].amount < cart_data[key].min_sip_investment) {
            cart_data[key].amount_error = 'Amount must exceed minimum SIP/Lumpsum.';
            setCartData([...cart_data]);
        }
    }

    // handle cart item folio change 
    function _handleCartFolio(folio, key) {
        let cart_data = cartData;
        cart_data[key].selected_folio = folio;
        cart_data[key].folio_error = '';
        setCartData([...cart_data]);
    }

    const _updateGoalLumpsumValue = (amount) => {
        if (amount.length !== 0) {
            setGoalLumpsum(amount);
        } else {
            setGoalLumpsum(0);
        }
        setGoalLumpsumError("");
    }

    function _openPaymentModal(url) {
        setPaymentUrl(url);
        setIsPendingOrder(true);
        setShowSuccessModal(true);
    }

    // success modal close 
    function _handleModalClose() {
        setShowSuccessModal(false);
        setIsPendingOrder(true);
    }

    // handle mt transaction fund select
    function _selectFundMtTransaction(value) {
        setSelectedMtTransactionFund(value);
        setMtTransactionError("");
        setInstallmentError("");
        setTransactionFundAmountError("");
        setSelectedAmcFundError("");
        setTransactionFundAmount("");
        setSelectedFundFolio({});
        setSelectedFundBank({});
        setSelectedAmcFund("");
        setSelectedAmcFundId("");
    }

    // handle mt transaction bank select
    const _selectFundBank = (bank_info) => {
        setSelectedFundBank(bank_info);
        setSelectedFundBankError("");
    }

    // handle mt transaction folio select 
    const _selectMtTransactionFolio = (folio) => {
        setSelectedFundFolio(folio);
        setSelectedFundBankError("");
    }
    // handle mt transaction amount 
    const _addFundAmount = (value) => {
        setTransactionFundAmount(value);
        setTransactionFundAmountError("");
    }

    // handle mt installments 
    const _handleInstallment = (value) => {
        setInstallment(value);
        setInstallmentError("");
    }

    // handle mt switch all
    const _handleSwitchAll = (instalment_id) => {
        setSwitchAll(instalment_id);
    }

    // handle mt fund search change 
    function _handleAmcSearchSelect(dropdown, id, label) {

        setShowAmcFundDropdown(dropdown);
        setSelectedAmcFund(label);
        setSelectedAmcFundError('');
        setSelectedAmcFundId(id);
    }

    // validation mt transaction fund
    function _handleMTTransactionFormValidation() {
        let valid_form = true;
        if (Object.keys(selectedMtTransactionFund).length === 0) {
            setMtTransactionError('Mutual Fund is required');
            _scrollToElement('goal-redeem-dropdown');
            valid_form = false;
        }
        // if (Object.keys(selectedFundBank).length === 0) {
        //     setSelectedFundBankError("Bank is required");
        //     _scrollToElement('goal-bank-dropdown');
        //     valid_form = false;
        // }
        if (Object.keys(selectedFundFolio).length === 0) {
            setSelectedFundFolioError("Folio is required");
            _scrollToElement('selected-fund-folio-dropdown');
            valid_form = false;
        }
        if (transactionFundAmount === "") {
            setTransactionFundAmountError("Amount is required");
            _scrollToElement('selected-fund-amount-input');
            valid_form = false;
        }
        if (selectedTransactionType !== 6 && selectedAmcFund === '') {
            setSelectedAmcFundError('Fund is required');
            _scrollToElement('fund-search');
            valid_form = false;
        }
        if (selectedTransactionType !== 5 && installment === '') {
            setInstallmentError('Installment is required');
            _scrollToElement('installment-input');
            valid_form = false;
        }
        if (valid_form === true) {
            _createOrder();
        }

    }

    const _searchAmcFund = (search_query) => {
        // setMfDetail(null);
        setSelectedAmcFund(search_query);
        setAmcFundError('');
        if (search_query === '') {
            setShowAmcFundDropdown(false);
        }

        // enable search only if the query is greater than 2
        if (search_query.length > 2) {
            _getAmcFund(search_query);
        } else {
            setShowAmcFundDropdown(false);
            setAmcSearchResult([]); // clear search suggestion
        }
    }

    // return create-order payload based on transaction type
    function _getCreateOrderPayload() {
        let formatted_date = null;
        if (sipEndDate !== "") {
            const parts = sipEndDate.split('-');
            formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];
        }
        const payload = {
            id: selectedTransactionType > 4 && selectedTransactionType < 8 ? selectedMtTransactionFund.value : selectedTransactionType === 3 ? selectedRedeemGoal.value : selectedFundId,
            customer_id: selectedUserId,
            investment_type: investmentType.value,
            transaction_type: selectedTransactionType,
            lumpsum: null,
            sip: null,
            redeem: null,
            cart: null,
            goal_data: null,
            folio_number: selectedTransactionType > 4 && selectedTransactionType < 8 ? selectedFundFolio.value : isDematUser !== true ? selectedFolio.value : null,
            stp: null,
            swp: null,
            switch: null
        };

        switch (selectedTransactionType) {
            case 1:
                payload.lumpsum = {
                    amount,
                    bank_id: selectedBank.value
                };
                break;
            case 2:
                payload.sip = {
                    amount,
                    date: sipDate,
                    step_sip: 0,
                    generate_today: firstInvestment === 1 ? true : false,
                    mandate_id: selectedMandate.value,
                    sip_end_date: formatted_date,
                    // end_date: _formatDate(expiryDate),
                    // limit: mandateLimit
                };
                break;
            case 3:
                payload.redeem = {
                    amount: goalRedeemAmount,
                    redeem_all: goalRedeemAll,
                    // bank_id: selectedRedeemBank.value,
                };
                payload.folio_number = selectedRedeemFolio.value;
                break;
            case 4:
                let cart_data = [];
                for (let cart_index = 0; cart_index <= cartData.length - 1; cart_index++) {
                    cart_data[cart_index] = {};
                    cart_data[cart_index].folio_no = cartData[cart_index]['selected_folio'].value;
                    cart_data[cart_index].mf_id = cartData[cart_index].mf_id;
                    cart_data[cart_index].amount = cartData[cart_index].amount;
                }

                payload.cart = {
                    'cart_items': cart_data,
                    'bank_id': selectedBank.value,
                };
                break;
            case 5:
                payload.switch = {
                    switch_in_mf_id: selectedAmcFundId,
                    amount: transactionFundAmount,
                    generate_today: true,
                    redeem_all: switchAll === 1 ? true : false
                }
                break;
            case 6:
                payload.swp = {
                    amount: transactionFundAmount,
                    sip_date: sipDate,
                    installments: installment,
                    generate_today: firstInvestment === 1 ? true : false,
                }
                break;
            case 7:
                payload.stp = {
                    switch_in_mf_id: selectedAmcFundId,
                    amount: transactionFundAmount,
                    sip_date: sipDate,
                    installments: installment,
                    generate_today: firstInvestment === 1 ? true : false,
                }
                break;
            case 8:
                payload.lumpsum = {
                    amount,
                    bank_id: selectedBank.value
                };
                break;
            case 9:
                payload.sip = {
                    amount,
                    date: sipDate,
                    step_sip: 0,
                    generate_today: firstInvestment === 1 ? true : false,
                    mandate_id: selectedMandate.value,
                    sip_end_date: formatted_date,
                    // end_date: _formatDate(expiryDate),
                    // limit: mandateLimit
                };
                break;
        }

        return JSON.stringify(payload);
    }

    const _formatDate = (date) => {
        const parts = date.split('-');
        const formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];
        return formatted_date;
    }

    const _getCreateGoalPayload = () => {
        let formatted_date = null;
        if (sipEndDate !== "") {
            const parts = sipEndDate.split('-');
            formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];
        }
        const payload = {
            id: selectedGoalTransactionType === 3 ? selectedRedeemGoal.value : selectedGoal.goal_type_id,
            customer_id: selectedUserId,
            investment_type: investmentType.value,
            transaction_type: selectedGoalTransactionType,
            lumpsum: null,
            sip: null,
            redeem: null,
            cart: null,
            goal_data: null,
            folio_number: selectedFolio.value
        };

        switch (selectedGoalTransactionType) {
            case 1:

                payload.lumpsum = {
                    amount: goalLumpsum,
                    bank_id: selectedGoalBank.value
                };

                payload.sip = {
                    amount,
                    date: sipDate,
                    step_sip: 0,
                    // generate_today: firstInvestment === 1 ? true : false,
                    mandate_id: selectedMandate.value,
                    sip_end_date: formatted_date
                    // end_date: _formatDate(expiryDate),
                    // limit: mandateLimit
                };

                break;
            case 2:
                payload.sip = {
                    amount,
                    date: sipDate,
                    step_sip: 0,
                    // generate_today: firstInvestment === 1 ? true : false,
                    mandate_id: selectedMandate.value,
                    sip_end_date: formatted_date
                    // end_date: _formatDate(expiryDate),
                    // limit: mandateLimit
                };
                break;
            case 3:
                payload.redeem = {
                    amount: goalRedeemAmount,
                    redeem_all: goalRedeemAll,
                    bank_id: selectedRedeemBank.value,
                };
                break;
        }

        return JSON.stringify(payload);
    }

    const _formatArray = (source_array, target_keys) => {
        source_array.forEach(obj => {
            for (let processed_key in target_keys) {
                console.log('processed_key', processed_key);
                console.log('target_keys', target_keys);
                obj[target_keys[processed_key]] = obj[processed_key];
                delete obj[processed_key];
            }
        });
        return source_array;

    }

    // search handler
    function _handleSearchQuery(value) {
        setSearchQuery(value);
        if (value === '') {
            _orderHistory(1, pageSize, '')
        }
    }

    // calculate NAV with submitted amount and NAV of selected fund
    const _calculateUnits = (amount, nav) => {
        if (amount === 0) {
            return 0;
        } else {
            return (amount / nav.current_nav).toFixed(2);
        }
    }

    const _handleDeleteCart = (mf_id, key) => {
        const cart_data = cartData;
        cart_data.splice(key, 1);
        setCartData([...cart_data]);
    }

    const _addAmount = (value) => {
        if (value.length !== 0) {
            setAmount(value);
        } else {
            setAmount(0);
        }
        setAmountError("");
    }

    const _addRedeemAmount = (value) => {
        if (value.length !== 0) {
            setGoalRedeemAmount(value);
            if (Object.keys(selectedRedeemGoal).length) {
                setMfRedeemUnits((value / selectedRedeemGoal.nav.current_nav).toFixed(4));
            }
        } else {
            setMfRedeemUnits(0);
            setGoalRedeemAmount(0);
        }

        setGoalRedeemAmountError("");
        setMfRedeemUnitsError("");
    }

    const _addRedeemUnits = (value) => {
        setGoalRedeemAmount(selectedRedeemGoal.nav.current_nav * value);
        setMfRedeemUnits(value);
        setMfRedeemUnitsError("");
        setGoalRedeemAmountError("");
    }

    const _handleRedeemAll = () => {
        console.log('inside native fucntin');
        setGoalRedeemAll(!goalRedeemAll);
        setGoalRedeemAmountError('');
        setMfRedeemUnitsError("");
    }

    const _handleValidate = () => {
        if (investmentType.value == 2) {
            _validateMFForm();
        } else {
            _validateGoalForm();
        }
    }



    const _handleAmountInputBlur = () => {
        switch (investmentType.value) {
            case 2: _validateMfMinAmountOnBlur(selectedTransactionType);
                break;
            case 1: _validateGoalMinAmountOnBlur(selectedGoalTransactionType);
        }
    }


    function _handleMandateLimit(value) {
        setMandateLimit(value);
        setMandateLimitError("");
    }

    // handle goal config type radio
    function _handleGoalConfigType(value) {
        setSelectedGoalConfigType(value);
    }

    // navigate to goal listing
    function _navigateToGoalListing() {
        window.open(`${window.location.href}/goal?customer_id=${selectedUserId}&name=${encodeURIComponent(selectedUser)}`);
    }

    // Scroll to form element  
    function _scrollToElement(id) {
        console.log('inside scroll function ', id)
        let element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
        }
    }

    const _validateMfMinAmountOnBlur = (type) => {
        if (mfDetail !== null) {
            console.log('transaction type', type);
            if ((type === 1 || type === 8) && amount < mfDetail.min_lumpsum_investment) {
                setAmountError(`Min lumpsum amount is ${mfDetail.min_lumpsum_investment}`);
                _scrollToElement('mf-amount-input');
            }
            if ((type === 2 || type === 9) && amount < mfDetail.min_sip_investment) {
                setAmountError(`Min sip amount is ${mfDetail.min_sip_investment}`);
                _scrollToElement('mf-amount-input');
            }

        }
    }

    const _validateGoalMinAmountOnBlur = (type) => {
        if (Object.keys(goalDetail).length !== 0) {
            console.log('type', type);
            if (type === 1 && goalLumpsum < goalDetail.min_lumpsum_investment) {
                setGoalLumpsumError(`Min lumpsum amount is ${goalDetail.min_lumpsum_investment}`);
                _scrollToElement('goal-lumpsum-amount');
            }
            if (amount < goalDetail.min_sip_investment) {
                setAmountError(`Min sip amount is ${goalDetail.min_sip_investment}`);
                _scrollToElement('mf-amount-input');
            }
        }
    }

    // goal validation
    const _validateGoalForm = () => {
        let valid = true;
        if (selectedGoalTransactionType !== 3) {
            if (Object.keys(selectedGoal).length === 0) {
                setSelectedGoalError("Select goal");
                valid = false;
                _scrollToElement('goal-dropdown');
            }
            if (Object.keys(selectedGoal).length !== 0) {
                console.log("Third in")
                if (selectedGoalTransactionType === 1) {
                    if (goalLumpsum === 0) {
                        setGoalLumpsumError("Enter lumpsum amount");
                        valid = false;
                        _scrollToElement('goal-lumpsum-amount');
                    }
                }
                if (amountError !== '') {
                    valid = false;
                    _scrollToElement('mf-amount-input');
                }
                if (amount === 0) {
                    setAmountError("Enter SIP amount");
                    valid = false;
                    _scrollToElement('mf-amount-input');
                }
                if (Object.keys(selectedGoalBank).length === 0) {
                    valid = false;
                    setGoalBankError("Select bank");
                    _scrollToElement('goal-bank');
                }
                if (Object.keys(selectedMandate).length === 0 && amount !== 0) {
                    valid = false;
                    setMandateError('Mandate is required');
                    _scrollToElement('goal-mandate')
                }
                if (Object.keys(selectedInvestmentStyle).length === 0) {
                    setInvestmentStyleError("Select investment style");
                    valid = false;
                    _scrollToElement('goal-transaction');
                }
            }
        }
        else {
            // if (Object.keys(selectedRedeemBank).length === 0) {
            //     setSelectedRedeemBankError("Select bank");
            //     _scrollToElement('goal-bank-dropdown');
            //     valid = false;
            // }
            if (Object.keys(selectedRedeemGoal).length === 0) {
                setSelectedRedeemGoalError("Select goal");
                _scrollToElement('goal-redeem-dropdown');
                valid = false;
            }
            if (goalRedeemAmount === 0) {
                setGoalRedeemAmountError("Enter redeem amount");
                _scrollToElement('goal-redeem-amount')
                valid = false;
            }
        }
        if (valid === true) {
            _createOrder();
        }
    }

    const _validateMFForm = () => {
        let valid_form = true;
        if (selectedTransactionType !== 3) {
            if (selectedUserId === null) {
                valid_form = false;
                setUserError('Customer is required');
                _scrollToElement('user-input');
            }
            if (Object.keys(investmentType).length === 0) {
                valid_form = false;
                setInvestmentTypeError('Investment type is required');
                _scrollToElement('investment-type-dropdown');
            }
            if (selectedTransactionType === '') {
                valid_form = false;
                setTransactionTypeError('Investment type is required');
                _scrollToElement(selectedTransactionType);
            }

            if (selectedTransactionType == 4) {
                const updated_cart_data = cartData;
                if (updated_cart_data.length == 0) {
                    valid_form = false;
                    setFundNameError('Fund is required');
                    _scrollToElement('fund-search');
                }

                for (let cart_index = 0; cart_index <= updated_cart_data.length - 1; cart_index++) {
                    if (updated_cart_data[cart_index].amount_error !== '') {
                        _scrollToElement(`cart-amount-${cart_index}`);
                        valid_form = false;
                    }
                    if (updated_cart_data[cart_index].amount == 0) {
                        valid_form = false;
                        _scrollToElement(`cart-amount-${cart_index}`);
                        updated_cart_data[cart_index].amount_error = 'Amount must be grater than 0';
                    }
                    if (updated_cart_data[cart_index].amount === '') {
                        _scrollToElement(`cart-amount-${cart_index}`);
                        valid_form = false;
                        updated_cart_data[cart_index].amount_error = 'Amount is required';
                    }
                }

                setCartData([...updated_cart_data]);
            } else {
                if (selectedFundId === '') {
                    valid_form = false;
                    setFundNameError('Fund is required');
                    _scrollToElement('fund-search');
                }
                if (amount === 0) {
                    valid_form = false;
                    setAmountError('Amount is required');
                    _scrollToElement('fund-search');
                }
                if (amountError !== '') {
                    valid_form = false;
                }
                if (Object.keys(selectedFolio).length === 0 && isDematUser !== true) {
                    valid_form = false;
                    setFolioError('Folio is required');
                }
            }

            if (selectedBank == null) {
                setBankError('Bank is required');
                valid_form = false;
            }
            if (selectedTransactionType === 2 || selectedTransactionType === 9) {
                if (Object.keys(selectedMandate).length === 0) {
                    valid_form = false;
                    setMandateError('Mandate is required');
                }

            }
        }
        else {
            // if (Object.keys(selectedRedeemBank).length === 0) {
            //     setSelectedRedeemBankError("Select bank");
            //     valid_form = false;
            // }
            if (Object.keys(selectedRedeemGoal).length === 0) {
                setSelectedRedeemGoalError("Select goal");
                valid_form = false;
            }
            if (mfRedeemUnits < selectedRedeemGoal.min_redemption_units && goalRedeemAll === false) {
                setMfRedeemUnitsError(`Units should not be less than ${selectedRedeemGoal.min_redemption_units}`);
                valid_form = false;
            }
            if (goalRedeemAmount === 0) {
                setGoalRedeemAmountError("Enter redeem amount");
                valid_form = false;
            } else if (goalRedeemAmount < selectedRedeemGoal.min_redemption_amount && goalRedeemAll === false) {
                setGoalRedeemAmountError(`Amount should not be less than ₹ ${selectedRedeemGoal.min_redemption_amount}`);
                valid_form = false;
            }

            if (Object.keys(selectedRedeemFolio).length === 0 && isDematUser !== true) {
                if (goalRedeemAll === false) {
                    setSelectedRedeemFolioError("Select folio");
                    valid_form = false;
                }
            }
            if (mfRedeemUnits > selectedRedeemGoal.total_units) {
                setMfRedeemUnitsError("Units should not exceed the total units.")
            }
        }
        //eslint-disable-next-line
        if (valid_form === true) {
            _createOrder()
        }
    }

    // validation for mandate creation
    function _validateMandateForm() {
        let valid = true;
        if (selectedUserId === null) {
            valid = false;
            setUserError('Client name is required');
        }
        if (investmentType.value === 1 && Object.keys(selectedGoalBank).length === 0) {
            valid = false;
            setGoalBankError('Bank is required');
        }
        if (investmentType.value === 2 && selectedBank === null) {
            valid = false;
            setBankError('Bank is required');
        }
        console.log('valid', valid);
        console.log('selectedBank', selectedBank);
        if (valid === true) {
            if (mandateType === 1) {
                _createMandate();
            } else {
                _createPhysicalMandate();
            }
        }
    }

    // API - get config data 
    function _getConfigData() {
        let url = 'order-execution/get-config-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                if (response.data.investment_type.length !== 0) {
                    let investment_type = response.data.investment_type;
                    investment_type.forEach(item => {
                        item.value = item.id;
                        delete item.id;
                    });

                    // following block will create admin configured goal details for 'Goal investment'
                    // user param 'value' for search since we replacing 'id' with 'value'
                    let cart_index = response.data.investment_type.findIndex((item) => item.value == 1); // get index of goal config
                    if (cart_index != -1) {
                        let goal = _formatArray(investment_type[0].data, { 'name': 'label' });
                        setGoals(goal);
                        if (location.state !== null) {
                            let selected_goal = goal.filter(value => value.goal_type_id === location.state.goal_id);
                            setSelectedGoal(selected_goal[0]);
                        }
                    }

                    setConfigData(response.data);
                    setInvestmentTypeOptions(investment_type);
                    if (location.state !== null) {
                        setInvestmentType(investment_type[0]);
                        _setInvestmentType(investment_type[0])
                    }
                    // setInvestmentRadioOptions(investment_type[0].transaction_type);
                }
            }
            setPageLoader(false);
        })
    };

    // API - user search 
    async function _userSearch(value) {
        let url = 'user/search';
        let payload = JSON.stringify({
            query: value
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setUserSearchResult(response.data);
                setShowUserDropDown(true);
            } else {
                setUserSearchResult([]);
            }
        })
    };

    // API - Search Amc fund
    function _getAmcFund(query) {
        let url = 'order-execution/search-mf';
        let payload = JSON.stringify({
            mf_id: selectedMtTransactionFund.value,
            query,
            transaction_type: selectedTransactionType
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (response.data.length !== 0) {
                    setShowAmcFundDropdown(true);
                    setAmcSearchResult(response.data);
                }

            } else {
                setAmcSearchResult([]);
            }
        })
    }

    // API -  search fund detail
    function _getFundName(value) {
        let url = 'fund/search';
        let payload = JSON.stringify({
            query: value,
            page_num: 1,
            investment_type: selectedTransactionType < 3 || selectedTransactionType > 7 ? selectedTransactionType : null
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (response.data.length !== 0) {
                    setShowFundDropDown(true);
                    setFundSearchResult(response.data);
                }

            } else {
                setFundSearchResult([]);
            }
        })
    }

    // API - get redeem details for mf investment
    const _getMFRedeemData = () => {
        let url = 'order-execution/mf-redeem-data';
        let fund_id = selectedTransactionType === 3 ? Object.keys(selectedRedeemGoal).length === 0 ? 0 : selectedRedeemGoal.value : Object.keys(selectedMtTransactionFund).length === 0 ? 0 : selectedMtTransactionFund.value;
        let payload = JSON.stringify({
            id: fund_id,
            customer_id: selectedUserId,
            sip_date: sipDate,
            transaction_type: selectedTransactionType,
            generate_today: firstInvestment === 1 ? true : false
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                const modified_data = {
                    "banks": _convertKeys(response.data.banks),
                    "mf_data": _convertKeys(response.data.mf_data),
                    "allowed_dates": selectedTransactionType === 6 ? response.data.swp_dates : response.data.stp_dates
                };

                console.log("MOdified data", modified_data);
                if (modified_data.banks.length) {
                    setSelectedRedeemBank(modified_data.banks[0]);
                    setSelectedFundBank(modified_data.banks[0]);
                }

                setGoalRedeemData(modified_data);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
        })
    }

    // API - get mf detail of selected fund
    function _getMfDetail(id, data = null) {
        let url = 'order-execution/mf-invest-data';
        let payload = JSON.stringify({
            id,
            customer_id: selectedUserId,
            generate_today: firstInvestment === 1 ? true : false,
            // sip_date: selectedTransactionType === 2 ? sipDate : null,
            sip_date: sipDate,
            mandate_id: null
        });

        APIService(true, url, payload).then((response) => {

            if (response.status_code === 200) {
                let mf_data = response.data;
                // Appending fund data and cart data with response and push state
                if (data !== null) {
                    mf_data['mf_data'] = data;
                    mf_data['cart_data'] = [];
                    if ((selectedTransactionType === 2 || selectedTransactionType === 9) && mf_data.min_sip_investment === 0) {
                        toast.dismiss();
                        toast.error("SIP is disabled for this fund", {
                            type: 'error'
                        });
                    }
                    if (selectedTransactionType === 4) {
                        let existing_cart = cartData;
                        let selected_folio = null
                        if (mf_data.folios.length != 0) {
                            selected_folio = mf_data.folios[0];
                        } else {
                            selected_folio = FOLIO_OPTION;
                        }

                        let folios = _formatArray(mf_data.folios, { 'folio_number': 'label', 'id': 'value' });
                        let cart_item = {
                            'mf_id': data.id,
                            'name': data.name,
                            'image': data.image,
                            'nav': data.nav,
                            'min_sip_investment': mf_data.min_sip_investment, // update min SIP value from invest data API for cart
                            'min_lumpsum_investment': mf_data.min_lumpsum_investment, // update min lumpsum value from invest data API for cart
                            'folios': [FOLIO_OPTION, ...folios],
                            'selected_folio': selected_folio,
                            'folio_error': '',
                            'amount': 0,
                            'amount_error': '',
                        };
                        existing_cart.push(cart_item);
                        setCartData([...existing_cart]);
                        setSelectedFund(""); // clear search input only for cart search
                    } else {

                        if (mf_data.folios.length != 0) {
                            let folios = _formatArray(mf_data.folios, { 'folio_number': 'label', 'id': 'value' });
                            setMfDetailFolioOptions([FOLIO_OPTION, ...folios]);
                            setSelectedFolio(mf_data.folios[0]);
                        } else {
                            setMfDetailFolioOptions([FOLIO_OPTION]);
                            setSelectedFolio({});
                        }
                    }
                }

                setMfDetail(mf_data);
                if (mfEnabledDate.length === 0) {
                    setMfEnabledDate(mf_data.sip_dates);
                }

                // process bank options
                if (mf_data.banks.length != 0) {
                    let banks = mf_data.banks;
                    banks = banks.map((item) => {
                        return { value: item.bank_id, label: item.name + '(' + item.account_number + ')', mandate: item.mandates, account_number: item.account_number }
                    });
                    if (selectedBank === null) {
                        _selectBank(banks[0]);
                    }
                    setMfDetailBankOptions(banks);
                }

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
        })
    }

    // API - create order
    function _createOrder() {
        setButtonLoader(true);
        let url = "order-execution/create-order";
        let payload = {};
        if (investmentType.value == 2) {
            payload = _getCreateOrderPayload();
        }
        else {
            payload = _getCreateGoalPayload();
        }
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setPaymentUrl(response.data.payment_url);
                setShowSuccessModal(true);
                setIsPendingOrder(false);
                _clearForm();
                window.scroll({
                    top: 0,
                    behavior: 'smooth'
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setButtonLoader(false);
        })
    }

    // API - get redeem details for mf investment
    const _orderHistory = (page_num = pageNum, page_size = pageSize, query = searchQuery, start_date = startDate, end_date = endDate, order_type = filterType, payment_status = paymentStatus, order_status = orderStatus) => {

        let url = 'order-execution/order-history';
        let payload = JSON.stringify({
            "query": query,
            "order_status": order_status[0] === null ? [] : order_status,
            "payment_status": payment_status[0] === null ? [] : payment_status,
            "order_type": order_type[0] === null ? [] : order_type,
            "page_size": page_size,
            "page_num": page_num,
            "from_date": start_date === "Start date" ? null : start_date,
            "to_date": end_date === "End date" ? null : end_date,
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOrderHistory(response.data);
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setPageSize(response.data.page_size);
            } else {
                setOrderHistory([]);
            }
            setOrderHistoryLoader(false);
        })
    }

    // API - get goal invest data
    const _getGoalInvestData = () => {
        let url = "order-execution/goal-invest-data";
        let payload = JSON.stringify({
            id: selectedGoal.goal_type_id,
            customer_id: selectedUserId,
            generate_today: firstInvestment === 1 ? true : false,
            sip_date: sipDate.length !== 0 ? sipDate : null,
            mandate_id: Object.keys(selectedMandate).length !== 0 ? selectedMandate.value : null,
            investment_style_id: Object.keys(selectedInvestmentStyle).length !== 0 ? selectedInvestmentStyle.value : null
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                const modified_bank = response.data.banks.map(({ bank_id, name, ...rest }) => ({
                    value: bank_id,
                    label: name,
                    ...rest
                }));
                const investment_style = response.data.goal_data.investment_style.map(({ id, name, ...rest }) => ({
                    value: id,
                    label: name,
                    ...rest
                }))
                setGoalDetailBankOptions(modified_bank);
                setGoalDetail(response.data);
                setInvestmentStyleOptions(investment_style);
                // setSelectedInvestmentStyle({});
                // setSelectedGoalBank({});
                setMandateOptions([MANDATE_OPTION]);
                // setSelectedMandate({});
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }

        })
    }

    // API - Get order filter 
    const _getOrderFilterData = () => {
        let url = 'order/filter-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setFilterData(response.data);
                if (Object.keys(SELECTED_FILTER).length === 0) {
                    setInvestmentAmount([response.data.invested_amount[0].id])
                    setFilterType([response.data.filter_data[0].id]);
                    setPaymentStatus([response.data.payment_status[0].id]);
                    setOrderStatus([response.data.order_status[0].id]);
                    setInvestedIn([response.data.invested_in[0].id]);
                }
                setPageLoader(false);
            }
        });
    }

    // API - get goal redeem data
    function _getGoalRedeemData() {
        let url = "order-execution/goal-redeem-data";
        let payload = JSON.stringify({
            customer_id: selectedUserId,
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                const modified_data = {
                    "banks": _convertKeys(response.data.banks),
                    "goal_data": _convertKeys(response.data.goal_data),

                };
                console.log("MOdified data", modified_data);
                if (modified_data.banks.length) {
                    setSelectedRedeemBank(modified_data.banks[0]);
                    setSelectedFundBank(modified_data.banks[0]);
                }

                setGoalRedeemData(modified_data);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
        })
    }

    function _fileDownloader(url, file_name) {
        const link = document.createElement('a');
        link.href = url;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // API - create mandate 
    function _createMandate() {
        setButtonLoader(true);
        const parts = expiryDate.split('-');
        if (mandateLimit === '') {
            setMandateLimitError('Mandate limit is required');
            return;
        }

        const formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];
        let url = 'mandates/create';
        let payload = JSON.stringify({
            customer_id: selectedUserId,
            bank_id: investmentType.value === 1 ? selectedGoalBank.value : selectedBank.value,
            end_date: formatted_date,
            limit: mandateLimit,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success("Mandate created and sent to the user.", {
                    type: 'success'
                });
                _clearForm();
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setButtonLoader(false);
        });
    }

    // API - create physical mandate
    function _createPhysicalMandate() {
        setButtonLoader(true);
        function _formatDate(date) {
            const parts = date.split('-');
            const formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];
            return formatted_date;
        }
        let formatted_start_date = '';
        const formatted_end_date = _formatDate(expiryDate);
        if (mandateStartDate) {
            formatted_start_date = _formatDate(mandateStartDate);
        }
        if (mandateLimit === '') {
            setMandateLimitError('Mandate limit is required');
            return;
        }

        let url = 'v2/mandates/create';
        let payload = JSON.stringify({
            customer_id: selectedUserId,
            bank_id: investmentType.value === 1 ? selectedGoalBank.value : selectedBank.value,
            start_date: formatted_start_date,
            end_date: formatted_end_date,
            limit: mandateLimit,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                _fileDownloader(response.data.url, 'Mandate form');
                toast.success("Physical Mandate created and sent to the user.", {
                    type: 'success'
                });
                _clearForm();
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
        });
    }

    useEffect(() => {
        let count = 0;
        if (filterType.length !== 0 && !filterType.includes(null)) {
            count++;
        }
        if (paymentStatus.length !== 0 && !paymentStatus.includes(null)) {
            count++;
        }
        if (orderStatus.length !== 0 && !orderStatus.includes(null)) {
            count++;
        }

        setFilterCount(count);

    }, [filterType, paymentStatus, orderStatus]);


    return (
        <Fragment>
            <NavigationHeader type={1}
                title="Order execution" />

            <div className="ps-4 pe-lg-5 pe-md-2 pe-2">
                <div className="row padding-32px-bottom">
                    {/* tab */}
                    <div className="col-lg-4 col-md-12">
                        <CustomTab data={TAB_DATA}
                            type={2}
                            index={tabIndex}
                            onSelectTab={_handleTabIndex} />
                    </div>

                    {tabIndex === 2 && <div className="col-lg-8 col-md-12">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-1  d-flex gap-8px pe-2">
                                <SearchInput placeholder="Search with id, mobile , email & PAN"
                                    value={searchQuery}
                                    valueChange={_handleSearchQuery}
                                    name='Search'
                                    handleSearch={() => {
                                        setPageNum(1);
                                        _orderHistory(1, pageSize, searchQuery, startDate, endDate, filterType, paymentStatus, orderStatus)
                                    }} />


                            </div>
                            <div className="col-lg-5 d-flex gap-8px  px-0 position-relative">
                                <CustomDateRangePicker
                                    startDate={displayStartDate}
                                    endDate={displayEndDate}
                                    popupRef={popupRef}
                                    className='w-100'
                                    showDateFilter={showDateFilter}
                                    handleShowDateFilter={_handleShowDateFilter}
                                    selectedRange={selectedRange}
                                    handleDateRangeChange={_handleDateRangeChange}
                                    clearDates={_handleClearDates} />
                                <div className="position-relative" >
                                    {
                                        filterCount > 0 &&
                                        <span className="color-white border-radius-100px e-poppins-bold e-font-12 line-height-12px e-filter-badge position-absolute bg-sea-green d-flex justify-content-center align-items-center">{filterCount}</span>
                                    }
                                    <div className="border-radius-6px p-2 border-bright-gray border-all bg-white  d-flex align-items-center z-index-10"
                                        onClick={_handleToggleFilter}>
                                        <p className="color-charleston-green e-poppins-medium e-font-14 line-height-24px mb-0 pe-1 cursor-pointer mt-0">Filter</p>
                                        <img draggable={false} src={require('../../Assets/Images/Users/filter.png')}
                                            alt="Filter"
                                            width={24}
                                            height={24}
                                            className="cursor-pointer me-2" />
                                    </div>
                                    {
                                        showFilter === true &&
                                        <Fragment>
                                            <div className="position-relative" ref={filterRef}>
                                                <KYCPopup type={1}
                                                    // investmentAmount={investmentAmount}
                                                    filterType={filterType}
                                                    paymentStatus={paymentStatus}
                                                    orderStatus={orderStatus}
                                                    // investedIn={investedIn}
                                                    data={filterData}
                                                    applyFilter={_handleApplyFilter}
                                                    close={() => setShowFilter(false)} />
                                            </div>
                                            {/* <div className="position-fixed w-100 z-index-8 h-100 left-0 top-20px cursor-default"
                                            onClick={() => {
                                                console.log("is this gettgin run")
                                                setShowFilter(false)
                                            }}></div> */}
                                        </Fragment>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>}

                    {/* MF Investment */}
                    {
                        tabIndex === 1 ?
                            <div className=" padding-24px-top">

                                {/* search customer */}
                                <div className="col-6" ref={userSearchRef}>
                                    <SearchSelect value={selectedUser}
                                        placeholder="Search with Client name, ID, PAN, Phone"
                                        data={userSearchResult}
                                        activeId={selectedUserId}
                                        type="text"
                                        id='user-input'
                                        name="search"
                                        error={userError}
                                        showDropdown={showUserDropDown}
                                        customerList={true} // param to show mt_id 
                                        // onClick={() => _handleUserSearchClick(1)}
                                        select={(dropdown, id, label, data) => _handleSelectUserSearch(dropdown, id, label, data)}
                                        valueChange={(value) => _handleInput(value, 1)} />
                                </div>

                                {/* select investment types */}
                                <div data-disabled={selectedUserId === null ? true : false}>
                                    <div className="col-lg-6 pt-4 mt-2">
                                        <CustomSearchSelectBox options={investmentTypeOptions}
                                            value={Object.keys(investmentType).length !== 0 ? investmentType : ""}
                                            label="Select investment type"
                                            id="investment-type-dropdown"
                                            error={investmentTypeError}
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={(investment_style) => {
                                                _setInvestmentType(investment_style)
                                            }} />
                                    </div>

                                    {
                                        investmentType.value == 2 ?
                                            <>
                                                {/* show available transaction types */}
                                                {
                                                    transactionStatement.length === 0 ?
                                                        Object.keys(investmentType).length !== 0 && (
                                                            <div className="col-lg-12 padding-24px-top">
                                                                <label className="e-poppins-medium e-font-16 line-height-16px color-light-black mb-2">Select Investment type</label>
                                                                <RadioGroup data={investmentRadioOptions}
                                                                    type={1}
                                                                    id={selectedTransactionType}
                                                                    wrapperClass='w-auto'
                                                                    radioHandler={(id) => {
                                                                        _selectMFTransactionType(id)
                                                                    }} />
                                                            </div>
                                                        )
                                                        :
                                                        <p className="e-poppins-regular color-black e-font-16 line-height-16px mb-0 mt-4">{transactionStatement}</p>
                                                }

                                                {
                                                    selectedTransactionType === 3 ? // mf redeem
                                                        <Fragment>
                                                            <div className="row">
                                                                <div className="col-6 pt-4 pe-0">
                                                                    <CustomSearchSelectBox options={goalRedeemData.mf_data}
                                                                        value={Object.keys(selectedRedeemGoal).length === 0 ? "" : selectedRedeemGoal}
                                                                        label="Select redeem fund"
                                                                        error={selectedRedeemGoalError}
                                                                        hide_error_text={false}
                                                                        id='goal-redeem-dropdown'
                                                                        placeholder=""
                                                                        postfix="*"
                                                                        onSelectChange={(goal_redeem) => {
                                                                            _selectGoalRedeem(goal_redeem)
                                                                        }} />
                                                                </div>
                                                                {
                                                                    Object.keys(selectedRedeemGoal).length !== 0 &&
                                                                    <div className="col-6 pt-4">
                                                                        {/* Current amount, invested amount and total units */}
                                                                        <div className="border-primary-color border-all bg-pale-alice-blue border-radius-8px d-flex justify-content-between padding-14px-tb px-2">

                                                                            <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                <span className="color-gray e-poppins-regular me-2">Available amount </span>
                                                                                {`₹ ${selectedRedeemGoal.current_amount.toLocaleString('en-IN')}`}
                                                                                {/* {
                                                                                    Object.keys(selectedRedeemFolio).length !== 0 ?
                                                                                        `₹ ${Math.floor(selectedRedeemGoal.nav.current_nav * selectedRedeemFolio.units)}`
                                                                                        :
                                                                                        '-'
                                                                                } */}
                                                                            </p>
                                                                            <p className="e-font-14 e-inter-medium color-black margin-block-end-0 ">
                                                                                <span className="color-gray e-poppins-regular me-2">Available units </span>
                                                                                {
                                                                                    Object.keys(selectedRedeemFolio).length !== 0 ?
                                                                                        selectedRedeemFolio.units
                                                                                        :
                                                                                        '-'
                                                                                }
                                                                            </p>
                                                                            <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                <span className="color-gray e-poppins-regular me-2">Total units</span>
                                                                                {selectedRedeemGoal.total_units.toLocaleString('en-IN')}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {/* <div className="col-6 pt-4 mt-3">
                                                                <CustomSearchSelectBox options={goalRedeemData.banks}
                                                                    value={Object.keys(selectedRedeemBank).length === 0 ? "" : selectedRedeemBank}
                                                                    label="Select bank"
                                                                    error={selectedRedeemBankError}
                                                                    hide_error_text={false}
                                                                    id="goal-bank-dropdown"
                                                                    placeholder=""
                                                                    postfix="*"
                                                                    onSelectChange={(bank_info) => {
                                                                        _selectGoalRedeemBank(bank_info)
                                                                    }} />

                                                            </div> */}
                                                            {
                                                                Object.keys(selectedRedeemGoal).length !== 0 &&
                                                                <Fragment>
                                                                    <div className="row">
                                                                        <div className="col-6 pt-4 mt-3 pe-0">
                                                                            <CustomAmountInput value={mfRedeemUnits}
                                                                                label="Add units"
                                                                                disabled={goalRedeemAll}
                                                                                showRupeePrefix={false}
                                                                                id='goal-units-input'
                                                                                // postfix="*"
                                                                                inputClass="ps-3"
                                                                                error={mfRedeemUnitsError}
                                                                                allowDecimal={true}
                                                                                handleChange={_addRedeemUnits} />
                                                                        </div>
                                                                        {
                                                                            Object.keys(selectedRedeemGoal).length !== 0 &&
                                                                            <div className="col-6 pt-4 mt-3">
                                                                                {/* Current amount, invested amount and total units */}
                                                                                <div className="border-primary-color border-all bg-pale-alice-blue border-radius-8px d-flex justify-content-between padding-14px-tb px-2">

                                                                                    <p className="e-font-14 e-inter-medium color-black margin-block-end-0 ">
                                                                                        <span className="color-gray e-poppins-regular me-2">Min. Redemption units </span>
                                                                                        {`${selectedRedeemGoal.min_redemption_units.toLocaleString('en-IN')}`}
                                                                                    </p>
                                                                                    <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                        <span className="color-gray e-poppins-regular me-2">Redemption multiple units</span>
                                                                                        {selectedRedeemGoal.redemption_multiple_units.toLocaleString('en-IN')}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-6 pt-4 mt-3 pe-0">
                                                                            <CustomAmountInput value={goalRedeemAmount}
                                                                                label="Add amount"
                                                                                showRupeePrefix={true}
                                                                                disabled={goalRedeemAll}
                                                                                id='goal-amount-input'
                                                                                postfix="*"
                                                                                error={goalRedeemAmountError}
                                                                                handleChange={_addRedeemAmount} />
                                                                        </div>
                                                                        {
                                                                            Object.keys(selectedRedeemGoal).length !== 0 &&
                                                                            <div className="col-6 pt-4 mt-3">
                                                                                {/* Current amount, invested amount and total units */}
                                                                                <div className="border-primary-color border-all bg-pale-alice-blue border-radius-8px d-flex justify-content-between padding-14px-tb px-2">
                                                                                    <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                        <span className="color-gray e-poppins-regular me-2">Min. Redemption amount </span>
                                                                                        {`₹ ${selectedRedeemGoal.min_redemption_amount.toLocaleString('en-IN')}`}
                                                                                    </p>
                                                                                    <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                        <span className="color-gray e-poppins-regular me-2">Redemption multiple amount</span>
                                                                                        {`₹ ${selectedRedeemGoal.redemption_multiple_amount.toLocaleString('en-IN')}`}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        isDematUser !== true &&
                                                                        <div className="col-6 pt-4 mt-3">
                                                                            <CustomSearchSelectBox options={mfRedeemFolioOptions}
                                                                                value={Object.keys(selectedRedeemFolio).length === 0 ? "" : selectedRedeemFolio}
                                                                                label="Select folio"
                                                                                isDisabled={goalRedeemAll}
                                                                                error={selectedRedeemFolioError}
                                                                                id='mf-folio-dropdown'
                                                                                hide_error_text={false}
                                                                                placeholder=""
                                                                                postfix="*"
                                                                                onSelectChange={(folio_info) => {
                                                                                    _selectMFRedeemFolio(folio_info)
                                                                                }} />

                                                                        </div>
                                                                    }

                                                                    <div className="col-6 mt-4 pt-2">

                                                                        <div className={`d-flex align-items-center gap-8px`}
                                                                            onClick={_handleRedeemAll}>
                                                                            {console.log('goalRedeemAll', goalRedeemAll)}
                                                                            <Icon icon={goalRedeemAll ? 'checked' : 'unchecked'}
                                                                                size={24}
                                                                                className="cursor-pointer"
                                                                            />
                                                                            <span className="color-black e-poppins-regular e-font-14 line-height-24px cursor-pointer ">
                                                                                Redeem all
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </Fragment>
                                                        : // switch, swp, stp
                                                        selectedTransactionType === 5 || selectedTransactionType === 6 || selectedTransactionType === 7 ?
                                                            <Fragment>
                                                                {/* Select fund */}
                                                                <div className="row">
                                                                    <div className="col-6 pt-4 ">
                                                                        <CustomSearchSelectBox options={goalRedeemData.mf_data}
                                                                            value={Object.keys(selectedMtTransactionFund).length === 0 ? "" : selectedMtTransactionFund}
                                                                            label="Select fund"
                                                                            error={mtTransactionFundError}
                                                                            hide_error_text={false}
                                                                            id='goal-redeem-dropdown'
                                                                            placeholder=""
                                                                            postfix="*"
                                                                            onSelectChange={(goal_redeem) => {
                                                                                _selectFundMtTransaction(goal_redeem)
                                                                            }} />
                                                                    </div>
                                                                    {
                                                                        Object.keys(selectedMtTransactionFund).length !== 0 &&
                                                                        <div className="col-6 pt-4">
                                                                            {/* Current amount, invested amount and total units */}
                                                                            <div className="border-primary-color border-all bg-pale-alice-blue border-radius-8px d-flex justify-content-between padding-14px-tb px-2">
                                                                                <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                    <span className="color-gray e-poppins-regular me-2">Available amount </span>
                                                                                    {`₹ ${selectedMtTransactionFund.current_amount.toLocaleString('en-IN')}`}
                                                                                    {/* {
                                                                                        Object.keys(selectedFundFolio).length !== 0 ?
                                                                                            `₹ ${Math.floor(selectedMtTransactionFund.nav.current_nav * selectedFundFolio.unit)}`
                                                                                            :
                                                                                            '-'
                                                                                    } */}
                                                                                </p>
                                                                                <p className="e-font-14 e-inter-medium color-black margin-block-end-0 ">
                                                                                    <span className="color-gray e-poppins-regular me-2">Available units </span>
                                                                                    {
                                                                                        Object.keys(selectedFundFolio).length !== 0 ?
                                                                                            selectedFundFolio.unit
                                                                                            :
                                                                                            '-'
                                                                                    }
                                                                                </p>
                                                                                {
                                                                                    selectedTransactionType === 5 ?
                                                                                        <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                            <span className="color-gray e-poppins-regular me-2">Min switch out amount </span>
                                                                                            {selectedMtTransactionFund.min_switch_out_amount ? `₹ ${selectedMtTransactionFund.min_switch_out_amount.toLocaleString('en-IN')}` : '-'}
                                                                                        </p>
                                                                                        :
                                                                                        selectedTransactionType === 7 ?
                                                                                            <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                                <span className="color-gray e-poppins-regular me-2">Min STP out amount</span>
                                                                                                {selectedMtTransactionFund.stp_out_minimum_installment_amount ? `₹ ${selectedMtTransactionFund.stp_out_minimum_installment_amount.toLocaleString('en-IN')}` : '-'}
                                                                                            </p>
                                                                                            :
                                                                                            <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                                <span className="color-gray e-poppins-regular me-2">Exit load</span>
                                                                                                ₹ 0.00
                                                                                            </p>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {
                                                                    Object.keys(selectedMtTransactionFund).length !== 0 &&
                                                                    <>
                                                                        {
                                                                            selectedTransactionType !== 6 &&
                                                                            <div className="row">
                                                                                <div className="col-6 pt-2 mt-3" ref={fundSearchRef}>
                                                                                    <h6 className="color-eerie-black e-poppins-mediam e-font-16">
                                                                                        {selectedTransactionType === 7 ? "Transfer to" : "Switch to"}
                                                                                    </h6>
                                                                                    <SearchSelect value={selectedAmcFund}
                                                                                        placeholder="Search fund and add"
                                                                                        id="fund-search"
                                                                                        data={amcSearchResult}
                                                                                        activeId={selectedAmcFundId}
                                                                                        type="text"
                                                                                        error={selectedAmcFundError}
                                                                                        showDropdown={showAmcFundDropdown}
                                                                                        className="e-search-fund-font-14 color-black"
                                                                                        // onClick={() => _handleUserSearchClick(2)}
                                                                                        select={_handleAmcSearchSelect}
                                                                                        valueChange={(query) => _searchAmcFund(query)} />
                                                                                </div>
                                                                                <div className="col-12 mt-4">
                                                                                    <h6 className="color-eerie-black e-poppins-mediam e-font-16">
                                                                                        Exit load ₹ 0.00
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        {
                                                                            selectedTransactionType > 5 &&
                                                                            <div className="col-6 pt-3 my-3">
                                                                                <label className="pb-3 color-light-black e-font-16 line-height-16px e-poppins-medium">Generate first installment now</label>
                                                                                <RadioGroup data={FIRST_INSTALLMENT}
                                                                                    radioItemClassName="py-2"
                                                                                    size={14}
                                                                                    fontSize="e-font-12"
                                                                                    type={2}
                                                                                    id={firstInvestment}
                                                                                    radioHandler={_handleRadioInput} />
                                                                            </div>
                                                                        }


                                                                        <div className="row">
                                                                            <div className="col-6 pt-4 mt-3">
                                                                                <CustomSearchSelectBox options={mfRedeemFolioOptions}
                                                                                    value={Object.keys(selectedFundFolio).length === 0 ? "" : selectedFundFolio}
                                                                                    label="Select folio"
                                                                                    error={selectedFundBankError}
                                                                                    hide_error_text={false}
                                                                                    id="selected-fund-folio-dropdown"
                                                                                    placeholder=""
                                                                                    postfix="*"
                                                                                    onSelectChange={(folio) => {
                                                                                        _selectMtTransactionFolio(folio)
                                                                                    }} />

                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            selectedTransactionType === 5 &&
                                                                            <div className="row">
                                                                                <div className="col-6 pt-3 mt-3">
                                                                                    <label className="pb-3 color-light-black e-font-16 line-height-16px e-poppins-medium">Switch all</label>
                                                                                    <RadioGroup data={FIRST_INSTALLMENT}
                                                                                        radioItemClassName="py-2"
                                                                                        size={14}
                                                                                        fontSize="e-font-12"
                                                                                        type={2}
                                                                                        id={switchAll}
                                                                                        radioHandler={_handleSwitchAll} />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <div className="row">
                                                                            <div className="col-6 pt-4 mt-3 ">
                                                                                <CustomAmountInput value={transactionFundAmount}
                                                                                    label={selectedTransactionType === 5 ? "Switch amount" : selectedTransactionType === 6 ? "SWP amount" : "STP amount"}
                                                                                    showRupeePrefix={true}
                                                                                    disabled={switchAll === 1 && selectedTransactionType === 5 ? true : false}
                                                                                    id='selected-fund-amount-input'
                                                                                    postfix="*"
                                                                                    description={
                                                                                        selectedTransactionType === 5 ?
                                                                                            `Min switch in amount: ${selectedAmcFund && selectedAmcFund.min_switch_in_amount ? selectedAmcFund.min_switch_in_amount : '-'}, Min Switch Units: ${selectedMtTransactionFund.min_redemption_units ? selectedMtTransactionFund.min_redemption_units : '0'}`
                                                                                            :
                                                                                            selectedTransactionType === 6 ?
                                                                                                `Min Redemption out amount: ${selectedMtTransactionFund && selectedMtTransactionFund.swp_minimum_installment_amount ? selectedMtTransactionFund.swp_minimum_installment_amount : '-'}, Min SWP Units: ${selectedMtTransactionFund.min_redemption_units ? selectedMtTransactionFund.min_redemption_units : '0'}`
                                                                                                :
                                                                                                `Min STP in amount: ${selectedAmcFund && selectedAmcFund.stp_in_minimum_installment_amount ? selectedAmcFund.stp_in_minimum_installment_amount : '-'}, Min STP Units: ${selectedMtTransactionFund.min_redemption_units ? selectedMtTransactionFund.min_redemption_units : '0'}`
                                                                                    }
                                                                                    error={transactionFundAmountError}
                                                                                    handleChange={_addFundAmount} />
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            selectedTransactionType !== 5 &&
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-6 pt-4 mt-4 ">
                                                                                        <SIPDate labelClass="e-font-16 line-height-26px"
                                                                                            label={selectedTransactionType === 7 ? "STP date" : "SWP date"}
                                                                                            date={sipDate}
                                                                                            className="cursor-pointer"
                                                                                            inputClass="cursor-pointer"
                                                                                            type={1}
                                                                                            prefix={true}
                                                                                            dateText="Start from"
                                                                                            monthString={monthString}
                                                                                            enabledDates={goalRedeemData.allowed_dates}
                                                                                            dateSelected={_handleDateSelected} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-6 pt-4 mt-3 ">
                                                                                        <div className="position-relative">
                                                                                            <CustomAmountInput value={installment}
                                                                                                label="Installments"
                                                                                                showRupeePrefix={false}
                                                                                                id='installment-input'
                                                                                                postfix="*"
                                                                                                description={
                                                                                                    selectedTransactionType === 6 ?
                                                                                                        `Min no of installments: ${selectedMtTransactionFund && selectedMtTransactionFund.swp_minimum_installment_numbers ? selectedMtTransactionFund.swp_minimum_installment_numbers : '0'}`
                                                                                                        :
                                                                                                        `Min no of installments: ${selectedAmcFund && selectedAmcFund.stp_in_minimum_installment_amount_numbers ? selectedAmcFund.stp_in_minimum_installment_amount_numbers : '0'}`
                                                                                                }
                                                                                                error={installmentError}
                                                                                                handleChange={_handleInstallment} />
                                                                                            <span className="color-steel-gray e-poppins-regular e-font-10 position-absolute right-10px top-0 bottom-0 margin-auto h-fit-content">Installments</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }


                                                            </Fragment>
                                                            :
                                                            //mf - sip, lumpsum && cart
                                                            <Fragment>
                                                                <div className="col-lg-12 pt-4"
                                                                    data-disabled={selectedTransactionType === '' ? true : false}>
                                                                    <label className="e-poppins-medium e-font-16 line-height-16px color-light-black mb-4">Other details</label>
                                                                    <div className="row gx-0">
                                                                        <div className="col-6 mb-4" ref={fundSearchRef}>
                                                                            <SearchSelect value={selectedFund}
                                                                                placeholder="Search fund and add"
                                                                                id="fund-search"
                                                                                data={fundSearchResult}
                                                                                activeId={selectedFundId}
                                                                                type="text"
                                                                                error={fundNameError}
                                                                                showDropdown={showFundDropDown}
                                                                                // onClick={() => _handleUserSearchClick(2)}
                                                                                select={_handleFundSearchSelect}
                                                                                valueChange={(query) => _searchFund(query)} />
                                                                        </div>

                                                                        {/* show the section only if transaction types are SIP or Lumpsum and has a fund selected*/}
                                                                        {
                                                                            mfDetail != null && (selectedTransactionType === 1 || selectedTransactionType === 2 || selectedTransactionType === 8 || selectedTransactionType === 9) && (
                                                                                <div className="col-4 ms-2 ps-2">
                                                                                    {/* Minimum SIP and Lumpsum */}
                                                                                    <div className="border-primary-color border-all bg-pale-alice-blue border-radius-8px d-flex justify-content-between padding-14px-tb px-2">
                                                                                        <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                            <span className="color-gray e-poppins-regular me-2">Min SIP</span>
                                                                                            {mfDetail.min_sip_investment && (`₹ ${mfDetail.min_sip_investment.toLocaleString('en-IN')}`)}
                                                                                        </p>
                                                                                        <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                            <span className="color-gray e-poppins-regular me-2">Min Lumpsum</span>
                                                                                            {mfDetail.min_lumpsum_investment && (`₹ ${mfDetail.min_lumpsum_investment.toLocaleString('en-IN')}`)}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    {
                                                                        selectedTransactionType === 4 && cartData.length !== 0 && (
                                                                            <>
                                                                                {
                                                                                    cartData.map((item, key) => {
                                                                                        return (
                                                                                            <div className={`col-9 ${key !== 0 && 'mt-4'}`}
                                                                                                key={key}>
                                                                                                <div className="border-radius-8px border-bright-gray border-all p-4">
                                                                                                    <div className="row">
                                                                                                        {/* MF image and image */}
                                                                                                        <div className="col-6">
                                                                                                            <div className="border-primary-color border-all bg-pale-alice-blue border-radius-8px d-flex align-items-center padding-8px-tb px-2">
                                                                                                                <div className="bg-white border-radius-8px me-2">
                                                                                                                    <img draggable={false} src={item.image}
                                                                                                                        alt=""
                                                                                                                        width={40}
                                                                                                                        height={40}
                                                                                                                        className="object-fit-contain" />
                                                                                                                </div>
                                                                                                                <p className="color-black e-poppins-medium e-font-14 mb-0">{item.name}</p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {/* amount to be invested in the cart fund  */}
                                                                                                        <div className="col-4">
                                                                                                            <CustomAmountInput value={item.amount}
                                                                                                                label="Amount"
                                                                                                                showRupeePrefix={true}
                                                                                                                postfix="*"
                                                                                                                id={`cart-amount-${key}`}
                                                                                                                error={item.amount_error}
                                                                                                                handleBlur={() => _handleCartAmountBlur(item, key)}
                                                                                                                handleChange={(amount) => _handleCartAmount(amount, key)} />
                                                                                                        </div>

                                                                                                        <div className="col-2 justify-content-between d-flex align-items-center">
                                                                                                            {/* approximate units */}
                                                                                                            {/* <p className="color-outer-gray e-poppins-regular e-font-16 mb-0">
                                                                                                            {_calculateUnits(item.amount, item.nav)} units
                                                                                                        </p> */}
                                                                                                            {/* remove item from cart */}
                                                                                                            <div className="e-tool-tip-container position-relative"
                                                                                                                data-tooltip-content="Delete selected"
                                                                                                                onMouseEnter={() => setDeleteIconRed(true)}
                                                                                                                onMouseLeave={() => setDeleteIconRed(false)}>
                                                                                                                <Icon icon={deleteIconRed === true ? "delete-red" : "delete"}
                                                                                                                    size={24}
                                                                                                                    onClick={() => _handleDeleteCart(item.mf_id, key)} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="row mt-4">
                                                                                                        {/* min lumpsum and sip amount */}
                                                                                                        <div className="col-3">
                                                                                                            <label className="color-gray e-poppins-regular me-2 e-font-14">Min. SIP amount</label>
                                                                                                            <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                                                {`₹${item.min_sip_investment.toLocaleString('en-IN')}`}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="col-3">
                                                                                                            <label className="color-gray e-poppins-regular me-2 e-font-14">Min. Lumpsum amount</label>
                                                                                                            <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                                                                {`₹${item.min_lumpsum_investment.toLocaleString('en-IN')}`}
                                                                                                            </p>
                                                                                                        </div>

                                                                                                        {/* folio details */}
                                                                                                        {
                                                                                                            isDematUser !== true &&
                                                                                                            <div className="col-4">
                                                                                                                <CustomSearchSelectBox options={item.folios}
                                                                                                                    value={item.selected_folio}
                                                                                                                    label="Select folio"
                                                                                                                    error={item.folio_error}
                                                                                                                    hide_error_text={false}
                                                                                                                    id={`cart-folio-${key}`}
                                                                                                                    placeholder=""
                                                                                                                    postfix="*"
                                                                                                                    onSelectChange={(select_folio) => {
                                                                                                                        _handleCartFolio(select_folio, key)
                                                                                                                    }} />
                                                                                                            </div>
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>

                                                                {
                                                                    mfDetail != null && (
                                                                        <>
                                                                            {/* get amount for SIP and Lumpsum investment */}
                                                                            {
                                                                                (selectedTransactionType === 1 || (selectedTransactionType === 2 && mfDetail.min_sip_investment > 0) || selectedTransactionType === 8 || (selectedTransactionType === 9 && mfDetail.min_sip_investment > 0)) && (
                                                                                    <div className="col-6 pt-4">
                                                                                        <CustomAmountInput value={amount}
                                                                                            label="Add amount"
                                                                                            showRupeePrefix={true}
                                                                                            id="mf-amount-input"
                                                                                            postfix="*"
                                                                                            error={amountError}
                                                                                            handleBlur={() => _handleAmountInputBlur()}
                                                                                            handleChange={_addAmount} />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {/* Get First installment for SIP */}
                                                                            {
                                                                                (selectedTransactionType === 2 && mfDetail.min_sip_investment > 0 || selectedTransactionType === 9 && mfDetail.min_sip_investment > 0) && (
                                                                                    <div className="col-6 pt-4 mt-3">
                                                                                        <label className="pb-3 color-light-black e-font-16 line-height-16px e-poppins-medium">Pay first installment now</label>
                                                                                        <RadioGroup data={FIRST_INSTALLMENT}
                                                                                            radioItemClassName="py-2"
                                                                                            size={14}
                                                                                            fontSize="e-font-12"
                                                                                            type={2}
                                                                                            id={firstInvestment}
                                                                                            radioHandler={_handleRadioInput} />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {/* Get folio for Lumpsum and SIP */}
                                                                            {
                                                                                (selectedTransactionType === 1 || selectedTransactionType === 8 || (selectedTransactionType === 2 && mfDetail.min_sip_investment > 0) || (selectedTransactionType === 9 && mfDetail.min_sip_investment > 0)) && isDematUser !== true && (
                                                                                    <div className="col-6 pt-4 mt-3">
                                                                                        {console.log('mfDetailFolioOptions', mfDetailFolioOptions)}
                                                                                        <CustomSearchSelectBox options={mfDetailFolioOptions}
                                                                                            value={Object.keys(selectedFolio).length === 0 ? "" : selectedFolio}
                                                                                            label="Select folio"
                                                                                            error={folioError}
                                                                                            id="mf-folio"
                                                                                            hide_error_text={false}
                                                                                            placeholder=""
                                                                                            postfix="*"
                                                                                            onSelectChange={(select_value) => {
                                                                                                _selectFolio(select_value)
                                                                                            }} />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {/* get bank account */}
                                                                            {
                                                                                (selectedTransactionType === 1 || selectedTransactionType === 8 || (selectedTransactionType === 2 && mfDetail.min_sip_investment > 0) || (selectedTransactionType === 9 && mfDetail.min_sip_investment > 0)) && (
                                                                                    <div className="col-6 pt-4 mt-3">
                                                                                        <CustomSearchSelectBox options={mfDetailBankOptions}
                                                                                            value={selectedBank}
                                                                                            label="Select bank"
                                                                                            error={bankError}
                                                                                            id="mf-bank"
                                                                                            hide_error_text={false}
                                                                                            placeholder=""
                                                                                            postfix="*"
                                                                                            onSelectChange={(selected_bank) => {
                                                                                                _selectBank(selected_bank)
                                                                                            }} />
                                                                                    </div>
                                                                                )
                                                                            }


                                                                            {/* get mandate in SIP investment */}
                                                                            {
                                                                                (selectedTransactionType === 2 && mfDetail.min_sip_investment > 0 || selectedTransactionType === 9 && mfDetail.min_sip_investment > 0) ?
                                                                                    (
                                                                                        <div className="col-6 pt-4 mt-3">
                                                                                            <CustomSearchSelectBox options={mandateOptions}
                                                                                                value={Object.keys(selectedMandate).length !== 0 ? selectedMandate : ""}
                                                                                                label="Select mandate"
                                                                                                error={mandateError}
                                                                                                id='mf-mandate'
                                                                                                hide_error_text={false}
                                                                                                placeholder=""
                                                                                                postfix="*"
                                                                                                onSelectChange={(mandate_id) => {
                                                                                                    _selectMandate(mandate_id)
                                                                                                }} />
                                                                                        </div>
                                                                                    ) : null
                                                                            }

                                                                            {/* get SIP date */}
                                                                            {
                                                                                (selectedTransactionType === 2 && mfDetail.min_sip_investment > 0 || selectedTransactionType === 9 && mfDetail.min_sip_investment > 0) && (
                                                                                    <div className="col-6 pt-4 mt-3">
                                                                                        <SIPDate labelClass="e-font-16 line-height-26px"
                                                                                            label="Monthly SIP date"
                                                                                            date={sipDate}
                                                                                            className="cursor-pointer"
                                                                                            inputClass="cursor-pointer"
                                                                                            type={1}
                                                                                            enabledDates={mfDetail.sip_dates}
                                                                                            dateSelected={_handleDateSelected} />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {
                                                                                (selectedTransactionType === 2 && mfDetail.min_sip_investment > 0 || selectedTransactionType === 9 && mfDetail.min_sip_investment > 0) && (
                                                                                    <div className="col-6 mt-3 pt-4" >
                                                                                        <p className="color-black e-poppins-medium e-font-14 mb-2">
                                                                                            SIP end date<span className="color-red">*</span>
                                                                                        </p>
                                                                                        <div id="sip-end-date"></div>
                                                                                        <DatePicker label=""
                                                                                            value={sipEndDate}
                                                                                            minDate={sipEndDateMinDate}
                                                                                            maxDate={max_date}
                                                                                            dateFormat={'dd-MM-yyyy'}
                                                                                            displayFormat={'dd MM yyyy'}
                                                                                            removeLabelOnFocus={true}
                                                                                            error={expiryDateError}
                                                                                            calendarClass="position-relative top-0"
                                                                                            // openDateModal={_handleSIPEndDateFocus}
                                                                                            dob={_handleSIPEndDate} />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {/* Mandate expiration and mandate limit for create mandate */}
                                                                            {
                                                                                ((selectedTransactionType === 2 && mfDetail.min_sip_investment > 0 || (selectedTransactionType === 2 && mfDetail.min_sip_investment > 0 || selectedTransactionType === 9 && mfDetail.min_sip_investment > 0)) && selectedMandate && selectedMandate.value === null) && (
                                                                                    <>
                                                                                        {/* <div className="col-10 pt-4 mt-3">
                                                                                            <label className="pb-3 color-light-black e-font-16 line-height-16px e-poppins-medium">Select mandate type</label>
                                                                                            <RadioGroup data={MANDATE_TYPE}
                                                                                                radioItemClassName="py-2"
                                                                                                size={14}
                                                                                                fontSize="e-font-12"
                                                                                                type={2}
                                                                                                id={mandateType}
                                                                                                radioHandler={_handleMandateTypeRadio} />
                                                                                        </div> */}
                                                                                        {
                                                                                            mandateType === 2 &&
                                                                                            <div className="row gx-0 mt-3">
                                                                                                <div className="col-12">
                                                                                                    <h5 className="color-jett-black e-poppins-medium e-font-16 ">Set date</h5>
                                                                                                </div>
                                                                                                <div className="col-md-3 col-12 pe-md-2">
                                                                                                    <DatePicker label="Start date"
                                                                                                        value={mandateStartDate}
                                                                                                        minDate={start_date}
                                                                                                        maxDate={end_date}
                                                                                                        dateFormat={'dd-MM-yyyy'}
                                                                                                        displayFormat={'dd MM yyyy'}
                                                                                                        removeLabelOnFocus={true}
                                                                                                        error={expiryDateError}
                                                                                                        dob={_handleStartDate} />
                                                                                                </div>
                                                                                                <div className="col-md-3 col-12 ps-md-2 mt-md-0 mt-4">
                                                                                                    <DatePicker label="End date"
                                                                                                        value={expiryDate}
                                                                                                        minDate={min_date}
                                                                                                        maxDate={max_date}
                                                                                                        dateFormat={'dd-MM-yyyy'}
                                                                                                        displayFormat={'dd MM yyyy'}
                                                                                                        removeLabelOnFocus={true}
                                                                                                        error={expiryDateError}
                                                                                                        dob={_handleDateChange} />
                                                                                                </div>
                                                                                                <div className="col-lg-11">
                                                                                                    <div className="d-flex gap-8px bg-mint-cream p-2 w-max-content border-radius-8px mt-3">
                                                                                                        <div className="w-max-content">
                                                                                                            <Icon icon="info"
                                                                                                                size={24} />
                                                                                                        </div>
                                                                                                        <p className="e-font-14 e-poppins-regular line-height-24px color-jett-black mb-0">
                                                                                                            The start date for this process will be effective from T+29 days.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        <div id="limit-input ">
                                                                                            <h5 className="color-jett-black e-poppins-medium e-font-16 mb-0 pt-4 mt-3">Set your mandate limit</h5>
                                                                                            <div className="col-6 ">
                                                                                                <CustomAmountInput value={mandateLimit}
                                                                                                    key={"SIP amount"}
                                                                                                    label="Amount*"
                                                                                                    className="mt-4"
                                                                                                    error={mandateLimitError}
                                                                                                    inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left  border-radius-12px"
                                                                                                    labelClass=" e-font-16 line-height-26px bg-white"
                                                                                                    handleChange={_handleMandateLimit} />
                                                                                                <div className="d-flex gap-8px mt-4" id="footer-div">
                                                                                                    {
                                                                                                        AMOUNT_SUGGESTIONS.map((item, key) => {
                                                                                                            return (
                                                                                                                <Amountchip value={item}
                                                                                                                    key={key}
                                                                                                                    amount={mandateLimit}
                                                                                                                    index={key}
                                                                                                                    handleChipSelect={() => _handleChipSelect(key)}
                                                                                                                />
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                mandateType === 1 &&
                                                                                                <div className="col-6 mt-3">
                                                                                                    <DatePicker label="Expiry date"
                                                                                                        value={expiryDate}
                                                                                                        minDate={min_date}
                                                                                                        maxDate={max_date}
                                                                                                        dateFormat={'dd-MM-yyyy'}
                                                                                                        displayFormat={'dd MM yyyy'}
                                                                                                        removeLabelOnFocus={true}
                                                                                                        error={expiryDateError}
                                                                                                        calendarClass="position-relative top-0"
                                                                                                        // openDateModal={_handleDatePickerFocus}
                                                                                                        dob={_handleDateChange} />
                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </Fragment>
                                                }
                                            </>
                                            :
                                            investmentType.value == 1 &&
                                            <>
                                                <div className="col-lg-12 padding-24px-top">
                                                    <label className="e-poppins-medium e-font-16 line-height-16px color-light-black mb-2">Select Goal config type</label>
                                                    <RadioGroup data={GOAL_CONFIG_TYPE}
                                                        type={1}
                                                        id={selectedGoalConfigType}
                                                        wrapperClass='w-auto'
                                                        radioHandler={(id) => {
                                                            _handleGoalConfigType(id)
                                                        }} />
                                                </div>

                                                {/* show available transaction types for goals */}
                                                {
                                                    selectedGoalConfigType === 1 ?
                                                        <>
                                                            {
                                                                transactionStatement.length === 0 ?
                                                                    Object.keys(investmentType).length !== 0 && (
                                                                        <div className="col-lg-12 padding-24px-top">
                                                                            <label className="e-poppins-medium e-font-16 line-height-16px color-light-black mb-2">Select Investment type</label>
                                                                            <RadioGroup data={investmentRadioOptions}
                                                                                type={1}
                                                                                id={selectedGoalTransactionType}
                                                                                wrapperClass='w-auto'
                                                                                radioHandler={(id) => {
                                                                                    _selectGoalTransactionType(id)
                                                                                }} />
                                                                        </div>
                                                                    )
                                                                    :
                                                                    <p className="e-poppins-regular color-black e-font-16 line-height-16px mb-0 mt-4">{transactionStatement}</p>
                                                            }
                                                            {
                                                                (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) && (
                                                                    <div className="col-lg-6 pt-4">
                                                                        <CustomSearchSelectBox options={goals}
                                                                            value={selectedGoal.label == undefined ? "" : selectedGoal}
                                                                            label="Select Goal"
                                                                            id="goal-dropdown"
                                                                            error={selectedGoalError}
                                                                            hide_error_text={false}
                                                                            placeholder=""
                                                                            postfix="*"
                                                                            onSelectChange={(selected_goal) => {
                                                                                _selectGoal(selected_goal);
                                                                            }} />
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <GradientButton label="Continue"
                                                            className='mt-4'
                                                            onPress={_navigateToGoalListing} />
                                                }


                                                {
                                                    Object.keys(selectedGoal).length !== 0 &&
                                                    <Fragment>

                                                        {
                                                            (selectedGoalTransactionType === 1) && (
                                                                <div className="col-6 pt-4 mt-3">
                                                                    <CustomAmountInput value={goalLumpsum}
                                                                        label="Add Lumpsum amount"
                                                                        id='goal-lumpsum-amount'
                                                                        showRupeePrefix={true}
                                                                        postfix={amount === 0 ? "*" : null}
                                                                        error={goalLumpsumError}
                                                                        handleChange={(amount) => _updateGoalLumpsumValue(amount)}
                                                                        handleBlur={_handleAmountInputBlur} />
                                                                </div>
                                                            )
                                                        }


                                                        {/* get amount for SIP and Lumpsum investment */}
                                                        {
                                                            (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) && (


                                                                <div className="col-6 pt-4 mt-3">
                                                                    <CustomAmountInput value={amount}
                                                                        label="Add SIP amount"
                                                                        id='mf-amount-input'
                                                                        showRupeePrefix={true}
                                                                        postfix={goalLumpsum === 0 ? "*" : null}
                                                                        error={amountError}
                                                                        handleChange={_addAmount}
                                                                        handleBlur={_handleAmountInputBlur} />
                                                                </div>

                                                            )
                                                        }

                                                        {/* Get First installment for SIP */}


                                                        {
                                                            (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) && (
                                                                <div className="col-6 pt-4 mt-3">
                                                                    <CustomSearchSelectBox options={investmentStyleOptions}
                                                                        value={Object.keys(selectedInvestmentStyle).length === 0 ? "" : selectedInvestmentStyle}
                                                                        label="Select investment style"
                                                                        error={investmentStyleError}
                                                                        hide_error_text={false}
                                                                        id='goal-transaction'
                                                                        placeholder=""
                                                                        postfix="*"
                                                                        onSelectChange={(invest_style) => {
                                                                            _selectInvestmentStyle(invest_style)
                                                                        }} />
                                                                </div>
                                                            )
                                                        }
                                                        {/* get bank account  */}
                                                        {
                                                            (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) && (
                                                                <div className="col-6 pt-4 mt-3">
                                                                    <CustomSearchSelectBox
                                                                        options={goalDetailBankOptions}
                                                                        value={Object.keys(selectedGoalBank).length === 0 ? "" : selectedGoalBank}
                                                                        label="Select bank"
                                                                        id='goal-bank'
                                                                        error={goalBankError}
                                                                        hide_error_text={false}
                                                                        placeholder=""
                                                                        postfix="*"
                                                                        onSelectChange={(selected_bank) => {
                                                                            _selectGoalBank(selected_bank);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }

                                                        {/* mandate option */}
                                                        {
                                                            (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) && (
                                                                <div className="col-6 pt-4 mt-3">
                                                                    <CustomSearchSelectBox options={mandateOptions}
                                                                        value={Object.keys(selectedMandate).length !== 0 ? selectedMandate : ""}
                                                                        label="Select mandate"
                                                                        error={mandateError}
                                                                        id="goal-mandate"
                                                                        hide_error_text={false}
                                                                        placeholder=""
                                                                        postfix="*"
                                                                        onSelectChange={(mandate_id) => {
                                                                            _selectMandate(mandate_id)
                                                                        }} />
                                                                </div>
                                                            )
                                                        }


                                                        {/* get SIP date */}
                                                        {
                                                            (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) && Object.keys(selectedInvestmentStyle).length !== 0 && (
                                                                <div className="col-6 pt-4 mt-3">
                                                                    <SIPDate labelClass="e-font-16 line-height-26px"
                                                                        label="Monthly SIP date"
                                                                        date={sipDate}
                                                                        className="cursor-pointer"
                                                                        inputClass="cursor-pointer"
                                                                        type={1}
                                                                        enabledDates={sipDatesArray}
                                                                        dateSelected={_handleDateSelected} />
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) && (
                                                                <div className="col-6 mt-3 pt-4" >
                                                                    <p className="color-black e-poppins-medium e-font-14 mb-2">
                                                                        SIP end date<span className="color-red">*</span>
                                                                    </p>
                                                                    <div id="sip-end-date"></div>
                                                                    <DatePicker label=""
                                                                        value={sipEndDate}
                                                                        minDate={sipEndDateMinDate}
                                                                        maxDate={max_date}
                                                                        dateFormat={'dd-MM-yyyy'}
                                                                        displayFormat={'dd MM yyyy'}
                                                                        removeLabelOnFocus={true}
                                                                        error={expiryDateError}
                                                                        calendarClass="position-relative top-0"
                                                                        // openDateModal={_handleSIPEndDateFocus}
                                                                        dob={_handleSIPEndDate} />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) && selectedMandate && selectedMandate.value === null &&
                                                            <div id="limit-input ">
                                                                <h5 className="color-jett-black e-poppins-medium e-font-16 mb-0 pt-4 mt-3">Set your mandate limit</h5>
                                                                <div className="col-6 ">
                                                                    <CustomAmountInput value={mandateLimit}
                                                                        key={"SIP amount"}
                                                                        label="Amount*"
                                                                        className="mt-4"
                                                                        error={mandateLimitError}
                                                                        inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left  border-radius-12px"
                                                                        labelClass=" e-font-16 line-height-26px bg-white"
                                                                        handleChange={_handleMandateLimit} />
                                                                    <div className="d-flex gap-8px mt-4" id="footer-div">
                                                                        {
                                                                            AMOUNT_SUGGESTIONS.map((item, key) => {
                                                                                return (
                                                                                    <Amountchip value={item}
                                                                                        key={key}
                                                                                        amount={mandateLimit}
                                                                                        index={key}
                                                                                        handleChipSelect={() => _handleChipSelect(key)}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <DatePicker label="Expiry date"
                                                                        value={expiryDate}
                                                                        minDate={min_date}
                                                                        maxDate={max_date}
                                                                        dateFormat={'dd-MM-yyyy'}
                                                                        displayFormat={'dd MM yyyy'}
                                                                        removeLabelOnFocus={true}
                                                                        error={expiryDateError}
                                                                        calendarClass="position-relative top-0"
                                                                        // openDateModal={_handleDatePickerFocus}
                                                                        dob={_handleDateChange} />
                                                                </div>
                                                            </div>
                                                        }

                                                    </Fragment>
                                                }
                                                {/* get goal redeem data in redeem */}
                                                {
                                                    selectedGoalTransactionType === 3 &&
                                                    <Fragment>
                                                        <div className="row">
                                                            <div className="col-6 pt-4 pe-0">
                                                                <CustomSearchSelectBox options={goalRedeemData.goal_data}
                                                                    value={Object.keys(selectedRedeemGoal).length === 0 ? "" : selectedRedeemGoal}
                                                                    label="Select redeem fund"
                                                                    error={selectedRedeemGoalError}
                                                                    hide_error_text={false}
                                                                    id="goal-redeem-dropdown"
                                                                    placeholder=""
                                                                    postfix="*"
                                                                    onSelectChange={(goal_redeem) => {
                                                                        _selectGoalRedeem(goal_redeem)
                                                                    }} />
                                                            </div>
                                                            {
                                                                Object.keys(selectedRedeemGoal).length !== 0 &&
                                                                <div className="col-6 pt-4">
                                                                    {/* Current amount, invested amount and total units */}
                                                                    <div className="border-primary-color border-all bg-pale-alice-blue border-radius-8px d-flex justify-content-between padding-14px-tb px-2">
                                                                        <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                            <span className="color-gray e-poppins-regular me-2">Current </span>
                                                                            {`₹ ${selectedRedeemGoal.current_amount.toLocaleString('en-IN')}`}
                                                                        </p>
                                                                        <p className="e-font-14 e-inter-medium color-black margin-block-end-0 ">
                                                                            <span className="color-gray e-poppins-regular me-2">Invested </span>
                                                                            {`₹ ${selectedRedeemGoal.invested_amount.toLocaleString('en-IN')}`}
                                                                        </p>
                                                                        <p className="e-font-14 e-inter-medium color-black mb-0 ">
                                                                            <span className="color-gray e-poppins-regular me-2">Total units</span>
                                                                            {selectedRedeemGoal.total_units.toLocaleString('en-IN')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        {/* <div className="col-6 pt-4 mt-3">
                                                            <CustomSearchSelectBox options={goalRedeemData.banks}
                                                                value={Object.keys(selectedRedeemBank).length === 0 ? "" : selectedRedeemBank}
                                                                label="Select bank"
                                                                error={selectedRedeemBankError}
                                                                id='goal-redeem-bank'
                                                                hide_error_text={false}
                                                                placeholder=""
                                                                postfix="*"
                                                                onSelectChange={(bank_info) => {
                                                                    _selectGoalRedeemBank(bank_info)
                                                                }} />

                                                        </div> */}
                                                        <div className="col-6 pt-4 mt-3">
                                                            <CustomAmountInput value={goalRedeemAmount}
                                                                label="Add amount"
                                                                showRupeePrefix={true}
                                                                disabled={goalRedeemAll}
                                                                id='goal-redeem-amount'
                                                                postfix="*"
                                                                error={goalRedeemAmountError}
                                                                handleChange={_addRedeemAmount} />
                                                        </div>
                                                        <div className="col-6 mt-4 pt-2">

                                                            <div className={`d-flex align-items-center gap-8px`}
                                                                onClick={_handleRedeemAll}>
                                                                <Icon icon={goalRedeemAll ? 'checked' : 'unchecked'}
                                                                    size={24}
                                                                    className="cursor-pointer"
                                                                />
                                                                <span className="color-black e-poppins-regular e-font-14 line-height-24px cursor-pointer ">
                                                                    Redeem all
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </Fragment>
                                                }
                                            </>

                                    }

                                    {
                                        selectedGoalConfigType === 2 && investmentType.value === 1 ?
                                            null
                                            :
                                            <div className="col-lg-12 margin-40px-top">
                                                <SecondaryButton label="Cancel"
                                                    onPress={_clearForm} />
                                                {
                                                    (investmentType.value === 1 && (selectedGoalTransactionType === 1 || selectedGoalTransactionType === 2) && (selectedMandate.value === null && Object.keys(selectedMandate).length !== 0)) ||
                                                        (investmentType.value === 2 && selectedTransactionType === 2 && (Object.keys(selectedMandate).length !== 0 && selectedMandate.value === null)) ? (
                                                        <OutlineButton label="Generate mandate link"
                                                            className='ms-3'
                                                            onPress={_validateMandateForm} />
                                                    ) : (
                                                        <>

                                                            {
                                                                investmentType && investmentType.value === 2 && selectedTransactionType > 4 && selectedTransactionType < 8 ?
                                                                    <GradientButton label={selectedTransactionType === 5 ? "Generate switch order & notify user" : selectedTransactionType === 6 ? "Generate SWP order & notify user" : "Generate STP order & notify user"}
                                                                        className='ms-3'
                                                                        loading={buttonLoader}
                                                                        onPress={_handleMTTransactionFormValidation} />
                                                                    :
                                                                    <GradientButton label={selectedTransactionType === 3 || selectedGoalTransactionType === 3 ? "Generate redeem order & notify user" : "Generate payment link & notify user"}
                                                                        className='ms-3'
                                                                        loading={buttonLoader}
                                                                        onPress={_handleValidate} />
                                                            }

                                                        </>
                                                    )
                                                }



                                            </div>
                                    }


                                </div>
                            </div>
                            :
                            <>
                                {
                                    orderHistoryLoader === true ?
                                        <div className="h-80vh">
                                            <CustomLoader />
                                        </div>
                                        :
                                        <div className="row padding-24px-top">
                                            {
                                                orderHistory.length !== 0 ?
                                                    <div className="col-12">
                                                        <OrderHistory data={orderHistory}
                                                            pageSize={pageSize}
                                                            paginationData={paginationData}
                                                            pageNumber={pageNum}
                                                            totalPages={totalPages}
                                                            setPageSize={(e) => {
                                                                setPageSize(e);
                                                                _orderHistory(pageNum, e, searchQuery, startDate, endDate)
                                                            }}
                                                            getPage={(page_num) => {
                                                                setPageNum(page_num);
                                                                _orderHistory(page_num, pageSize, searchQuery, startDate, endDate);
                                                            }}
                                                            openPaymentModal={_openPaymentModal} />
                                                    </div>
                                                    :
                                                    <EmptyScreen />
                                            }
                                        </div>
                                }
                            </>
                    }

                </div>
            </div>

            {
                showSuccessModal === true ?
                    <OrderExecutionModal orderData={paymentUrl}
                        close={_handleModalClose}
                        isPendingOrder={isPendingOrder} />
                    :
                    null
            }
        </Fragment >
    )
}
export default InitiateTransaction;