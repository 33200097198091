
/*
 *   File : fund-house.js
 *   Author URI : https://evoqins.com
 *   Description : UI to show the AMC detail from Fund detail page
 *   Integrations : null
 *   Version : v1.1
 */
import { useEffect } from 'react';
import Icon from '../Icon/icon';

import style from '../../Styles/Components/fund-house-modal.module.scss';

export default function FundHouse(props) {

    // Modal close listener
    useEffect(() => {
        var my_modal = document.getElementById("fund-house");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    return (
        <div className={`modal fade ${style.e_holdings_modal}`}
            id="fund-house"
            tabIndex="-1"
            aria-labelledby="view-holdings"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white position-relative  p-4 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />

                    <div className="d-flex align-items-center gap-12px e-fund-name ">
                        <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                            <img src={props.data.amc_image}
                                width={36}
                                height={36}
                                className="object-fit-contain" />
                        </div>
                        <div className="d-flex flex-column gap-12px">
                            <p className="color-black e-poppins-semi-bold e-font-18 line-height-24px mb-0 bg-white">{props.data.amc_name}</p>
                            <span className="text-start border-bright-gray  color-black e-poppins-medium e-font-12 line-height-18px w-fit-content">Founded: {props.data.amc_founded}
                            </span>
                        </div>
                    </div>
                    <div className={style.e_modal_body}>

                        <p className="color-black e-poppins-medium e-font-18 line-height-24px mb-0 bg-white mt-4">
                            Website
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white mt-2">
                            <a className='color-black hover-underline' href={props.data.amc_website.startsWith("https://") ? props.data.amc_website : `https://${props.data.amc_website}`} target='_blank'>{props.data.amc_website}</a>
                        </p>

                        <p className="color-black e-poppins-medium e-font-18 line-height-24px mb-0 bg-white mt-4 ">
                            Contact
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white mt-2">
                            <a className='color-black hover-underline' href={`tel:${props.data.amc_contact}`}>{props.data.amc_contact}</a>
                        </p>


                        <p className="color-black e-poppins-medium e-font-18 line-height-24px mb-0 bg-white mt-4">
                            Email
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white mt-2">
                            <a className='color-black hover-underline' href={`mailto:${props.data.amc_email}`}>{props.data.amc_email}</a>
                        </p>


                        <p className="color-black e-poppins-medium e-font-18 line-height-24px mb-0 bg-white mt-4 ">
                            Address
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white mt-2">
                            {props.data.amc_address}
                        </p>


                        <p className="color-black e-poppins-semi-bold e-font-18 line-height-24px mb-0 bg-white mt-4">
                            RTA details
                        </p>

                        <div className='row mt-4'>
                            <div className='col-6 '>
                                <p className="color-black e-poppins-medium e-font-18 line-height-24px mb-0 bg-white">
                                    Name
                                </p>
                                <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white mt-2">
                                    {props.data.rta_name}
                                </p>
                            </div>
                            <div className='col-6'>
                                <p className="color-black e-poppins-medium e-font-18 line-height-24px mb-0 bg-white">
                                    Contact
                                </p>
                                <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white mt-2">
                                    <a className='color-black hover-underline' href={`tel:${props.data.rta_contact}`}>{props.data.rta_contact}</a>
                                </p>
                            </div>
                        </div>

                        <p className="color-black e-poppins-medium e-font-18 line-height-24px mb-0 bg-white mt-4">
                            Email
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white mt-2">
                            <a className='color-black hover-underline' href={`mailto:${props.data.rta_email}`}>{props.data.rta_email}</a>
                        </p>

                        <p className="color-black e-poppins-medium e-font-18 line-height-24px mb-0 bg-white mt-4">
                            Address
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 bg-white mt-2">
                            {props.data.rta_address}
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
};