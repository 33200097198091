/*
 *   File :  portfolio.js
 *   Author URI : https://evoqins.com
 *   Description : Container file which shows investments summary.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { RadioGroup } from "../../../../Components/FormElements";
import { CustomTab } from "../../../../Components/Tab";
import { PortfolioSummaryCard } from "../../../../Components/Cards";
import { PortfolioFundsTable, PortfolioGoalsTable } from "../../../../Components/Table";
import { CustomLoader, EmptyScreen } from "../../../../Components/Other";
import { GradientButton } from "../../../../Components/Cta";

import APIService from "../../../../Services/api-service";

const PORTFOLIO = [
    {
        id: -1, label: 'All',
        image: require("../../../../Assets/Images/Portfolio/all.png")
    },
    {
        id: 1, label: 'mt',
        image: require("../../../../Assets/Images/Portfolio/mt.png")
    },
    {
        id: 2, label: 'non-mt',
        image: require("../../../../Assets/Images/Portfolio/non-mt.png")
    },
];

const TAB_DATA = ['SIP', 'Lumpsum'];


const INVESTMENT_DATA = ["Mutual funds", "Life goals"]


const Portfolio = (props) => {

    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);
    const navigate = useNavigate();
    const location = useLocation();
    const [portfolioRadio, setPortFolioRadio] = useState(-1);
    const [tabIndex, setTabIndex] = useState(1);
    const [investmentType, setInvestmentType] = useState(1);
    const [portFolioData, setPortFolioData] = useState({});
    const [loader, setLoader] = useState(true);
    const [syncing, setSyncing] = useState(false);
    useEffect(() => {
        _getPortFolioData();
        //eslint-disable-next-line
    }, [portfolioRadio])

    // handler for tab change
    const _handleTabSelect = (type, index) => {
        switch (type) {
            case 1:
                setTabIndex(index);
                break;
            case 2:
                setInvestmentType(index);
                break;
            default:
                return null;
        }
    }

    function _showNoMtFunds() {
        setPortFolioRadio(2);
        var detail_section = document.getElementById('detail-section');
        if (detail_section) {
            detail_section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const _handleNavigate = (type, id) => {
        switch (type) {
            case 1:
                navigate("/order-book/mutual-fund-detail", {
                    state: {
                        user_id: location.state.user_id,
                        id: id,
                        is_goal: false
                    }
                })
                break;
            case 2:
                navigate("/order-book/goal-fund-detail", {
                    state: {
                        user_id: location.state.user_id,
                        id: id,
                        is_goal: true
                    }
                })
                break;
            default:
                return null;
        }
    }

    // API - Get user data
    function _getPortFolioData() {
        let url = 'user/portfolio/get';
        let payload = JSON.stringify({
            customer_id: location.state.user_id,
            type: portfolioRadio
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setPortFolioData(response.data);
            }
            setLoader(false);
        })
    }

    // API - sync folios
    function _syncFolio() {
        setSyncing(true);
        let url = 'user/folio-sync';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('Successfully synced folios', {
                    type: 'success'
                })
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setSyncing(false);
        })
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <div className="e-portfolio margin-72px-bottom ">
                <div className="row mt-3">
                    <div className="col-xl-7">
                        <RadioGroup data={PORTFOLIO}
                            className="gap-16px mb-4 justify-content-end"
                            radioItemClassName="py-2"
                            size={20}
                            fontSize="e-font-16"
                            type={3}
                            id={portfolioRadio}
                            radioHandler={(e) => setPortFolioRadio(e)} />
                        <PortfolioSummaryCard data={portFolioData.portfolio}
                            customer_info={props.customer_info}
                            isFundImported={portFolioData.is_cas_imported}
                            lastCASImport={portFolioData.last_cas_import}
                            isUpdateAvailable={portFolioData.is_cas_import_allowed}
                            callBackExternalFunds={_showNoMtFunds} />
                    </div>

                    <div className="col-xl-5 mt-5 pt-3">
                        <div className="border-radius-16px px-4 py-3 bg-pale-steel-blue h-100">
                            <p className="color-black e-poppins-semi-bold e-font-16 line-height-20px mb-3">Investment breakdown</p>
                            <div className="py-2 px-3 bg-white border-radius-24px ">
                                <CustomTab data={TAB_DATA}
                                    wrapperClass={'gap-16px'}
                                    index={tabIndex}
                                    className="px-0 padding-10px-tb e-order-tab mb-4"
                                    onSelectTab={(value) => _handleTabSelect(1, value)} />

                                <div className="row">
                                    {
                                        tabIndex === 1 &&
                                        <div className="col-4 mt-2">
                                            <div className="p-2">
                                                <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Active SIP</p>
                                                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{portFolioData.investment_breakdown.sip.sip_count}</p>
                                            </div>
                                        </div>
                                    }

                                    <div className="col-4 mt-2">
                                        <div className="p-2">
                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Invested</p>
                                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0"><span className="e-inter-medium">₹</span>
                                                {
                                                    tabIndex === 1 ?
                                                        portFolioData.investment_breakdown.sip.invested.toLocaleString('en-IN')
                                                        :
                                                        portFolioData.investment_breakdown.lumpsum.invested.toLocaleString('en-IN')
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <div className="p-2">
                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Current</p>
                                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0"><span className="e-inter-medium">₹</span>
                                                {
                                                    tabIndex === 1 ?
                                                        portFolioData.investment_breakdown.sip.current.toLocaleString('en-IN')
                                                        :
                                                        portFolioData.investment_breakdown.lumpsum.current.toLocaleString('en-IN')
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <div className="p-2">
                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Returns</p>
                                            <p className={`color-sea-green e-poppins-medium e-font-16 line-height-16px mb-0 ${tabIndex === 1 ? portFolioData.investment_breakdown.sip.returns < 0 && 'color-red' : portFolioData.investment_breakdown.lumpsum.returns < 0 && 'color-red'}`}>
                                                <span className={"e-inter-medium"}>₹</span>
                                                {
                                                    tabIndex === 1 ?
                                                        portFolioData.investment_breakdown.sip.returns.toLocaleString('en-IN')
                                                        :
                                                        portFolioData.investment_breakdown.lumpsum.returns.toLocaleString('en-IN')
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <div className="p-2">
                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Return %</p>
                                            <p className={`${tabIndex === 1 ? portFolioData.investment_breakdown.sip.returns_percentage < 0 && "color-red" : portFolioData.investment_breakdown.lumpsum.returns_percentage < 0 && "color-red"} color-sea-green e-poppins-medium e-font-14 line-height-14px mb-0`}>
                                                {
                                                    tabIndex === 1 ?
                                                        portFolioData.investment_breakdown.sip.returns_percentage
                                                        :
                                                        portFolioData.investment_breakdown.lumpsum.returns_percentage
                                                }%
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <div className="p-2">
                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">XIRR</p>
                                            <p className={`${10.209 < 0 ? "color-red" : "color-sea-green"} e-poppins-medium e-font-14 line-height-14px mb-0`}>
                                                {
                                                    tabIndex === 1 ?
                                                        portFolioData.investment_breakdown.sip.xirr
                                                        :
                                                        portFolioData.investment_breakdown.lumpsum.xirr
                                                }%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <p className="color-jett-black e-poppins-medium e-font-16 line-height-24px mb-3 margin-32px-top">Investment details</p>
                    </div>

                </div>

                <CustomTab data={INVESTMENT_DATA}
                    index={tabIndex}
                    wrapperClass={'gap-16px'}
                    className="px-0 padding-10px-tb e-order-tab mb-3"
                    onSelectTab={(value) => _handleTabSelect(2, value)} />
                {
                    investmentType === 1 ?
                        <>
                            {
                                portFolioData.investment_details.mutual_fund.length !== 0 ?
                                    <div id="detail-section">
                                        <PortfolioFundsTable data={portFolioData.investment_details.mutual_fund}
                                            onClick={(id) => _handleNavigate(1, id)} />
                                    </div>
                                    :
                                    <EmptyScreen title="No mutual fund investments found" />
                            }
                        </>

                        :
                        <>
                            {
                                portFolioData.investment_details.life_goals.length !== 0 ?
                                    <PortfolioGoalsTable data={portFolioData.investment_details.life_goals}
                                        onClick={(id) => _handleNavigate(2, id)} />
                                    :
                                    <EmptyScreen title="No life goal investments found" />
                            }
                        </>

                }

            </div>
    )
}

export default Portfolio;