/*
 *   File : create-mandate.js
 *   Author URI : https://evoqins.com
 *   Description : Screen to create bank mandate for an already existing bank account.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import Icon from "../Icon/icon";
import { GradientButton } from "../Cta";
import { CustomAmountInput, DatePicker } from "../FormElements";
import { Amountchip } from "../Other";
// import { _authenticateMandate, _createMandate } from "../../Helper/api";

import APIService from "../../Services/api-service";

import style from "../../Styles/Components/show-mandate.module.scss";

const AMOUNT_SUGGESTIONS = [
    100000,
    200000,
    300000,
    400000,
    500000
]

const today = new Date();
const min_date = new Date(today);
min_date.setFullYear(min_date.getFullYear() + 1);

const max_date = new Date(today);
max_date.setFullYear(max_date.getFullYear() + 40);

const default_date = new Date(today);
default_date.setFullYear(default_date.getFullYear() + 5);

const start_date = new Date(today); // Initialize with the provided 'today' date
start_date.setMonth(start_date.getMonth() + 1);

const end_date = new Date(start_date);
end_date.setMonth(end_date.getMonth() + 1);

const CreateMandateModal = (props) => {
    var timer; // global timer for payment url status check
    const [selectedBank, setSelectedBank] = useState(props.bankData[0].bank_id);
    const [apiLoader, setApiLoader] = useState(false);
    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");
    const [expiryDate, setExpiryDate] = useState(format(max_date, "dd-MM-yyyy"));
    const [expiryDateError, setExpiryDateError] = useState("");
    const [startDate, setStartDate] = useState(format(start_date, "dd-MM-yyyy"));

    useEffect(() => {
        var my_modal = document.getElementById("show-mandate");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            clearInterval(timer);
        });
    }, [props.close]);

    const _handleCancel = () => {
        props.close();
    }

    function _handleDateChange(value) {
        setExpiryDate(value);
        setExpiryDateError("");
    }

    function _handleStartDate(value) {
        setStartDate(value);
    }

    function _handleAmountInput(value) {
        setAmount(value);
        setAmountError("");
    }

    function _handleChipSelect(index) {
        setAmount(AMOUNT_SUGGESTIONS[index]);
        setAmountError("");
    }


    const _handleSelectBank = (id) => {
        setSelectedBank(id)
    }

    function _handleDatePickerFocus() {
        let element = document.getElementById('footer-div');
        setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' })
        }, 200);

    }

    function _fileDownloader(url, file_name) {
        const link = document.createElement('a');
        link.href = url;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const _handleCreatePhysicalMandate = async () => {
        function _formatDate(date) {
            const parts = date.split('-');
            const formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];
            return formatted_date;
        }
        let formatted_start_date = '';
        const formatted_end_date = _formatDate(expiryDate);
        if (startDate) {
            formatted_start_date = _formatDate(startDate);
        }
        if (amount === '') {
            setAmountError('Mandate limit is required');
            return;
        }
        setApiLoader(true);
        let url = 'v2/mandates/create';
        let payload = JSON.stringify({
            customer_id: props.userId,
            bank_id: selectedBank,
            start_date: formatted_start_date,
            end_date: formatted_end_date,
            limit: amount,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                _fileDownloader(response.data.url, 'Mandate form');
                let close_btn = document.getElementById('close-mandate-modal');
                close_btn.click();
                props.updateList();
            } else {
                // If creating mandate fails, show error message
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    };


    function _createMandate() {

        const parts = expiryDate.split('-');
        if (amount === '') {
            setAmountError('Mandate limit is required');
            return;
        }
        setApiLoader(true);
        const formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];
        let url = 'mandates/create';
        let payload = JSON.stringify({
            customer_id: props.userId,
            bank_id: selectedBank,
            end_date: formatted_date,
            limit: amount,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success("Mandate created and sent to the user.", {
                    type: 'success'
                });
                props.updateList();
                let close_btn = document.getElementById('close-mandate-modal');
                close_btn.click();
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
        
    }

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);


    return (
        <div className={`modal fade ${style.e_create_mandate}`}
            id="show-mandate"
            tabIndex="-1"
            aria-labelledby="show-mandate"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  padding-32px-all ${style.e_content} `}>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <p className="color-jett-black e-poppins-semi-bold e-font-18 mb-0">Configure mandate</p>
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className="cursor-pointer e-modal-close-btn"
                            onClick={_handleCancel} />
                        <button id="close-mandate-modal"
                            data-bs-dismiss="modal"
                            className="d-none"></button>
                    </div>
                    <div className={`${style.content_wrapper}`} id="modal-body">
                        <div className="border-all border-bright-gray p-3 border-radius-16px margin-32px-bottom">
                            <div className="d-flex gap-16px align-items-center margin-24px-bottom">
                                <img src={require("../../Assets/Images/Users/select-bank.png")}
                                    alt="Select bank"
                                    width={24}
                                    height={24}
                                    draggable={false} />
                                <p className="color-jett-black e-poppins-medium e-font-16 mb-0">Select bank</p>
                            </div>
                            <div className={`d-flex flex-column gap-8px `}>
                                {
                                    props.bankData.map((item) =>
                                        <div className={`border-radius-8px p-sm-3 p-2 cursor-pointer ${selectedBank === item.bank_id ? "bg-pale-mint" : "bg-grayish-white"}`}
                                            key={item.bank_id}
                                            onClick={() => _handleSelectBank(item.bank_id)}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex  align-items-center gap-12px">
                                                    <img src={item.bank_image}
                                                        alt={item.bank_name}
                                                        width={36}
                                                        height={36}
                                                        className="border-radius-100 object-fit-contain" />
                                                    <div className="d-flex flex-wrap align-items-center gap-12px">
                                                        <p className="color-black e-poppins-semi-bold e-font-sm-16-14 line-height-22px mb-0">{item.bank_name}</p>
                                                        <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">{item.masked_account_number}</p>
                                                    </div>
                                                </div>
                                                <Icon icon={selectedBank === item.bank_id ? "radio-selected" : "radio-unselected"}
                                                    size={25} />
                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        {
                            props.type === 2 &&
                            <div className="row gx-0 mt-3">
                                <div className="col-12">
                                    <h5 className="color-jett-black e-poppins-medium e-font-16 ">Set date</h5>
                                </div>
                                <div className="col-md-6 col-12 pe-md-2">
                                    <DatePicker label="Start date"
                                        value={startDate}
                                        minDate={start_date}
                                        maxDate={end_date}
                                        dateFormat={'dd-MM-yyyy'}
                                        displayFormat={'dd MM yyyy'}
                                        removeLabelOnFocus={true}
                                        error={expiryDateError}
                                        calendarClass="position-relative top-0"
                                        openDateModal={_handleDatePickerFocus}
                                        dob={_handleStartDate} />
                                </div>
                                <div className="col-md-6 col-12 ps-md-2 mt-md-0 mt-4">
                                    <DatePicker label="End date"
                                        value={expiryDate}
                                        minDate={min_date}
                                        maxDate={max_date}
                                        dateFormat={'dd-MM-yyyy'}
                                        displayFormat={'dd MM yyyy'}
                                        removeLabelOnFocus={true}
                                        error={expiryDateError}
                                        calendarClass="position-relative top-0"
                                        openDateModal={_handleDatePickerFocus}
                                        dob={_handleDateChange} />
                                </div>
                                <div className="col-lg-11">
                                    <div className="d-flex gap-8px bg-mint-cream p-2  border-radius-8px mt-3">
                                        <div className="w-max-content">
                                            <Icon icon="info"
                                                size={24} />
                                        </div>
                                        <p className="e-font-14 e-poppins-regular line-height-24px color-jett-black mb-0">
                                            The start date for this process will be effective from T+29 days.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }

                        <div id="limit-input">
                            <h5 className="color-jett-black e-poppins-medium e-font-16 mb-0">Set your mandate limit</h5>

                            <CustomAmountInput value={amount}
                                key={"SIP amount"}
                                label="Amount*"
                                className="mt-4"
                                error={amountError}
                                inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left  border-radius-12px"
                                labelClass=" e-font-16 line-height-26px bg-white"
                                handleChange={_handleAmountInput} />
                            <div className="d-flex flex-wrap gap-8px mt-4" id="footer-div">
                                {
                                    AMOUNT_SUGGESTIONS.map((item, key) => {
                                        return (
                                            <Amountchip value={item}
                                                key={key}
                                                amount={amount}
                                                index={key}
                                                handleChipSelect={() => _handleChipSelect(key)}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            props.type === 1 &&
                            <div className="my-3">
                                <DatePicker label="Expiry date"
                                    value={expiryDate}
                                    minDate={min_date}
                                    maxDate={max_date}
                                    dateFormat={'dd-MM-yyyy'}
                                    displayFormat={'dd MM yyyy'}
                                    removeLabelOnFocus={true}
                                    error={expiryDateError}
                                    calendarClass="position-relative top-0"
                                    openDateModal={_handleDatePickerFocus}
                                    dob={_handleDateChange} />
                            </div>
                        }

                    </div>
                    {
                        props.type === 1 ?
                            <div className="mx-5 mt-3">
                                <GradientButton label="Create mandate"
                                    className="w-100 padding-12px-tb e-font-16"
                                    // disabled={USER_DATA.is_kyc_done === false ? true : false}
                                    loading={apiLoader}
                                    onPress={_createMandate} />
                            </div>
                            :
                            <div className="mx-5 mt-3">
                                <GradientButton label="Submit"
                                    className="w-100 padding-12px-tb e-font-16"
                                    // disabled={USER_DATA.is_kyc_done === false ? true : false}
                                    loading={apiLoader}
                                    onPress={_handleCreatePhysicalMandate} />
                            </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default CreateMandateModal